import React, { useEffect, useState } from "react"
import "./../../css/search.css"
import searchIcon from "./../../assets/search.png"
import bankImg from "./../../assets/bank.png"
import searchIco from "./../../assets/search-page.png"
import moreIco from "./../../assets/point.png"
import backImg from "../../assets/left.png"
import { getLabelList, getProduct, productListByCondition,productListByConditionNew } from "../../api/product"

export default function Search({ history }) {
    let keyword = history.location.state
    let json = {}
    let type = 1
    if(history.location.state.type == 2){
        type = 2
        json = history.location.state
        delete  json.type
        keyword = ''
        console.log(json,'asdasdasdasd')
    }
    
    if(typeof(keyword)=='object') keyword = keyword.keyword
    
    const flag = history.location.state.flag || false
    const [activeIndex, setActiveIndex] = useState(1)
    const [hotSearchList, setHotSearchList] = useState([])
    // 获取关键字
    const [keywords, setKeywords] = useState(keyword)
    const [products, setProducts] = useState([])

    // 获取搜索框输入的值
    const inputChange = (e) => {
        setKeywords(e.target.value)
    }
    // 搜索按钮处理事件
    const searchHandle = () => {
        productListByCondition(keywords, false)
            .then((res) => {
                setProducts(res.result.records)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    // 热门搜索点击事件
    const tabChange = (id) => {
        setActiveIndex(id)
        hotSearchList.forEach((hotSearch) => {
            if (id === hotSearch.id) {
                setKeywords(hotSearch.labelName)
                productListByCondition(hotSearch.labelName, false)
                    .then((res) => {
                        setProducts(res.result.records)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        })
    }
    useEffect(() => {
        getLabelList().then((res) => {
            setHotSearchList(res.result)
        })
        // 通过条件查询
        if(type == 2) {
            productListByConditionNew(json).then((res) => {
                setProducts(res.result.records)
            })
            .catch((err) => {
                console.log(err)
            })
        }else{
        productListByCondition(keywords, flag)
            .then((res) => {
                setProducts(res.result.records)
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }, [])
    var tabList = hotSearchList.map(function (res, index) {
        var tabStyle = res.id === activeIndex ? "search-con-item search-active" : "search-con-item"
        return (
            <div
                key={index}
                onClick={() => {
                    tabChange(res.id)
                }}
                className={tabStyle}
            >
                {res.labelName}
            </div>
        )
    })
    const back = () => {
        window.history.back(-1)
    }

    return (
        <div className="wrapper1">
            <div className="container1">
                {/* 标题 */}
                <div className="page-title">
                    <img src={backImg} alt="" className="title-left1" onClick={back} />
                    搜索
                </div>
                {/* 搜索框 */}
                <div className="container2">
                <div className="home-search">
                    <div className="home-search-box">
                        <img src={searchIcon} className={"search-icon"} alt="" />
                        <input
                            type="text"
                            defaultValue={keywords}
                            value={keywords}
                            placeholder="搜索"
                            className="home-search-input"
                            onChange={inputChange}
                        />
                        <button className="search-btn" onClick={searchHandle}>
                            搜索
                        </button>
                    </div>
                </div>
                {/* 热门搜索 */}
                <div className="search-box">
                    <div className="search-title">热门搜索</div>
                    <div className="search-con">
                        <div className="search-con-flex">{tabList}</div>
                        <div className="search-icon-hot">
                            <img src={searchIco} alt="" className="search-ico" />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "1rem" }}>{products.length > 0 ? "" : "未搜索到相关内容"}</div>
                {products.map((item) => {
                    return (
                        <div
                            className="bank-item"
                            style={{ backgroundColor: "rgba(255,255,255,.5)", marginTop: "10px" }}
                        >
                            <div className="bank-item-top">
                                <div className="bank-top-left">
                                    <img src={bankImg} alt="" className="bank-image" />
                                    <div className="bank-top-center">
                                        <div className="yu-top-title">{item.proName}</div>
                                        <div className="sort-item-middle">
                                            <div className="middle-item">
                                                <div className="middle-item-top top-red">{item.proAmount}</div>
                                                <div className="middle-item-btm">贷款金额</div>
                                            </div>
                                            <div className="middle-item">
                                                <div className="middle-item-top">{item.proRate}</div>
                                                <div className="middle-item-btm">参考利率</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bank-item-btm">
                                <div className="btm-top">
                                    <div className="btm-item">社保</div>
                                    <div className="btm-item">公积金</div>
                                    <div className="btm-item">按揭房</div>
                                    <div className="btm-item">公积金</div>
                                    <div className="btm-item">公积金</div>
                                    <div className="btm-item">按揭房</div>
                                    <div className="btm-item">按揭房</div>
                                </div>
                            </div>
                            <div className="bank-point">
                                <img src={moreIco} alt="" className="point-icon" />
                            </div>
                        </div>
                    )
                })}
            </div>
            </div>
        </div>
    )
}
