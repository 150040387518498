import React, { useState } from 'react';
import './../../css/success.css';
import successIcon from './../../assets/success_icon.png';
import success from './../../assets/success.png';
import { Link } from "react-router-dom";
import backImg from "../../assets/left.png";

export default function Success() {
    const back = () => {
        window.history.back(-1)
    }
    return (
        <div className='wrapper'>
            <div className='container'>
                <div className='page-title'>
                    <img src={backImg} alt='' className='title-left1' onClick={back} />输入信息
                </div>
                <div className='manager-con'>
                    <div className='input-box success-box'>
                        <img src={successIcon} alt='' className='success_icon' />
                        <img src={success} alt='' className='success_ico' />
                        <div className='success-desc'>
                            请前往<Link to='/' className='success-link'>个人中心</Link>查看进度
                        </div>
                    </div>
                    <div className='input-btn'>
                        <button className='input-submit'>提交</button>
                    </div>
                </div>
            </div>
        </div>
    )
}