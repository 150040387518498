import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./../../css/mine.css"
import phonePic from "./../../assets/phone_icon.png"
import wechatPic from "./../../assets/wechat_icon.png"
import managerPic from "./../../assets/avatar.png"
import viper from "./../../assets/viper.png"
import homeIcon from "./../../assets/home_select.png"
import mineIcon from "./../../assets/mine_selected.png"
import menuIcon from "./../../assets/menu_address.png"
import kehu from "./../../assets/kehu.png"
import bankPic from "./../../assets/bank_logo.png"
import clockPic from "./../../assets/date_icon.png"
import bankImg from "./../../assets/bank.png"
import videoPoster from "./../../assets/jhk-1629292297794.png"
import videoPlay from "./../../assets/play_icon.png"
import commentIco from "./../../assets/comment_icon.png"
import zanIcon from "./../../assets/zan_icon.png"
import pointIco from "./../../assets/point.png"
import collectIcon from "./../../assets/collect.png"
import popupClose from "./../../assets/popup-close.png"
import { Button } from "antd-mobile-v2"
import { myProducts } from "../../api/product"
import { myVideo } from "../../api/video"
import { resource } from "../../utils/resource"
import { Toast } from "antd-mobile-v2"

export default function Mine({ history }) {
    if (!localStorage.getItem("userInfo")) {
        history.push("/login")
    }

    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const [visible, setVisible] = useState(false)
    const [products, setProducts] = useState()
    const [videos, setVideos] = useState()

    if (!products) {
        myProducts().then(({ result }) => {
            const { records } = result
            setProducts(records)
        })
        myVideo().then(({ result }) => {
            const { records } = result
            setVideos(records)
        })
    }

    return (
        <div className="wrapper">
            <div className="mine-top">
                <div className="mine-top-title">个人中心</div>
                <div className="mine-top-middle">
                    <div className="middle-left">
                        <img src={phonePic} alt="" className="phone-icon" />
                        <div className="phone-word">{userInfo.phone}</div>
                    </div>
                    <div className="middle-center">
                        <img src={resource(userInfo.avatar)} alt="" className="avatar-icon" />
                        <div className="avatar-word">{userInfo.realname}</div>
                        <div className="avatar-desc">
                            <span className="avatar-text">杭州银行</span>
                        </div>
                    </div>
                    <div className="middle-left">
                        <img src={wechatPic} alt="" className="phone-icon" />
                        <div className="phone-word">{userInfo.userWx}</div>
                    </div>
                </div>
                <div className="mine-top-btm">
                    <div className="btm-left">
                        <span
                            className="btm-left-item"
                            onClick={() => {
                                setVisible(true)
                            }}
                        >
                            已认证
                        </span>
                    </div>
                    <div className="btm-right">
                        <span
                            className="btm-right-item"
                            onClick={() => {
                                history.push("/personInfo")
                            }}
                        >
                            个人信息
                        </span>
                    </div>
                </div>
            </div>
            <div className="mine-btm">
                <div className="vip-submit">
                    <Link to="/member">
                        <img src={viper} alt="" className="viper-icon" />
                    </Link>
                </div>
                <div className="hot-part">
                    <div className="hot-title">
                        <div className="hot-title-left">客户预约记录</div>
                        <div className="hot-title-right">
                            <Link to="/" className="hot-a">
                                更多
                            </Link>
                        </div>
                    </div>
                    <div className="yu-content">
                        <div className="yu-item">
                            <div className="yu-item-top">
                                <div className="yu-top-flex">
                                    <div className="yu-top-logo">
                                        <img src={bankPic} alt="" className="bank-logo" />
                                    </div>
                                    <div className="yu-top-middle">
                                        <div className="yu-top-title">杭州银行-恒昌财富</div>
                                        <div className="yu-top-desc">
                                            预约进度：<span className="desc-span">尚未被查看</span>
                                        </div>
                                    </div>
                                    <div className="yu-top-right">
                                        <img src={clockPic} alt="" className="clock-icon" />
                                        <span className="clock-span">2021-09-20</span>
                                    </div>
                                </div>
                                <div className="yu-top-desc">
                                    <div className="yu-desc-tip">预约客户:</div>
                                    <div className="yu-desc-avatar">
                                        <img src={kehu} alt="" className="keHu" />
                                        <div className="kehu-word">陈默含</div>
                                    </div>
                                    <div className="yu-desc-avatar">
                                        <img src={kehu} alt="" className="keHu" />
                                        <div className="kehu-word">陈默含</div>
                                    </div>
                                </div>
                            </div>
                            <div className="yu-item-btm">
                                <div className="yu-btm-cancel">
                                    <Link to="/" className="cancel-btn">
                                        取消
                                    </Link>
                                </div>
                                <div className="yu-btm-check">
                                    <Link to="/" className="check-btn">
                                        查看
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hot-part">
                    <div className="hot-title">
                        <div className="hot-title-left">我的产品</div>
                        <div className="hot-title-right">
                            <Link to="/" className="hot-a">
                                更多
                            </Link>
                        </div>
                    </div>
                    <div className="yu-content">
                        {(products || []).map((product) => {
                            return (
                                <div
                                    className="bank-item"
                                    key={product.id}
                                    onClick={() => {
                                        history.push("/BankDetail", { product })
                                    }}
                                >
                                    <div className="bank-item-top">
                                        <div className="bank-top-left">
                                            <img src={product.proAvator||bankImg} alt="" className="bank-image" />
                                            <div className="bank-top-center">
                                                <div className="yu-top-title">{product.proName}</div>
                                                <div className="sort-item-middle">
                                                    <div className="middle-item">
                                                        <div className="middle-item-top top-red">
                                                            {product.proAmount}
                                                        </div>
                                                        <div className="middle-item-btm">贷款金额</div>
                                                    </div>
                                                    <div className="middle-item">
                                                        <div className="middle-item-top">{product.proRate}/月</div>
                                                        <div className="middle-item-btm">参考利率</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bank-top-right"></div>
                                    </div>
                                    <div className="bank-item-btm">
                                        <div className="btm-top">
                                            <div className="btm-item">社保</div>
                                            <div className="btm-item">公积金</div>
                                            <div className="btm-item">按揭房</div>
                                            <div className="btm-item">公积金</div>
                                            <div className="btm-item">公积金</div>
                                            <div className="btm-item">按揭房</div>
                                            <div className="btm-item">按揭房</div>
                                        </div>
                                    </div>
                                    <div className="bank-item-">
                                        <span className="-span">已买</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="hot-part">
                    <div className="hot-title">
                        <div className="hot-title-left">我的视频</div>
                        <div className="hot-title-right">
                            <Link to="/" className="hot-a">
                                更多
                            </Link>
                        </div>
                    </div>
                    <div className="yu-content">
                        {(videos || []).map((video) => {
                            return (
                                <div
                                    className="mine-video-item"
                                    key={video.id}
                                    onClick={() => {
                                        history.push("videoDetail", {
                                            video
                                        })
                                    }}
                                >
                                    <div className="video-item-top">
                                        {/* <img src={videoPoster} alt='' className='video-img' /> */}
                                        <video
                                            className="video-img"
                                            src={
                                                video.videoUrl.indexOf("http://bujinge.com:8080/jeecg-boot/") != -1
                                                    ? video.videoUrl
                                                    : `http://bujinge.com:8080/jeecg-boot/${video.videoUrl}`
                                            }
                                        ></video>
                                        <img src={videoPlay} alt="" className="play-img" />
                                    </div>
                                    <div className="video-item-middle">{video.name} </div>
                                    <div className="video-item-btm">
                                        <div className="video-item-btm-left">
                                            <img src={kehu} alt="" className="video-avatar" />
                                            <span className="video-name">{video.createBy}</span>
                                        </div>
                                        <div className="video-item-btm-right">
                                            <div className="video-right-item">
                                                <img src={commentIco} alt="" className="comment-pic" />
                                                <span className="comment-title">{video.commentCount||0}</span>
                                            </div>
                                            <div className="video-right-item">
                                                <img src={zanIcon} alt="" className="comment-pic" />
                                                <span className="zan-title">{video.likeCount||0}</span>
                                            </div>
                                            <div className="video-right-item">
                                                <img src={pointIco} alt="" className="comment-pic" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="tarbar">
                <div className="tarbar-home" onClick={() => history.push("/index")}>
                    <img src={homeIcon} alt="" className="home-icon" />
                    <div className="home-name">
                        <Link to="/">首页</Link>
                    </div>
                </div>
                <div className="tarbar-middle" onClick={() => history.push("/message")}>
                    <img src={menuIcon} alt="" className="menu-icon" />
                </div>
                <div className="tarbar-home"  onClick={() => history.push("/person")}>
                    <img src={mineIcon} alt="" className="home-icon" />
                    <div className="home-named">
                        <Link to="/mine">我的</Link>
                    </div>
                </div>
            </div>
            <div className="popup" style={{ display: visible ? "block" : "none" }}>
                <div className="popup-div"></div>
                <div className="popop-box">
                    <div className="popup-close">
                        <img
                            src={popupClose}
                            alt=""
                            className="popup-close-icon"
                            onClick={() => {
                                setVisible(false)
                            }}
                        />
                    </div>
                    <div className="popup-con" style={{ display: "flex", width: "280px" }}>
                        <Button
                            type="primary"
                            onClick={() => {
                                history.push("/companyAttest")
                            }}
                            style={{ flex: 1, marginRight: "5px" }}
                        >
                            企业认证
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                history.push("/attest")
                            }}
                            style={{ flex: 1 }}
                        >
                            个人认证
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
