import React, { useState } from "react"
import "./../../css/manager.css"
import addressIco from "./../../assets/address.png"
import viewsIco from "./../../assets/views.png"
import zanIco from "./../../assets/zan_icon.png"
import managerOne from "./../../assets/1.png"
import renIcon from "./../../assets/ren.png"
import { managerList } from "../../api/user"
import { resource } from "../../utils/resource"
import backImg from "../../assets/left.png"

export default function HotManager({ history }) {
    const [managers, setManagers] = useState()
    if (!managers) {
        let location = localStorage.getItem('position') || ''
        managerList(location).then(({ result }) => {
            const { records } = result
            setManagers(records)
        })
    }
    const back = () => {
        window.history.back(-1)
    }

    return (
        // <div className="wrapper">
        //     <div className="container">
        //         {/* 顶部标题 */}
        //         <div className="page-title">
        //             <img src={backImg} alt="" className="title-left1" onClick={back} />
        //             热门经理
        //         </div>
        <div className="wrapper-video" style={{ paddingBottom: "0", height: "100%" }}>
            <div style={{ paddingTop: "1.125rem", height: "100%" }}>
                {/* 顶部标题 */}
                <div className="page-title" style={{ backgroundColor: "#E1E0FF" }}>
                    <img src={backImg} alt="" className="title-left1" onClick={back} />
                    <div>热门经理</div>
                </div>
                <div className="manager-con" style={{ height: "100%", marginTop: "3.5rem",padding:"0 .625rem" }}>
                    {(managers || []).map((manager) => {
                        return (
                            <div
                                key={manager.id}
                                className="manager-item"
                                onClick={() => history.push("/managerDetail", manager)}
                            >
                                <div className="manager-item-top">
                                    {/* 头像 */}
                                    <div className="item-top-left">
                                        <img src={resource(manager.avatar)} alt="" className="manager-avatar-pic" />
                                    </div>
                                    {/* 经理名称 */}
                                    <div className="item-top-right">
                                        <div className="item-top-ren">
                                            <div className="item-top-ren-title">{manager.realname}</div>
                                            <div className="item-top-ren-rz">
                                                <img src={renIcon} alt="" className="ren-icon" />
                                                <span className="ren-span">已认证</span>
                                            </div>
                                        </div>
                                        <div className="item-top-desc">{manager.post_dictText}</div>
                                        <div className="item-top-con">{manager.introduction}</div>
                                    </div>
                                </div>
                                <div className="manager-item-btm">
                                    {/* 经理定位 */}
                                    <div className="bottom-left-btm">
                                        <img src={addressIco} alt="" className="address-image" />
                                        <span className="address-span">{manager.userLocation}</span>
                                    </div>
                                    <div className="video-item-btm-right">
                                        {/* 浏览次数 */}
                                        <div className="video-right-item">
                                            <img src={viewsIco} alt="" className="comment-pic" />
                                            <span className="comment-title">{manager.commentCount||0}</span>
                                        </div>
                                        {/* 点赞次数 */}
                                        <div className="video-right-item">
                                            <img
                                                src={zanIco}
                                                alt=""
                                                className="comment-pic"
                                                style={{ height: ".8125rem" }}
                                            />
                                            <span className="zan-title">{manager.likeCount||0}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="avatar-top">
                                    <img src={managerOne} alt="" className="avatar-top-pic" />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
