import axios from 'axios'

export function upload(file) {
    let formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("biz", 'temp')

    const config = {
        headers: {
            "Content-Type": "multipart/form-data;boundary=" + new Date().getTime(),
            'X-Access-Token': localStorage.getItem('token')
        }
    };

    return axios.post("http://bujinge.com:8080/jeecg-boot/sys/common/upload", formData, config).then(({ data }) => {
        console.log(data)
        return data.message
        // return `http://bujinge.com:8080/jeecg-boot/${data.message}`
    })

}