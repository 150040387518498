import React, { useState } from 'react';
import './../../css/sort.css';
import { Range, WingBlank } from 'antd-mobile-v2';
import backImg from "../../assets/left.png";

export default function Sort({ history }) {
    const [proRateTemp, setProRateTemp] = useState([2,3]);
    const [proCicleTemp, setProCicleTemp] = useState([0,60]);
    const [tabs, setTabs] = useState([
        { id: 1, tabName: '抵押贷' },
        { id: 2, tabName: '信用贷' }
    ]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [use, setUse] = useState([
        { id: 1, name: '经营贷款' },
        { id: 2, name: '消费贷款' }
    ]);
    const [useIndex, setUseIndex] = useState(0);
    const [type, setType] = useState([
        { id: 1, name: '企业贷款' },
        { id: 2, name: '个人贷款' }
    ]);
    const [typeIndex, setTypeIndex] = useState(0);

    const [apply, setApply] = useState([
        { id: 1, name: '房一抵', checked: false },
        { id: 2, name: '房二抵', checked: false },
        { id: 3, name: '车辆抵押', checked: false },
        { id: 4, name: '股权抵押', checked: false },
        { id: 5, name: '按揭房抵押', checked: true },
        { id: 20, name: '其他合法财产抵押', checked: true }
    ]);
    const [applyIndex, setApplyIndex] = useState(0);
    const [lx, setLx] = useState([
        { id: 1, name: '先息后本' },
        { id: 2, name: '等额本息' },
        { id: 3, name: '等额本金' },
        { id: 4, name: '其他' },
    ]);
    const [lxIndex, setLxIndex] = useState(0);

    const [lxqj, setLxqj] = useState([
        { id: 0, name: '滑动选择' },
        { id: 1, name: '0-1%' },
        { id: 2, name: '1-2%' },
        { id: 3, name: '2-3%' }
    ]);
    const [lxqjIndex, setLxqjIndex] = useState(0);

    const [year, setYear] = useState([
        { id: 0, name: '滑动选择' },
        { id: 1, name: '0-12月' },
        { id: 2, name: '0-36月' },
        { id: 3, name: '0-48月' },
        { id: 4, name: '0-60月' },
        { id: 5, name: '0-120月' },
        { id: 6, name: '0-360月' },
    ]);
    const [condition, setCondition] = useState(0);

    const [yearIndex, setYearIndex] = useState(0);
    const setFormValue = (name, value) => {
        setCondition({
            ...condition,
            [name]: value
        })
        console.log(condition)
    }
    const yearSelect = (id) => {
        console.log(id);

        setYearIndex(id)
    }
    const rangeChange = (e) => {
        // [0, 4]
        
        setProRateTemp(e)
        // console.log(e,'sadfasfdf');
    }
    const rangeAfterChange = (e) => {
        // console.log(e);
    }
    const rangeChanged = (e) => {
        // [0, 12]
        setProCicleTemp (e)
        // console.log(e);
    }
    const rangeAfterChanged = (e) => {
        // console.log(e);
    }
    var isBox1Show = currentIndex === 0 ? 'block' : 'none';
    var isbox2Show = currentIndex === 1 ? 'block' : 'none';
    var tabList = tabs.map(function (res, index) {
        var tabStyle = index === currentIndex ? 'subCtrl active' : 'subCtrl';
        return <li key={index} onClick={() => {
            setCurrentIndex(index)
            if (currentIndex == 0) {//信用贷款
                setApply([
                    { id: 6, name: '社保', checked: false },
                    { id: 7, name: '公积金', checked: false },
                    { id: 8, name: '打卡工资', checked: false },
                    { id: 9, name: '个税', checked: false },
                    { id: 10, name: '房贷', checked: true },
                    { id: 11, name: '车贷', checked: false },
                    { id: 12, name: '保单', checked: true },
                    { id: 13, name: '信用卡', checked: false },
                    { id: 14, name: '微粒贷', checked: false },
                    { id: 15, name: '流水贷', checked: false },
                    { id: 16, name: '烟草证贷', checked: false },
                    { id: 17, name: '学历贷', checked: false },
                    { id: 18, name: '装修贷', checked: false },
                    { id: 19, name: '房值贷', checked: false },
                ]);

            } else {//抵押贷款
                setApply([
                    { id: 1, name: '房一抵', checked: false },
                    { id: 2, name: '房二抵', checked: false },
                    { id: 3, name: '车辆抵押', checked: false },
                    { id: 4, name: '股权抵押', checked: false },
                    { id: 5, name: '按揭房抵押', checked: true },
                    { id: 20, name: '其他合法财产抵押', checked: true },
                ]);
            }
            console.log(currentIndex,index,'tryrtyt')

        }} className={tabStyle}>{res.tabName}</li>
    });
    const back = () => {
        window.history.back(-1)
    }
    return (
        <div className='wrapper1'>
            <div className='container1'>
                <div className='page-title'>
                    <img src={backImg} alt='' className='title-left1' onClick={back} />条件筛选
                </div>
                <div className='manager-con container2'>
                    <div className="listWrap">
                        <ul className="subNavWrap">
                            {tabList}
                        </ul>
                        <div className="newsList">
                            <div style={{ "display": isBox1Show }} >
                                <div className='sort-box'>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>贷款用途（抵押贷款）</div>
                                        <div className='sort-box-item-right'>
                                            <div className='sort-flex'>
                                                {use.map((item, index) =>
                                                    <span key={index} className={useIndex == index ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        setUseIndex(index)
                                                        setFormValue("useType", item.name)

                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>贷款类型（抵押贷款）</div>
                                        <div className='sort-box-item-right'>
                                            <div className='sort-flex'>
                                                {type.map((item, index) =>
                                                    <span key={index} className={typeIndex === index ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        setTypeIndex(index)
                                                        setFormValue("loanType", item.name)

                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>申请条件-多选（抵押贷款）</div>
                                        <div className='sort-box-item-right'>
                                            <div className='sort-flex'>
                                                {apply.map((item, index) =>
                                                    <span key={index} className={item.checked ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        let items = [...apply];
                                                        items[index].checked = !items[index].checked;
                                                        setApply(items)
                                                        setFormValue("label", items)

                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>利息（抵押贷款）</div>
                                        <div className='sort-box-item-right'>
                                            <div className='sort-flex'>
                                                {lx.map((item, index) =>
                                                    <span key={index} className={lxIndex == index ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        setLxIndex(index)
                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>利息区间（抵押贷款）</div>
                                        <div className='sort-box-item-right'>
                                            <div className='range-sort' style={{ width: '100%' }}>
                                                <WingBlank size="sm">
                                                    <Range
                                                    style = {{touchAction:'pan-y'}}
                                                        min={0}
                                                        max={4}
                                                        defaultValue={proRateTemp}
                                                        step={null}
                                                        marks={{
                                                            0:"0%", 
                                                            1:"1%", 
                                                            2:"2%", 
                                                            3:"3%", 
                                                            4:"4%"}}
                                                        onChange={rangeChange}
                                                        onAfterChange={rangeAfterChange}
                                                        trackStyle={[{ backgroundColor: '#8C66EC' }, { backgroundColor: '#8C66EC' }, { height: '4px' }]}
                                                        handleStyle={[{ backgroundColor: '#fff' }, { backgroundColor: '#fff' }]}
                                                        railStyle={{ backgroundColor: '#EEEEFF' }}
                                                    />
                                                </WingBlank>
                                            </div>
                                            <div className='sort-flex'>
                                                {lxqj.map((item, index) =>
                                                    <span key={index} className={lxqjIndex == index ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        setLxqjIndex(index)
                                                        // if (index==0){
                                                        //     setProRateTemp([0,1])
                                                        // }else if (index==1){
                                                        //     setProRateTemp([1,2])
                                                        // }
                                                        
                                                        // setFormValue("proRate", item.name)
                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>贷款年限</div>
                                        <div className='sort-box-item-right'>
                                            <div className='range-sort' style={{ width: '100%' }}>
                                                <WingBlank size="sm">
                                                    <Range
                                                        min={0}
                                                        max={360}
                                                        defaultValue={proCicleTemp}
                                                        step={null}
                                                        marks={{
                                                            0:"0",
                                                            60:"60月",
                                                            120:"120月",
                                                            240:"240月",
                                                            360:"360月"}}
                                                        onChange={rangeChanged}
                                                        onAfterChange={rangeAfterChanged}
                                                        trackStyle={[{ backgroundColor: '#8C66EC' }, { backgroundColor: '#8C66EC' }, { height: '4px' }]}
                                                        handleStyle={[{ backgroundColor: '#fff' }, { backgroundColor: '#fff' }]}
                                                        railStyle={{ backgroundColor: '#EEEEFF' }}
                                                    />
                                                </WingBlank>
                                            </div>
                                            <div className='sort-flex'>
                                                {year.map((item, index) =>
                                                    <span key={index} className={yearIndex == index ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        yearSelect(index)
                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bank-detail-btn'>
                                    <div className='bank-detail-btn-free' onClick={() => {
                                        let applyIdsList = ''
                                        apply.forEach(item=>{
                                            if (item.checked) applyIdsList+=item.id+','
                                        })
                                        if(applyIdsList){
                                            applyIdsList = applyIdsList.slice(0,applyIdsList.length-1)
                                        }
                                        let proRateMin = 0;
                                        let proRateMax = 0;
                                        let proCircleMin = 0;
                                        let proCircleMax = 0;
                                        if (lxqjIndex==0){
                                            proRateMin=proRateTemp[0]
                                            proRateMax=proRateTemp[1]
                                        }else{
                                            if(lxqjIndex==1){
                                                proRateMin= 0
                                                proRateMax= 1
                                            }else if(lxqjIndex==2){
                                                proRateMin= 1
                                                proRateMax= 2
                                            }else if(lxqjIndex==3){
                                                proRateMin= 2
                                                proRateMax= 3
                                            }
                                        }
                                        if (yearIndex==0){
                                            proCircleMin=proCicleTemp[0]
                                            proCircleMax=proCicleTemp[1]
                                        }else{
                                            if(yearIndex==1){
                                                proCircleMin= 0
                                                proCircleMin= 12
                                            }else if(yearIndex==2){
                                                proCircleMin= 0
                                                proCircleMin= 36
                                            }else if(yearIndex==3){
                                                proCircleMin= 0
                                                proCircleMin= 48
                                            }else if(yearIndex==4){
                                                proCircleMin= 0
                                                proCircleMin= 60
                                            }else if(yearIndex==5){
                                                proCircleMin= 0
                                                proCircleMin= 120
                                            }else if(yearIndex==6){
                                                proCircleMin= 0
                                                proCircleMin= 360
                                            }
                                        }

                                        console.log('asdasdasdasdasd')

                                        // yearIndex  proCicleTemp
                                        history.push("/search", {
                                            type:2,
                                            proType:tabs[currentIndex]['tabName'],
                                            proPurpose:use[useIndex]['id'],
                                            proClass:type[typeIndex]['name'],
                                            proConditionList:applyIdsList,
                                            proRepaymentMethod:lx[lxIndex]['id'],
                                            proCircleMin:proCircleMin,
                                            proCircleMax:proCircleMax,
                                            proRateMin:proRateMin,
                                            proRateMax:proRateMax,
                                        })
                                    }}>完成</div>
                                    <div className='bank-detail-btn-online' onClick={() => window.location.reload()}>重置</div>
                                </div>
                            </div>
                            <div style={{ "display": isbox2Show }}>
                                <div className='sort-box'>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>贷款用途(信用贷款)</div>
                                        <div className='sort-box-item-right'>
                                            <div className='sort-flex'>
                                                {use.map((item, index) =>
                                                    <span key={index} className={useIndex == index ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        setUseIndex(index)
                                                        setFormValue("useType", item.name)

                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>贷款类型(信用贷款)</div>
                                        <div className='sort-box-item-right'>
                                            <div className='sort-flex'>
                                                {type.map((item, index) =>
                                                    <span key={index} className={typeIndex === index ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        setTypeIndex(index)
                                                        setFormValue("loanType", item.name)

                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>申请条件-多选(信用贷款)</div>
                                        <div className='sort-box-item-right'>
                                            <div className='sort-flex'>
                                                {apply.map((item, index) =>
                                                    <span key={index} className={item.checked ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        let items = [...apply];
                                                        items[index].checked = !items[index].checked;
                                                        setApply(items)
                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>利息(信用贷款)</div>
                                        <div className='sort-box-item-right'>
                                            <div className='sort-flex'>
                                                {lx.map((item, index) =>
                                                    <span key={index} className={lxIndex == index ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        setLxIndex(index)
                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>利息区间(信用贷款)</div>
                                        <div className='sort-box-item-right'>
                                            <div className='range-sort' style={{ width: '100%' }}>
                                                <WingBlank size="sm">
                                                    <Range
                                                        min={0}
                                                        max={4}
                                                        step={null}
                                                        defaultValue={proRateTemp}
                                                        marks={{
                                                            0:"0%",
                                                         1:"1%", 
                                                         2:"2%", 
                                                         3:"3%", 
                                                         4:"4%"}}
                                                        onChange={rangeChange}
                                                        onAfterChange={rangeAfterChange}
                                                        trackStyle={[{ backgroundColor: '#8C66EC' }, { backgroundColor: '#8C66EC' }, { height: '4px' }]}
                                                        handleStyle={[{ backgroundColor: '#fff' }, { backgroundColor: '#fff' }]}
                                                        railStyle={{ backgroundColor: '#EEEEFF' }}
                                                    />
                                                </WingBlank>
                                            </div>
                                            <div className='sort-flex'>
                                                {lxqj.map((item, index) =>
                                                    <span key={index} className={lxqjIndex === item.id ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        setLxIndex(item.id)

                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>贷款年限(信用贷款)</div>
                                        <div className='sort-box-item-right'>
                                            <div className='range-sort' style={{ width: '100%' }}>
                                                <WingBlank size="sm">
                                                    <Range
                                                        min={0}
                                                        max={360}
                                                        defaultValue={proCicleTemp}
                                                        step={null}
                                                        marks={{
                                                            0:"0",
                                                            60:"60月",
                                                            120:"120月",
                                                            240:"240月",
                                                            360:"360月"
                                                        }}
                                                        onChange={rangeChanged}
                                                        onAfterChange={rangeAfterChanged}
                                                        trackStyle={[{ backgroundColor: '#8C66EC' }, { backgroundColor: '#8C66EC' }, { height: '4px' }]}
                                                        handleStyle={[{ backgroundColor: '#fff' }, { backgroundColor: '#fff' }]}
                                                        railStyle={{ backgroundColor: '#EEEEFF' }}
                                                    />
                                                </WingBlank>
                                            </div>
                                            <div className='sort-flex'>
                                                {year.map((item, index) =>
                                                    <span key={index} className={yearIndex === item.id ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        yearSelect(item.id)
                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bank-detail-btn'>
                                    <div className='bank-detail-btn-free' onClick={() => {
                                        let applyIdsList = ''
                                        apply.forEach(item=>{
                                            if (item.checked) applyIdsList+=item.id+','
                                        })
                                        if(applyIdsList){
                                            applyIdsList = applyIdsList.slice(0,applyIdsList.length-1)
                                        }
                                        let proRateMin = 0;
                                        let proRateMax = 0;
                                        let proCircleMin = 0;
                                        let proCircleMax = 0;
                                        if (lxqjIndex==0){
                                            proRateMin=proRateTemp[0]
                                            proRateMax=proRateTemp[1]
                                        }else{
                                            if(lxqjIndex==1){
                                                proRateMin= 0
                                                proRateMax= 1
                                            }else if(lxqjIndex==2){
                                                proRateMin= 1
                                                proRateMax= 2
                                            }else if(lxqjIndex==3){
                                                proRateMin= 2
                                                proRateMax= 3
                                            }
                                        }
                                        if (yearIndex==0){
                                            proCircleMin=proCicleTemp[0]
                                            proCircleMax=proCicleTemp[1]
                                        }else{
                                            if(yearIndex==1){
                                                proCircleMin= 0
                                                proCircleMin= 12
                                            }else if(yearIndex==2){
                                                proCircleMin= 0
                                                proCircleMin= 36
                                            }else if(yearIndex==3){
                                                proCircleMin= 0
                                                proCircleMin= 48
                                            }else if(yearIndex==4){
                                                proCircleMin= 0
                                                proCircleMin= 60
                                            }else if(yearIndex==5){
                                                proCircleMin= 0
                                                proCircleMin= 120
                                            }else if(yearIndex==6){
                                                proCircleMin= 0
                                                proCircleMin= 360
                                            }
                                        }

                                        console.log('asdasdasdasdasd')

                                        // yearIndex  proCicleTemp
                                        history.push("/search", {
                                            type:2,
                                            proType:tabs[currentIndex]['tabName'],
                                            proPurpose:use[useIndex]['id'],
                                            proClass:type[typeIndex]['name'],
                                            proConditionList:applyIdsList,
                                            proRepaymentMethod:lx[lxIndex]['id'],
                                            proCircleMin:proCircleMin,
                                            proCircleMax:proCircleMax,
                                            proRateMin:proRateMin,
                                            proRateMax:proRateMax,
                                        })
                                    }}>完成</div>
                                    <div className='bank-detail-btn-online' onClick={() => window.location.reload()}>重置</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}
