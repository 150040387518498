import Home from "../js/index/index"
import Mine from "../js/mine/mine"
import Person from "../js/mine-person/person"
import Search from "../js/search/search"
import HotCompany from "../js/hotCompany/company"
import Manager from "../js/hotManager/HotManager"
import ManagerDetail from "../js/hotManagerDetail/HotManagerDetail"
import HotVideo from "../js/hotVideo/HotVideo"
import HotVideoDetail from "../js/hotVideoDetail/videoDetail"
import Bank from "../js/hotBank/bank"
import BankDetail from "../js/hotBankDetail/bankDetail"
import Sort from "../js/sort/sort"
import CompanySort from "../js/sort/companySort"
import InputInfo from "../js/input/inputInfo"
import Success from "../js/success/success"
import ForgetPassword from "../js/forget-password/forget"
import UpdatePassword from "../js/update-password/update"
import Login from "../js/login/login"
import Logon from "../js/logon/logon"
import Attention from "../js/attention/Attention"
import Upload from "../js/upload/upload"
import UploadVideo from "../js/upload-video/uploadVideo"
import PersonInfo from "../js/personn/person"
import Attest from "../js/attest/attest"
import Message from "../js/message/message"
import Publish from "../js/publish/publish"
import Member from "../js/member/member"
import CompanyAttest from "../js/company-attest/attest"
import MyVideo from "../js/my-video/video"
import BookingUserDetail from "../js/mine-person/booking_user_detail"
import UploadMessage from "../js/message/uploadMessage"
import MyProducts from "../js/mine/myProducts"
import MyProductsBookings from "../js/mine/myProductsBookings"
import MyComments from "../js/mine/myComments"

const appRoutes = [
    {
        path: "/index",
        component: Home
    },
    {
        path: "/mine",
        component: Mine
    },
    {
        path: "/person",
        component: Person
    },
    {
        path: "/search",
        component: Search
    },
    {
        path: "/company",
        component: HotCompany
    },
    {
        path: "/manager",
        component: Manager
    },
    {
        path: "/managerDetail",
        component: ManagerDetail
    },
    {
        path: "/video",
        component: HotVideo
    },
    {
        path: "/myvideo",
        component: MyVideo
    },
    {
        path: "/videoDetail",
        component: HotVideoDetail
    },
    {
        path: "/bank",
        component: Bank
    },
    {
        path: "/bankDetail",
        component: BankDetail
    },
    {
        path: "/companySort",
        component: CompanySort
    },
    {
        path: "/sort",
        component: Sort
    },
    {
        path: "/companyAttest",
        component: CompanyAttest
    },
    {
        path: "/input",
        component: InputInfo
    },
    {
        path: "/success",
        component: Success
    },
    {
        path: "/forget",
        component: ForgetPassword
    },
    {
        path: "/update",
        component: UpdatePassword
    },
    {
        path: "/register",
        component: Login
    },
    {
        path: "/login",
        component: Logon
    },
    {
        path: "/attention",
        component: Attention
    },
    {
        path: "/upload",
        component: Upload
    },
    {
        path: "/uploadVideo",
        component: UploadVideo
    },
    {
        path: "/personInfo",
        component: PersonInfo
    },
    {
        path: "/attest",
        component: Attest
    },
    {
        path: "/message",
        component: Message
    },
    {
        path: "/publish",
        component: Publish
    },
    {
        path: "/member",
        component: Member
    },
    {
        path: "/booking_user_detail",
        component: BookingUserDetail
    },
    {
        path: "/uploadMessage",
        component: UploadMessage
    },
    {
        path: "/myProducts",
        component: MyProducts
    },
    {
        path: "/myProductsBookings",
        component: MyProductsBookings
    },
    {
        path: "/myComments",
        component: MyComments
    }
]

export { appRoutes }
