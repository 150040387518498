import React, { useState } from 'react';
import './../../css/attest.css';
import { Picker, List, Toast } from 'antd-mobile-v2';
import { ImagePicker } from 'antd-mobile-v2';
import addressIcon from './../../assets/add.png';
import rightIcon from './../../assets/right.png';
import {
    upload
} from '../../api/upload'
import {
    bankapplication
} from '../../api/user'
import { resource, spliceUrl } from '../../utils/resource';
import backImg from "../../assets/left.png";
import provinceCity from './../../assets/provinceCity.json';

const CustomChildren = props => (
    <div
        onClick={props.onClick}
        style={{ backgroundColor: '#fff', paddingLeft: 15 }}
    >
        <div className="test" style={{ display: 'flex', height: '45px', lineHeight: '45px' }}>
            <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{props.children}</div>
            <div style={{ textAlign: 'right', color: '#888', marginRight: 15 }}>{props.extra}</div>
        </div>
    </div>
);

export default function CompanyAttest(props) {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const [attest, setAttest] = useState({
        identityLevel: userInfo.identityLevel,
        identityStatus: 4,
        realname: userInfo.realname,
        userCompany: userInfo.userCompany,
        telephone: userInfo.telephone,
        userLocation: userInfo.userLocation,
        userIdCard: userInfo.userIdCard,
        certificationInformation1: userInfo.certificationInformation1,
        certificationInformation2: userInfo.certificationInformation2
    })

    const setAttestValue = (name, value) => {
        setAttest({
            ...attest,
            [name]: value
        })
    }

    console.log(attest);


    const onChange = (name, fs, type, index) => {
        if ('remove' !== type) {
            upload(fs[fs.length - 1].file).then((url) => {
                fs[fs.length - 1].url = url
                setAttestValue(name, JSON.stringify(fs.map(item => { return { url: spliceUrl(item.url) } })))
            })
        } else {
            fs.splice(index, 0)
            setAttestValue(name, JSON.stringify(fs.map(item => { return { url: spliceUrl(item.url) } })))
        }

    }
    const back = () => {
        window.history.back(-1)
    }

    return (
        // <div className='wrapper'>
        //     <div className='container'>
        //         <div className='page-title'>
        //             <img src={backImg} alt='' className='title-left1' onClick={back} />企业认证<span className="delete-titile">({userInfo.identityStatus === 2 ? "已" : "待"}审核)</span>
        //         </div>
        <div className="wrapper1" >
        <div className='container1'>
            {/* 顶部标题 */}
            <div className="page-title" style={{ backgroundColor: "#E1E0FF" }}>
            <img src={backImg} alt='' className='title-left1' onClick={back} />企业认证<span className="delete-titile">({userInfo.identityStatus === 2 ? "已" : "待"}审核)</span>
            </div>
                <div className='manager-con container2' style={{ height: "100%", marginTop: "2.5rem" }}>
                    <div className='input-box'>

                        <div className='input-item'>
                            <div className='input-item-left'>企业性质</div>
                            <div className='input-item-right' style={{ textAlign: "right" }}>
                                {/* <select className='attest-select' value={attest.identityLevel} onChange={({ target }) => { setAttestValue('identityLevel', target.value) }}>
                                    <option value='行业达人'>行业达人</option>
                                    <option value='客户经理'>客户经理</option>
                                </select> */}
                                <input type='text' placeholder='请输入企业性质' value={attest.identityLevel} onChange={({ target }) => { setAttestValue('identityLevel', target.value) }} className='input-text person-input' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>法人姓名</div>
                            <div className='input-item-right'>
                                <input type='text' placeholder='请输入姓名' value={attest.realname} onChange={({ target }) => { setAttestValue('realname', target.value) }} className='input-text person-input' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>公司电话</div>
                            <div className='input-item-right'>
                                <input type='text' placeholder='请输入手机号' value={attest.telephone} onChange={({ target }) => { setAttestValue('telephone', target.value) }} className='input-text person-input' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>法人身份证</div>
                            <div className='input-item-right'>
                                <input type='text' placeholder='请输入身份证号' value={attest.userIdCard} onChange={({ target }) => { setAttestValue('userIdCard', target.value) }} className='input-text person-input' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>公司名称</div>
                            <div className='input-item-right'>
                                <input type='text' placeholder='请输入公司全称' value={attest.userCompany} onChange={({ target }) => { setAttestValue('userCompany', target.value) }} className='input-text person-input' />
                            </div>
                        </div>

                        <div className='attest-item'>
                            <div className='attest-left'>营业执照、法人身份证上传</div>
                            <div className='attest-box'>
                                <ImagePicker
                                    onChange={(var1, var2, var3) => onChange('certificationInformation1', var1, var2, var3)}
                                    files={(() => {
                                        const files = JSON.parse(attest.certificationInformation1 || "[]")
                                        return files.map(item => {
                                            item.url = resource(item.url)
                                            return item;
                                        })
                                    })()}
                                    accept="image/gif,image/jpeg,image/jpg,image/png"
                                />

                            </div>
                        </div>
                        <div className='attest-item'>
                            <div className='attest-left'>相关资料金融、证券、小贷等上传</div>
                            <div className='attest-box'>
                                <ImagePicker
                                    onChange={(var1, var2, var3) => onChange('certificationInformation2', var1, var2, var3)}
                                    files={(() => {
                                        const files = JSON.parse(attest.certificationInformation2 || "[]")
                                        return files.map(item => {
                                            item.url = resource(item.url)
                                            return item;
                                        })
                                    })()}
                                    accept="image/gif,image/jpeg,image/jpg,image/png"
                                />

                            </div>
                        </div>

                        <div className='upload-address'>
                            <div className='upload-address-left'>
                                <img src={addressIcon} alt='' className='upload-address-icon' />
                            </div>
                            <div className='upload-address-right'>
                                <List style={{ width: '100%', border: "none" }} >
                                    <Picker
                                        title="选择地区"
                                        extra=""
                                        cols='1'
                                        data={provinceCity}
                                        value={[userInfo.identityStatus !== 2 ? '' : attest.userLocation]}
                                        onChange={v => setAttestValue('userLocation', v[0])}
                                        onOk={v => setAttestValue('userLocation', v[0])}
                                    >
                                        <CustomChildren>所在位置</CustomChildren>
                                    </Picker>
                                </List>
                                <img src={rightIcon} alt='' className='upload-right-icon' />
                            </div>
                        </div>

                    </div>
                    <div className='input-btn'>
                        <button className='input-submit' onClick={() => {
                            if (userInfo.identityStatus === 4) {
                                Toast.fail("已提交申请请勿重复提交，审核完成后可修改认证")
                            } else {
                                bankapplication(attest).then(res => {
                                    if (res.success) {
                                        localStorage.setItem("userInfo", JSON.stringify({
                                            ...userInfo,
                                            ...attest
                                        }))
                                        Toast.success("企业认证提交成功！")
                                    } else {
                                        Toast.fail(res.message)
                                    }
                                })
                            }

                        }}>提交</button>
                    </div>
                </div>
            </div>
        </div>
    )

}
