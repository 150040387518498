import React, { useEffect, useState } from 'react';
import './../../css/company.css';
import companyPic from './../../assets/hot-company.png';
import addressIco from './../../assets/address.png';
import viewsIco from './../../assets/views.png';
import zanIco from './../../assets/zan_icon.png';
import recIco from './../../assets/rec.png';
import backImg from "../../assets/left.png";
import { dtBooking, myproducts, myvideos, companyList, userInfoByUserName } from "../../api/user";
import bankPic from "../../assets/bank_logo.png";
import clockPic from "../../assets/date_icon.png";
import kehu from "../../assets/kehu.png";
import { deleteProduct, getProduct } from "../../api/product";
import { resource } from "../../utils/resource";
import top from "../../assets/top.png";
import { commentList, deleteComment } from "../../api/comment";
import { Toast } from "antd-mobile-v2";
import renIcon from "../../assets/ren.png";
import { config } from './../../utils/config'
import bankImg from "./../../assets/bank.png"

export default function Search({ history }) {
    const [products, setProducts] = useState()
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let pageNo = 0
    let total = 0
    let loading = false
    let init = true
    let orignialArr = []
    const [page,setPage] = useState(0)

    useEffect(() => {
        init = true
        pageNo = 0
        orignialArr = []
        getData()
        window.onscroll = function () {
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            //滚动条到底部的条件
            if (scrollTop + windowHeight == scrollHeight) {
                if(!init){
                    getData()
                }
                
                console.log('scrollTop')
            }
        }

    }, [])

    const getData = (reset)=>{
        if(reset){
            pageNo = 0
            orignialArr = []
        }
        if (!loading){
            let isAdd = (pageNo < (total/config.pageSize)) || (pageNo==0)
            console.log(pageNo,'22222assss')
            if (isAdd){
                pageNo++
                setPage(pageNo)
                console.log(pageNo,'assss')
                loading = true
                myproducts(pageNo,config.pageSize).then(res => {
                    
                    total = res.result.total
                    let result = res.result.records;
            var arr = []
            result.forEach(item=>{
                if (item.proType=='抵押贷'){
                    arr = [
                        { id: 1, name: '房一抵', checked: false },
                    { id: 2, name: '房二抵', checked: false },
                    { id: 3, name: '车辆抵押', checked: false },
                    { id: 4, name: '股权抵押', checked: false },
                    { id: 5, name: '按揭房抵押', checked: true },
                    { id: 20, name: '其他合法财产抵押', checked: true },
                    ]
                }else{
                    arr = [
                        { id: 6, name: '社保', checked: false },
                        { id: 7, name: '公积金', checked: false },
                        { id: 8, name: '打卡工资', checked: false },
                        { id: 9, name: '个税', checked: false },
                        { id: 10, name: '房贷', checked: true },
                        { id: 11, name: '车贷', checked: false },
                        { id: 12, name: '保单', checked: true },
                        { id: 13, name: '信用卡', checked: false },
                        { id: 14, name: '微粒贷', checked: false },
                        { id: 15, name: '流水贷', checked: false },
                        { id: 16, name: '烟草证贷', checked: false },
                        { id: 17, name: '学历贷', checked: false },
                        { id: 18, name: '装修贷', checked: false },
                        { id: 19, name: '房值贷', checked: false },
                    ]
                }
                    let temp = []
                    if (item.proConditionList){
                        item.proConditionList.forEach(item1=>{
                            let res = arr.find((item2)=>{
                                console.log(item2,item1)
                                return item2.id == item1
                            })
                            console.log(res)
                            if (res) temp.push(res.name)
                            
                        })
                    }
                    item.condition = temp
                    
                
            })
            orignialArr = [...orignialArr,...result]
            setProducts(orignialArr)
                    loading = false
                    init = false
                })
            }
        }
        
    }
    function back() {
        window.history.back(-1)
    }
    return (
        <div className='wrapper1'>
            <div className='container1'>
                <div className='page-title'>
                    <img src={backImg} alt='' className='title-left1' onClick={back} />我的产品
                </div>
            </div>

            <div className='sort-content container2'>
                {(products || []).map(product => {
                    return (
                        <div className='sort-item' key={product.id}>
                            <div className='sort-item-top' onClick={() => {
                                getProduct(product.id).then(({ result }) => {
                                    history.push("/BankDetail", { product: result })
                                })

                            }}>
                                <div className='top-left' onClick={() => {
                                    getProduct(product.id).then(({ result }) => {
                                        history.push("/BankDetail", { product: result })
                                    })

                                }}>

                                    <img style={{ width: "40px", height: "40px" }} src={product.proAvator||bankImg} alt='' className='bank-logo' />
                                </div>
                                <div className='top-right' onClick={() => {
                                    getProduct(product.id).then(({ result }) => {
                                        history.push("/BankDetail", { product: result })
                                    })

                                }}>
                                    <div className='top-right-title'>
                                        <div className='title-name'>{product.proName} <span className="delete-titile">({product.status === 1 ? "已" : "待"}审核)</span></div>
                                        <div className='title-hot'>{product.commentCount||0}</div>
                                    </div>
                                    <div style={{marginLeft:'0.5rem'}} className='top-right-address'>{product.location}</div>
                                </div>
                            </div>
                            <div className='sort-item-middle' onClick={() => {
                                getProduct(product.id).then(({ result }) => {
                                    history.push("/BankDetail", { product: result })
                                })

                            }}>

                                <div className='middle-item'>
                                    <div className='middle-item-top'>{product.proAmount}</div>
                                    <div className='middle-item-btm'>贷款额度(元)</div>
                                </div>
                                <div className='middle-item'>
                                    <div className='middle-item-top'>{!product.proRate?(product.proRateMin + '~'+ product.proRateMax + '%'):product.proRate}</div>
                                    <div className='middle-item-btm'>参考利率</div>
                                </div>
                                <div className='middle-item'>
                                    <div className='middle-item-top'>{!product.proCicle?(product.proCicleMin + '~'+ product.proCicleMax + '月'):product.proCicle}</div>
                                    <div className='middle-item-btm'>贷款周期</div>
                                </div>
                            </div>
                            <div className='sort-item-btm'>
                                <div className='btm-top'>
                                    {product.condition.map((item5,idx)=>
                                        <div className='btm-item'>{item5}</div>
                                    )
                                    }
                                </div>

                                <span style={{margin:'2rem .6rem'}} className="delete-titile" onClick={() => {
                                    deleteProduct(product.id).then(res => {
                                        if (res.success) {
                                            Toast.success(res.message)
                                            
                                            getData(true)
                                            // commentList(product.id, "product").then(res => {
                                            //     setProducts(res.result)
                                            // })
                                        }
                                    })
                                }}>

                                    删除
                                </span>
                            </div>
                        </div>
                    )
                })}


            </div>
            <div className='toTop' onClick={() => {
                    if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                        Toast.fail("未注册/登录/认证！")
                    } else {
                        history.push("/upload")
                    }
                }}>
                <img src={top} alt='' className='top-pic' />
                <div className='top-title' >上传</div>
            </div>
        </div>
    )

}
