import React, { useState, useEffect } from 'react';
import './../../css/sort.css';
import { Range, WingBlank } from 'antd-mobile-v2';
import backImg from "../../assets/left.png";
import { proNameList } from '../../api/product';

export default function Sort({ history }) {

    const [apply, setApply] = useState([]);

    useEffect(() => {
        proNameList().then(res => {
            let proNames = [];
            res.result.map((item, index) => {
                proNames.push({ id: index, name: item.proName, checked: false });
            })
            setApply(proNames)
        })

    }, [])


    const back = () => {
        window.history.back(-1)
    }


    return (
        <div className='wrapper1'>
            <div className='container1'>
                <div className='page-title'>
                    <img src={backImg} alt='' className='title-left1' onClick={back} />公司筛选
                </div>
                <div className='manager-con container2' >
                    <div className="listWrap">
                        <div className="newsList">
                            <div>
                                <div className='sort-box'>
                                    <div className='sort-box-item'>
                                        <div className='sort-box-item-left'>公司-多选</div>
                                        <div className='sort-box-item-right'>
                                            <div className='sort-flex'>
                                                {apply.map((item, index) =>
                                                    <span key={index} className={item.checked ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        let items = [...apply];
                                                        items[index].checked = !items[index].checked;
                                                        setApply(items)
                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bank-detail-btn'>
                                    <div className='bank-detail-btn-free' onClick={() => {
                                        let proNames = null;
                                        apply.map((item) => {
                                            if (item.checked) {
                                                if (!proNames) {
                                                    proNames = item.name;
                                                } else {
                                                    proNames = proNames + "," + item.name;
                                                }
                                            }
                                        })
                                        history.push({ pathname: "/search", state: { keyword: proNames } })
                                    }}>完成</div>
                                    <div className='bank-detail-btn-online' onClick={() => window.location.reload()}>重置</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}
