import React, { useEffect, useState } from "react"
import "./../../css/video.css"
import videoPoster from "./../../assets/jhk-1629292297794.png"
import videoPlay from "./../../assets/play_icon.png"
import commentIco from "./../../assets/comment_icon.png"
import zanIcon from "./../../assets/zan_icon.png"
import pointIco from "./../../assets/point.png"
import kehu from "./../../assets/kehu.png"
import top from "./../../assets/top.png"
import { addLike, deleteVideos, myvideos as hotVideoList, userInfoByUserName } from "../../api/user"
import { resource } from "../../utils/resource"
import backImg from "../../assets/left.png"
import { commentList, deleteComment } from "../../api/comment"
import { Toast } from "antd-mobile-v2"
import commentMessage from "../../assets/comment_message.png"
import renIcon from "../../assets/ren.png"
import { config } from './../../utils/config'
import VideoItem from '../../components/videoItem'

export default function MyVideo({ history }) {
    const [videoList, setVideoList] = useState()
    const [curPlaySrc, setCurPlaySrc] = useState('')
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    let pageNo = 0
    let total = 0
    let loading = false
    let init = true
    let orignialArr = []

    // if (userInfo === null || userInfo === undefined) {
    //     userInfo = {
    //         id: "",
    //         username: "",
    //         realname: "",
    //         avatar: "",
    //         birthday: null,
    //         sex: null,
    //         email: null,
    //         phone: "",
    //         orgCode: null,
    //         orgCodeTxt: null,
    //         status: 1,
    //         delFlag: 0,
    //         workNo: null,
    //         post: null,
    //         telephone: "11",
    //         createBy: null,
    //         createTime: "",
    //         updateBy: "",
    //         updateTime: "",
    //         activitiSync: 0,
    //         userIdentity: null,
    //         departIds: null,
    //         relTenantIds: null,
    //         clientId: null,
    //         userWx: null,
    //         userQrCode: "",
    //         userLocation: "上海市",
    //         userStatus: 5,
    //         userCompany: "",
    //         identityLevel: "",
    //         userIdCard: "",
    //         certificationInformation1: null,
    //         certificationInformation2: "",
    //         introduction: "",
    //         identityStatus: null
    //     }
    // }
    useEffect(() => {
        init = true
        pageNo = 0
        orignialArr = []
        getData()

        window.onscroll = function () {
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            //滚动条到底部的条件
            let index = Math.floor(scrollTop/240) + 1
                                        index = index - Math.floor(index/4.2)
                            
                                   
                                    // }
            console.log(index,orignialArr,'fffff')
            if (orignialArr.length>=1){
                setCurPlaySrc(orignialArr[index].videoUrl)
            }
                                    
                                    
            if (scrollTop + windowHeight == scrollHeight) {
                if(!init){
                    getData()
                }
                
                console.log('scrollTop')
            }
        }
    }, [])

    const getData = ()=>{
        if (!loading){
            let isAdd = (pageNo < (total/config.pageSize)) || (pageNo==0)
            console.log(pageNo,'22222assss')
            if (isAdd){
                pageNo++
                loading = true
                hotVideoList(pageNo,config.pageSize).then(res => {
                    orignialArr = [...orignialArr,...res.result.records]
                    setVideoList(orignialArr)
                    if(pageNo ===1){
                        setCurPlaySrc(orignialArr[0].videoUrl)
                    }
                    total = res.result.total
                    
                    loading = false
                    init = false
                })
            }
        }
        
    }
    const back = () => {
        window.history.back(-1)
    }
    return (
        <div className="wrapper1">
            <div className="container1">
                <div className="page-title">
                    <img src={backImg} alt="" className="title-left1" onClick={back} />
                    我的视频
                </div>
                <div className="company-con container2">
                    {(videoList || []).map((video,index) => {
                        return (
                            <div className="mine-video-item border-video" key={video.id}>
                                <div className="video-item-top">
                                <VideoItem  src={video.videoUrl} cover={video.cover} curPlaySrc={curPlaySrc} />
                                    {/* <video
                                        controls
                                        src={resource(video.videoUrl)}
                                        alt=""
                                        autoPlay={index==0?true:false}
                                        className="video-img2"
                                        onClick={() =>
                                            history.push("videoDetail", {
                                                video
                                            })
                                        }
                                    /> */}
                                </div>
                                <div className="video-item-middle" style={{ whiteSpace: "normal" }}>
                                    {video.name}{" "}
                                    <span className="delete-titile">({video.status === 1 ? "已" : "待"}审核)</span>
                                </div>
                                <div className="video-item-btm">
                                    <div className="video-item-btm-left">
                                        <img src={video.createUserAvatar||kehu} alt="" className="video-avatar" />
                                        <span className="video-name">{video.createBy}</span>
                                    </div>

                                    <div className="video-item-btm-right">
                                        <div className="video-right-item">
                                            <span
                                                className="delete-titile"
                                                onClick={() => {
                                                    deleteVideos(video.id).then((res) => {
                                                        if (res.success) {
                                                            Toast.success(res.message)
                                                            window.location.reload()
                                                        }
                                                    })
                                                }}
                                            >
                                                删除
                                            </span>
                                        </div>
                                        <div className="video-right-item">
                                            <img src={commentIco} alt="" className="comment-pic" />
                                            <span className="comment-title">{video.commentCount}</span>
                                        </div>
                                        <div className="video-right-item">
                                            <img src={zanIcon} alt="" className="comment-pic" />
                                            <span className="zan-title">{video.likeCount||0}</span>
                                        </div>
                                        <div className="video-right-item">
                                            <img src={pointIco} alt="" className="comment-pic" />
                                        </div>
                                    </div>
                                </div>
                                <div className="video-btm-time">{video.createTime}</div>
                            </div>
                        )
                    })}
                    {/* <div className='mine-video-item border-video'>
                        <div className='video-item-top'>
                            <img src={videoPoster} alt='' className='video-img' />
                            <img src={videoPlay} alt='' className='play-img' />
                        </div>
                        <div className='video-item-middle' style={{ whiteSpace: 'normal' }}>没有人可以绝对把握意外事故的发生，但是懂得风险管理的人， 一定不会遇事惊慌失措，因为一切都在其预料之中。 </div>
                        <div className='video-item-btm'>
                            <div className='video-item-btm-left'>
                                <img src={kehu} alt='' className='video-avatar' /><span className='video-name'>王晓庆</span>
                            </div>
                            <div className='video-item-btm-right'>
                                <div className='video-right-item'>
                                    <img src={commentIco} alt='' className='comment-pic' />
                                    <span className='comment-title'>2220</span>
                                </div>
                                <div className='video-right-item'>
                                    <img src={zanIcon} alt='' className='comment-pic' />
                                    <span className='zan-title'>280</span>
                                </div>
                                <div className='video-right-item'>
                                    <img src={pointIco} alt='' className='comment-pic' />
                                </div>
                            </div>
                        </div>
                        <div className='video-btm-time'>2021-07-20 15:30:00</div>
                    </div> */}
                </div>
            </div>
            <div className="toTop" onClick={() => {
                        if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                            Toast.fail("未注册/登录/认证！")
                        } else {
                            history.push("/uploadVideo")
                        }
                    }}>
                <img src={top} alt="" className="top-pic" />
                <div
                    className="top-title"
                    
                >
                    上传
                </div>
            </div>
        </div>
    )
}
