import React, { useEffect, useState } from 'react';
import './../../css/managerDetail.css';
import { resource } from '../../utils/resource'
import zanIcon from './../../assets/zan_icon.png';
import popupClose from './../../assets/popup-close.png';
import {
    addFans,
    addLike,
    deleteFans,
    fansCount1,
    isFans, myComments,
    queryBookingByUsername,
    userInfoByUserName
} from "../../api/user";
import backImg from "../../assets/left.png";
import commentAvatar from "../../assets/comment_avatar.png";
import commentWrite from "../../assets/comment_write.png";
import { commentAdd, commentList, deleteComment, deleteReComment, reComments } from "../../api/comment";
import { Toast } from "antd-mobile-v2";
import commentMessage from "../../assets/comment_message.png";
import down from "../../assets/down.png";

export default function ManagerDetail({ history }) {
    const user = history.location.state
    const [visible, setVisible] = useState('hidden');

    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const openPopup = () => {
        if (userInfo.id === '' || userInfo.id === undefined) {
            Toast.fail("请登录")
            history.push("/login")
        }
        setVisible('visible')
    }
    const [visible1, setVisible1] = useState('hidden');
    const closePopup1 = () => {
        setVisible1('hidden')
    }
    const [reKeywords, setReKeywords] = useState('');


    const back = () => {
        window.history.back(-1)
    }
    const [flag, setFlag] = useState(false);

    const [comments, setComments] = useState()
    const [keywords, setKeywords] = useState('');

    if (userInfo === null || userInfo === undefined) {
        userInfo = {
            "id": "",
            "username": "",
            "realname": "",
            "avatar": "",
            "birthday": null,
            "sex": null,
            "email": null,
            "phone": "",
            "orgCode": null,
            "orgCodeTxt": null,
            "status": 1,
            "delFlag": 0,
            "workNo": null,
            "post": null,
            "telephone": "11",
            "createBy": null,
            "createTime": "",
            "updateBy": "",
            "updateTime": "",
            "activitiSync": 0,
            "userIdentity": null,
            "departIds": null,
            "relTenantIds": null,
            "clientId": null,
            "userWx": null,
            "userQrCode": "",
            "userLocation": "上海市",
            "userStatus": 5,
            "userCompany": "",
            "identityLevel": "",
            "userIdCard": "",
            "certificationInformation1": null,
            "certificationInformation2": "",
            "introduction": "",
            "identityStatus": null
        }
    }
    useEffect(() => {
        myComments(userInfo.username).then(res => {
            const result = res.result;
            setComments(result)
        })

    }, [])
    // function back() {
    //     window.history.back(-1)
    // }
    return (
        <div className='wrapper1'>
            <div className=''>
            <div className='container1'>
                <div className='page-title'>
                    <img src={backImg} alt='' className='title-left1' onClick={back} />全部动态
                </div>
            </div>
                <div style={{marginLeft: '0.8rem',marginRight: '0.8rem'}} className='comment-box-content container2'>
                    {(comments || []).map(comment => {
                        return (
                            <div className='comment-box-item' key={comment.id}>
                                <div className='comment-item-top'>
                                    <div className='comment-item-top-left'>
                                        <img src={comment.avatar} alt='' className='comment-person' />
                                    </div>
                                    <div className='comment-item-top-right'>
                                        <div className='commnet-item-top-right-name'>{comment.realname}</div>
                                        <div className='comment-item-top-right-time'>{comment.createTime}</div>
                                    </div>
                                </div>
                                {(comment.images || []).map(item => {
                                    return (
                                        <span className='input-item-right' style={{ textAlign: "right" }}>
                                            <img src={resource(item)} alt='' className='upload-com' />
                                        </span>
                                    )
                                })}

                                <div className='comment-item-content'>{comment.comment}</div>
                                <div className='comment-item-btm'>
                                    <div className='video-right-item'>
                                        <span className={comment.create_by === userInfo.username ? "delete-titile" : "hide"} onClick={() => {
                                            deleteComment(comment.id).then(res => {
                                                if (res.success) {
                                                    Toast.success(res.message)
                                                    myComments(userInfo.username).then(res => {
                                                        setComments(res.result)
                                                    })
                                                }

                                            })
                                        }}>删除</span>
                                    </div>

                                    <div className='video-right-item'>
                                        <img src={commentMessage} alt='' className='comment-pic' />
                                        <span className='comment-title'>{comment.reback.length}</span>
                                    </div>

                                    <div className='video-right-item' onClick={() => {
                                        if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                            Toast.fail("未注册/登录/认证！")
                                        } else {
                                            console.log(userInfo,user,'aaaaaaaa')
                                            if (userInfo.username === user.username) {
                                                Toast.fail("不能给自己点赞")
                                            } else {
                                                addLike({
                                                    likeId: comment.id,
                                                    likeType: 2
                                                }).then(res => {
                                                    const d = [];
                                                    comments.forEach(message1 => {
                                                        if (message1.id === comment.id) {
                                                            message1.likeCount = res.result;
                                                        }
                                                        d.push(message1);
                                                    })
                                                    setComments(d);
                                                })
                                            }
                                        }
                                    }}>
                                        <img src={zanIcon} alt='' className='comment-pic' />
                                        <span className='zan-title'>{comment.likeCount}</span>
                                    </div>
                                </div>

                                {(comment.reback || []).map(item => {
                                    return <div key={item.id} className='comment-word'>
                                        {item.realname}：{item.comment}
                                        <span style={{marginLeft:'1rem'}}>
                                            <span className={item.create_by === userInfo.username ? "delete-titile" : "hide"} onClick={() => {
                                                deleteReComment(item.id).then(res => {
                                                    if (res.success) {
                                                        Toast.success(res.message)
                                                        myComments(userInfo.username).then(res => {
                                                            setComments(res.result)
                                                        })
                                                    }

                                                })
                                            }}>删除</span>
                                        </span>
                                    </div>
                                })}
                            </div>

                        )
                    })}
                </div>
            </div>

        </div>
    )

}
