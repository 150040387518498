// import { Component } from "react";
import { Link } from "react-router-dom"
// import { appRoutes } from "../../router";
import React, { useEffect, useState } from "react"
import { config } from './../../utils/config'
// import { Button } from 'antd-mobile-v2';
import top from "./../../assets/top.png";
import { Carousel } from "antd-mobile-v2"
import { PickerView } from "antd-mobile-v2"
import { Picker, List } from "antd-mobile-v2"
import "antd-mobile-v2/dist/antd-mobile.css"
import "./../../css/index.css"
import addressIcon from "./../../assets/address_icon.png"
import searchIcon from "./../../assets/search.png"
import hotCompannyOne from "./../../assets/hot_company_one.png"
import videoPlay from "./../../assets/play_icon.png"
import homeIcon from "./../../assets/home_selected.png"
import mineIcon from "./../../assets/mine_select.png"
import { hotBank } from "../../api/hotbank"
import { Toast } from "antd-mobile-v2"
import { hotVideoList } from "../../api/video"
import { productList, getProduct } from "../../api/product"
import { companyList, userInfoByUserName, managerList } from "../../api/user"
import { resource } from "../../utils/resource"
import { advertisenmentList } from "../../api/advertisenment"
import menuIcon from "./../../assets/menu_address.png"
import videoPic from "../../assets/jhk-1629292297794.png"
import bankImg from "./../../assets/bank.png"
// import provinceCity from './../../assets/provinceCity.json';
import axios from "axios"
import { atom, useRecoilState } from "recoil"
export let locationAtom = atom({
    key: "locationAtom",
    default: { location: "北京市" }
})

export let locationCity = "北京市"
export const provinceCity = [
    {
        label: "北京",
        value: "北京",
        children: [
            {
                label: "北京市",
                value: "北京市"
            }
        ]
    },
    {
        label: "上海",
        value: "上海",
        children: [
            {
                label: "上海市",
                value: "上海市"
            }
        ]
    },
    {
        label: "广东省",
        value: "广东省",
        children: [
            {
                label: "广州市",
                value: "广州市"
            },
            {
                label: "深圳市",
                value: "深圳市"
            }
        ]
    },
    {
        label: "重庆",
        value: "重庆",
        children: [
            {
                label: "重庆市",
                value: "重庆市"
            }
        ]
    },
    {
        label: "四川省",
        value: "四川省",
        children: [
            {
                label: "成都市",
                value: "成都市"
            }
        ]
    }
]

const CustomChildren = (props) => (
    <div onClick={props.onClick}>
        <div className="test" style={{ fontSize: ".875rem", color: "#2a2a2a" }}>
            <div style={{ textAlign: "right", color: "#2a2a2a" }}>{props.extra}</div>
        </div>
    </div>
)
let pageSize = 0
let page = 1
let totalAll = 0 
let productPageListAll = []
// 解决loaction的bug
let loading = false
let loading1 = false
let loading2 = false
let loading3 = false
let init = true
export default function Home({ history }) {
    const [keywords, setKeywords] = useState("")
    const [imgList, setImgList] = useState([""])
    const [visible, setVisible] = useState("hidden")
    const [value, setValue] = useState(null)
    const [pickerValue, setPickerValue] = useState()
    const [videoList, setVideoList] = useState()
    const [products, setProducts] = useState()
    const [total, setTotal] = useState(100)
    const [users, setUsers] = useState()
    const [adList, setAdList] = useState()
    const [bankList, setBankList] = useState()
    const [location, setLocation] = useRecoilState(locationAtom)

    let userInfo = localStorage.getItem("userInfo")
    let username =
        localStorage.getItem("userInfo") === null || localStorage.getItem("userInfo") === undefined
            ? ""
            : localStorage.getItem("userInfo").username
    if (userInfo === null || userInfo === undefined) {
        userInfo = {
            id: "",
            username: "",
            realname: "",
            avatar: "",
            birthday: null,
            sex: null,
            email: null,
            phone: "",
            orgCode: null,
            orgCodeTxt: null,
            status: 1,
            delFlag: 0,
            workNo: null,
            post: null,
            telephone: "11",
            createBy: null,
            createTime: "",
            updateBy: "",
            updateTime: "",
            activitiSync: 0,
            userIdentity: null,
            departIds: null,
            relTenantIds: null,
            clientId: null,
            userWx: null,
            userQrCode: "",
            userLocation: "上海市",
            userStatus: 5,
            userCompany: "",
            identityLevel: "",
            userIdCard: "",
            certificationInformation1: null,
            certificationInformation2: "",
            introduction: "",
            identityStatus: null
        }
    }
    
    const getData = (isAdd) =>{
       
        pageSize = config.pageSize
        // if (pageSize > total) {
        //     pageSize = total;
        // }
        console.log(isAdd,products,'sadasdasdasd')
        let isApi = (totalAll/pageSize)>page
        if(isAdd){
            if (isApi){
                if (isAdd) page++
                loading = true
            productList(pageSize, page,locationCity).then(({ result }) => {
                var temp = productPageListAll
                if (isAdd){
                    temp =  [...productPageListAll,...result.records]
                    console.log(products,'hhhhhhhhhhhhhhhhhhhhhhh')
                    // setProducts([...products,...result.records])
                }else{
                    temp = result.records
                }
                setProducts(temp)


                productPageListAll = temp
                
                console.log(result.records,'r7y567567567')
                totalAll = result.total
                setTotal(result.total)
                loading = false
            })
        }
        }else{
            console.log('asdasda1111111111111')
            loading = true
            productList(pageSize, page,locationCity).then(({ result }) => {
                var temp1 = []
               
                temp1 = result.records
                
                setProducts(temp1)


                productPageListAll = temp1
                
                console.log(result.records,'r7y567567567')
                totalAll = result.total
                setTotal(result.total)
                loading = false
                init = false
            })
        }
    }
        
    const getManager = (location) =>{
        loading3 = true
        managerList(location).then(({ result }) => {
            if (result) setUsers(result.records)
            else setUsers([])
            loading3 = false
        })
    }
    const getComapany  = (location) =>{
        loading1 = true
        companyList(location).then(({ result }) => {
            console.log(result.records,'tyutyuyuyuyu')
            if (result) setBankList(result.records)
            loading1 = false
        })
    }
    const getVideo  = (location) =>{
        loading2 = true
        hotVideoList(location).then(({ result }) => {
            setVideoList(result.records)
            loading2 = false
            console.log(result.records,'t6u8678678678678678')
        })
    }
    const getProducts = (isAdd) => {
        console.log('asdasdasaaaaaaa111111')
        if(!loading){
            console.log(page,isAdd,'aaaaaaa111111')
            
            getData(isAdd)
        }else{
            let timer = null
            timer = setInterval(()=>{
                if(!loading){
                    console.log(page,isAdd,'aaaaaaa1111122222222')
                    clearInterval(timer)
                    timer = null
                    getData(isAdd)
                }
            },1000)
        }
    }
    
    const afterLocation = ()=>{
        page = 1
        totalAll = 0
        let location = localStorage.getItem('position') || ''
        console.log('aaaaaaaaaaa',page)
        getProducts(false)
        
        // companyList,managerList都是
        if (!loading3){
            getManager(location)
        }else{
            let timer3 = null
            timer3 = setInterval(()=>{
                if(!loading){
                    clearInterval(timer3)
                    timer3 = null
                    getManager(location)
                }
            },500)
        }
        if (!loading1){
            getComapany(location)
        }else{
            let timer1 = null
            timer1 = setInterval(()=>{
                if(!loading){
                    
                    clearInterval(timer1)
                    timer1 = null
                    getComapany(location)
                }
            },500)
        }
        if (!loading2){
            getVideo(location)
        }else{
            let timer2 = null
            timer2 = setInterval(()=>{
                if(!loading){
                    
                    clearInterval(timer2)
                    timer2 = null
                    getVideo(location)
                }
            },500)
        }

       
    }

    useEffect(() => {
        page = 1
        loading = false
        loading1 = false
        loading2 = false
        loading3 = false
        init = true
        console.log('init')
        // let location = localStorage.getItem('position') || ''
        // companyList().then((res) => {
            // afterLocation()
        // })
        window.onscroll = function () {
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            //滚动条到底部的条件
            if (scrollTop + windowHeight == scrollHeight) {
                if(!init){
                    getProducts(true)
                }
                
                console.log('scrollTop')
            }
        }
        let timer = setInterval(()=>{
            let locationTemp = localStorage.getItem('position')
            if(locationTemp!==locationCity&&locationTemp!==''){
                locationCity = locationTemp
                Toast.success("定位到"+ locationCity)
                setLocation({ location: locationCity })
                console.log('获得地址后')
            }
        },500)
        setTimeout(()=>{
            try{
                console.log(navigator.geolocation,'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
                let locationTemp = localStorage.getItem('position')
                if (locationTemp) { //H5获取经纬度
                    locationCity = locationTemp
                        Toast.success("定位到"+ locationCity)
                        setLocation({ location: locationCity })
                        console.log('获得地址后')
                }else{
                    axios.get("https://restapi.amap.com/v3/ip?key=002035dce059f4d44951f84462d29274").then((map) => {
                
                        localStorage.setItem('position',map["data"]["city"])
                        locationCity = map["data"]["city"]
                        Toast.success("定位到"+ locationCity)
                        setLocation({ location: map["data"]["city"] })
                        console.log('获得地址后')
                        // afterLocation()
                        // getProducts()
                    })
                }
    
            }catch{
               
            }
        },1000)
        
        
    }, [])

    useEffect(() => {
        console.log('location改变后')
        // let location = localStorage.getItem('position') || ''
       
        // hotVideoList(location).then(({ result }) => {
        //     setVideoList(result.records)
        // })
        // hotBank(location).then(({ result }) => {
        //     console.log(result)
        //     setBankList(result)
        // })
        advertisenmentList().then(({ result }) => {
            setAdList(result.records)
        })

        afterLocation()
    }, [location])

    // 获取搜索框输入的值
    const inputChange = (e) => {
        setKeywords(e.target.value)
    }
    const onChange = (value) => {
        setValue(value)
    }
    // 搜索按钮处理事件
    const searchHandle = () => {
        history.push("/search", keywords)
    }

    const onScrollChange = () => {}
    const onPickerChange = (value) => {}

    return (
        <div className="wrapper">
            <div className="container">
                <div className="title">
                    <div className="title-left">
                        <img src={addressIcon} className={"address"} alt="" />
                        {/* 地区选择 */}
                        <div className="title-left-text">
                            {/* {locationCity} */}
                            <List style={{ width: "100%", border: "none" }}>
                                <Picker
                                    title="选择地区"
                                    extra={locationCity||''}
                                    cols="3"
                                    data={provinceCity}
                                    value={pickerValue}
                                    // onChange={v => { setPickerValue(v) }}
                                    onOk={(v) => {
                                        setLocation({ location: v[1] })
                                        localStorage.setItem('position',v[1])
                                        setPickerValue(v)
                                        locationCity = v[1]
                                    }}
                                    // onPickerChange={v => onPickerChange(v)}
                                >
                                    <CustomChildren>所在位置</CustomChildren>
                                </Picker>
                            </List>
                        </div>
                    </div>
                    <div className="title-middle">首页</div>
                </div>
                <div className="home-search">
                    <div className="home-search-box">
                        <img src={searchIcon} className={"search-icon"} alt="" />
                        <input
                            type="text"
                            defaultValue={keywords}
                            placeholder="搜索"
                            className="home-search-input"
                            onChange={inputChange}
                        />
                        <button className="search-btn" onClick={searchHandle}>
                            搜索
                        </button>
                    </div>
                </div>
                {/* 轮播图 */}
                <div className="home-banner">
                    <Carousel
                        infinite
                        autoplay={true}
                        dotStyle={{ width: "7px", height: "7px", backgroundColor: "#7771FF" }}
                        dotActiveStyle={{ width: "7px", height: "7px", backgroundColor: "#7c94ff" }}
                    >
                        {(adList || []).map((val,index2) => (
                            <a key={index2} href="/#" style={{ display: "inline-block", width: "100%" }}>
                                <img
                                    src={resource(val.advPic)}
                                    alt=""
                                    style={{ width: "100%", verticalAlign: "top" }}
                                    onLoad={() => {
                                        // fire window resize event to change height
                                        window.dispatchEvent(new Event("resize"))
                                    }}
                                />
                            </a>
                        ))}
                    </Carousel>
                </div>
                {/* 热门公司 */}
                <div className="hot-part">
                    <div className="hot-title">
                        <div className="hot-title-left">热门公司</div>
                        <div className="hot-title-right">
                            <Link to="/company" className="hot-a">
                                更多
                            </Link>
                        </div>
                    </div>
                    <div className="hot-content">
                        <div className="hot-content-scroll">
                            {(bankList || []).map((bank,index3) => {
                                return (
                                    <div className="com-li" key={index3}>
                                        <div
                                            className="hot-item"
                                            onClick={() => {
                                                history.push("/bank", bank)
                                            }}
                                        >
                                            <div className="hot-item-pic">
                                                <img
                                                    src={bank.avatar}
                                                    alt=""
                                                    className="hot-img"
                                                    style={{ borderRadius: "25%" }}
                                                />
                                            </div>
                                            <div className="hot-item-name">{bank.userCompany}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {/* 热门视频 */}
                <div className="hot-part">
                    <div className="hot-title">
                        <div className="hot-title-left">热门视频</div>
                        <div className="hot-title-right">
                            <Link to="/video" className="hot-a">
                                更多
                            </Link>
                        </div>
                    </div>
                    <div className="hot-content">
                        <div className="hot-content-scroll">
                            {(videoList || []).map((video,index4) => {
                                return (
                                    <div className="video-item" key={index4}>
                                        <div className="hot-video" onClick={() => history.push("/videoDetail", video)}>
                                            <div style={{ height: "120px", overflow: "hidden" }}>
                                                {/* <video poster={video.cover} className='video-img1' src={resource(video.videoUrl)} onClick={() => history.push("videoDetail", video)} /> */}
                                                <img
                                                    src={video.cover}
                                                    alt=""
                                                    className="video-img1"
                                                    onClick={() => history.push("/videoDetail", video)}
                                                ></img>
                                            </div>
                                        </div>
                                        <div className="video-btm">{video.name}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {/* 热门经理 */}
                <div className="hot-part">
                    <div className="hot-title">
                        <div className="hot-title-left">热门经理</div>
                        <div className="hot-title-right">
                            <Link to="/manager" className="hot-a">
                                更多
                            </Link>
                        </div>
                    </div>
                    <div className="hot-content">
                        <div className="hot-content-scroll">
                            {(users || []).map((user,index) => {
                                return (
                                    <div
                                        className="manager-li"
                                        key={index}
                                        onClick={() => {
                                            history.push("/managerDetail", user)
                                        }}
                                    >
                                        <div className="manager-avatar">
                                            <img
                                                src={resource(user.avatar)}
                                                alt=""
                                                className="avatar-pic"
                                                style={{ borderRadius: "25%" }}
                                            />
                                        </div>
                                        <div className="manager-name">{user.realname}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="sort-section">
                    <div className="sort-tab">
                        <div
                            className="tab-item"
                            onClick={() => {
                                history.push("/companySort")
                            }}
                        >
                            公司筛选
                        </div>
                        <div className="tab-item">
                            <Link to={"/sort"}>条件筛选</Link>
                        </div>
                    </div>
                    {/* 公司列表 */}
                    <div className="sort-content">
                        {(products || []).map((product,index1) => {
                            return (
                                <div
                                    className="sort-item"
                                    onClick={() => {
                                        getProduct(product.id).then(({ result }) => {
                                            history.push("/BankDetail", { product: result })
                                        })
                                    }}
                                    key={index1}
                                >
                                    <div className="sort-item-top">
                                        <div className="top-left">
                                            <img src={product.proAvator||bankImg} alt="" className="bank-logo" />
                                        </div>
                                        <div className="top-right">
                                            <div className="top-right-title">
                                                <div className="title-name">{product.proName}</div>
                                            </div>
                                            <div className="top-right-address"></div>
                                        </div>
                                    </div>
                                    <div className="sort-item-middle">
                                        <div className="middle-item">
                                            <div className="middle-item-top">{product.proAmount}</div>
                                            <div className="middle-item-btm">贷款额度(元)</div>
                                        </div>
                                        <div className="middle-item">
                                            <div className="middle-item-top">{!product.proRate?(product.proRateMin + '~'+ product.proRateMax + '%'):product.proRate}</div>
                                            <div className="middle-item-btm">参考利率</div>
                                        </div>
                                        <div className="middle-item">
                                            <div className="middle-item-top">{!product.proCicle?(product.proCicleMin + '~'+ product.proCicleMax + '月'):product.proCicle}</div>
                                            <div className="middle-item-btm">贷款期数</div>
                                        </div>
                                    </div>
                                    <div className="sort-item-btm">
                                        <div className="btm-top">
                                            <div className="btm-item">
                                                {product.proLabel === "1"
                                                    ? "车贷"
                                                    : product.proLabel === "2"
                                                    ? "房贷"
                                                    : product.proLabel === "3"?"信用贷" :product.proLabel}
                                            </div>
                                        </div>
                                        <div className="btm-btn">展开</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* 上传 */}
            <div className="toTop" onClick={() => {
                        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
                        if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                            Toast.fail("未注册/登录/认证！");
                        } else {
                            history.push("/upload");
                        }
                    }} style={{ bottom:'10rem' }}>
                <img src={top} alt="" className="top-pic" />
                <div
                    className="top-title"
                    
                >
                    上传
                </div>
            </div>
                <div className="tarbar">
                    <div className="tarbar-home" onClick={() => history.push("/index")}>
                        <img src={homeIcon} alt="" className="home-icon" />
                        <div className="home-named">
                            <Link to="/">首页</Link>
                        </div>
                    </div>
                    <div className="tarbar-middle" onClick={() => history.push("/message")}>
                        <img src={menuIcon} alt=""  className="menu-icon" />
                    </div>
                    <div className="tarbar-home" onClick={() => history.push("/person")}>
                        <img src={mineIcon} alt="" className="home-icon" />
                        <div className="home-name">
                            <Link to="/person">我的</Link>
                        </div>
                    </div>
                </div>
                <div className="popup" style={{ visibility: visible, zIndex: "9999999999" }}>
                    <div className="popup-div" />
                    <div className="popop-address">
                        <PickerView
                            onChange={onChange}
                            onScrollChange={onScrollChange}
                            data={provinceCity}
                            value={["02", "02-1", "02-1-1"]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export function getVideoPoster(url) {
    let video = document.createElement("video")
    video.width = 200
    video.height = 80
    video.src = url
    let canvas = document.createElement("canvas")
    canvas.width = 200
    canvas.height = 80
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height)
    // let img = document.createElement("img");
    // img.src =
    return canvas.toDataURL("image/png")
    //   width: 200px;
    // height: 80px;
}
