import { Switch, Route, Redirect } from "react-router-dom"
import { Component } from "react"
import { appRoutes } from "./router"
import { Toast } from "antd-mobile-v2"

class App extends Component {
    render() {
        window.onerror = function (errorMessage, scriptURI, lineNo, columnNo, error) {
            console.log("errorMessage: " + errorMessage) // 异常信息
            console.log("scriptURI: " + scriptURI) // 异常文件路径
            console.log("lineNo: " + lineNo) // 异常行号
            console.log("columnNo: " + columnNo) // 异常列号
            console.log("error: " + error) // 异常堆栈信息
        }
        return (
            <Switch>
                {appRoutes.map((route) => (
                    <Route exact key={route.path} path={route.path} component={route.component}></Route>
                ))}
                <Redirect from="/" to="/index" />
            </Switch>
        )
    }
}

export default App
