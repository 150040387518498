import React, { useState } from "react"
import "./../../css/login.css"
import mobileIcon from "./../../assets/mobile_icon.png"
import passwordIcon from "./../../assets/password_icon.png"
import codeIcon from "./../../assets/code_icon.png"
import logo from "./../../assets/logo.png"
import { Link } from "react-router-dom"
import { sendSms, register } from "../../api/user"
import { Toast } from "antd-mobile-v2"
import { useCountDown } from "../../hooks/useCountDown"

export default function Login({ history }) {
    const [registerUser, setRegisterUser] = useState({
        phone: "",
        password: "",
        confirmPassword: "",
        smscode: ""
    })
    // 验证码倒计时
    const { count, start, timeText } = useCountDown()
    // 手机号格式正则
    const regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/

    return (
        <div className="wrapper forget">
            <div className="container">
                <div className="manager-con">
                    <div className="input-box login-box">
                        <img src={logo} alt="" className="logo" />
                        <div className="forget-item">
                            <div className="forget-item-left">
                                <img src={mobileIcon} alt="" className="forget-icon" />
                            </div>
                            <div className="forget-item-right">
                                <input
                                    type="number"
                                    value={registerUser.phone}
                                    onChange={({ target }) => {
                                        setRegisterUser({
                                            ...registerUser,
                                            phone: target.value
                                        })
                                    }}
                                    placeholder="请输入手机号"
                                    className="forget-input"
                                />
                            </div>
                        </div>
                        <div className="forget-item">
                            <div className="forget-item-left">
                                <img src={passwordIcon} alt="" className="forget-icon" />
                            </div>
                            <div className="forget-item-right">
                                <input
                                    type="password"
                                    value={registerUser.password}
                                    onChange={({ target }) => {
                                        setRegisterUser({
                                            ...registerUser,
                                            password: target.value
                                        })
                                    }}
                                    placeholder="请输入密码"
                                    className="forget-input"
                                />
                            </div>
                        </div>
                        <div className="forget-item">
                            <div className="forget-item-left">
                                <img src={passwordIcon} alt="" className="forget-icon" />
                            </div>
                            <div className="forget-item-right">
                                <input
                                    type="password"
                                    value={registerUser.confirmPassword}
                                    onChange={({ target }) => {
                                        setRegisterUser({
                                            ...registerUser,
                                            confirmPassword: target.value
                                        })
                                    }}
                                    placeholder="请再次输入密码"
                                    className="forget-input"
                                />
                            </div>
                        </div>
                        {/* 验证码 */}
                        <div className="forget-item">
                            <div className="forget-item-left">
                                <img src={codeIcon} alt="" className="forget-icon" />
                            </div>
                            <div className="forget-item-right">
                                <input
                                    type="number"
                                    value={registerUser.smscode}
                                    onChange={({ target }) => {
                                        setRegisterUser({
                                            ...registerUser,
                                            smscode: target.value
                                        })
                                    }}
                                    placeholder="请输入验证码"
                                    className="forget-input"
                                />
                            </div>
                            {/* 获取验证码 */}
                            <div
                                className="forget-item-count"
                                onClick={() => {
                                    if (count === 0) {
                                        console.log(registerUser.phone,'ertertertert')
                                        sendSms(registerUser.phone).then(({ result }) => {
                                            console.log(result)
                                            // setRegisterUser({
                                            //     ...registerUser
                                            //     // smscode: result
                                            // })
                                        })
                                        // 开启倒计时
                                        start()
                                    }
                                }}
                            >
                                {timeText}
                            </div>
                        </div>
                        <div className="next-btn">
                            <button
                                className="next-submit"
                                onClick={() => {
                                    if (!registerUser.phone) return Toast.fail("请输入手机号")
                                    if (!regs.test(registerUser.phone)) return Toast.fail("手机号输入不合法")
                                    if (registerUser.password === registerUser.confirmPassword) {
                                        register(registerUser).then((resp) => {
                                            if (resp.success) {
                                                history.push("/login")
                                            } else {
                                                Toast.fail(resp.message)
                                            }
                                        })
                                    } else {
                                        Toast.fail("密码和确定密码不一致")
                                    }
                                }}
                            >
                                注册
                            </button>
                        </div>

                        <div className="logo-desc">
                            已有账号,前往
                            <Link to="/login" className="logo-link">
                                登录
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
