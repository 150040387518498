import request from '../utils/request'


export function commentList(objId, objType,pageNo='',pageSize='') {
    return request({
        url: "likeandcomment/dtComments/list",
        method: "GET",
        params: {
            objId,
            objType,
            pageNo,
            pageSize
        }
    })
}

export function commentAdd(data) {
    return request({
        url: "likeandcomment/dtComments/add",
        method: "post",
        data
    })
}

export function reComments(data) {
    return request({
        url: "likeandcomment/dtReComments/add",
        method: "post",
        data
    })
}
export function deleteComment(id) {
    return request({
        url: "likeandcomment/dtComments/delete?id="+id,
        method: "delete"

    })
}

export function deleteReComment(id) {
    return request({
        url: "likeandcomment/dtReComments/delete?id="+id,
        method: "delete"

    })
}
