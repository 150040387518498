/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect, useRef } from "react"

// 发送验证码倒计时
export const useCountDown = () => {
    var timeref = useRef(null)
    const [count, setCount] = useState(0)
    const [flag, setFlag] = useState(true) //处理节流
    const [timeText, setText] = useState("获取验证码")
    useEffect(() => {
        return () => {
            clearInterval(timeref.current)
        } //清除
    }, [])
    useEffect(() => {
        if (count === 0) {
            setFlag(true) //当定时器结束的时候开启阀门
            clearInterval(timeref.current)
            setText("获取验证码")
        } else {
            setFlag(false)
            setText(count + "s后重新获取")
        }
    }, [count])
    let start = (num = 60) => {
        console.log('60000000000')
        if (flag) {
            setCount(60)
            // useCountDown((count) => count || num)
            timeref.current = setInterval(() => {
                setCount((count) => count - 1)
            }, 1000)
        }
    }

    return { start, count, timeText }
}
