import React, { useState } from 'react';
import './../../css/logon.css';
import mobileIcon from './../../assets/mobile_icon.png';
import passwordIcon from './../../assets/password_icon.png';
import logo from './../../assets/logo.png';
import { Link } from "react-router-dom";
import { login } from '../../api/user'
import {
    Toast
} from 'antd-mobile-v2'
import backImg from "../../assets/left.png";

export default function Login({ history }) {
    const [loginUser, setLoginUser] = useState({
        username: "",
        password: ""
    })

    return (
        <div className='wrapper forget'>
            <div className=''>
                <div className='page-title' style={{ boxShadow:'none'}}>
                    <img src={backImg} alt='' className='title-left1' onClick={() => {
                        window.history.go(-2);
                    }} />
                </div>
                <div className='manager-con container'>
                    <div className='input-box login-box'>
                        <img src={logo} alt='' className='logo' />
                        <div className='forget-item'>
                            <div className='forget-item-left'>
                                <img src={mobileIcon} alt='' className='forget-icon' />
                            </div>
                            <div className='forget-item-right'>
                                <input type='number' value={loginUser.username} onChange={({ target }) => { setLoginUser({ ...loginUser, username: target.value }) }} placeholder='请输入手机号' className='forget-input' />
                            </div>
                        </div>
                        <div className='forget-item'>
                            <div className='forget-item-left'>
                                <img src={passwordIcon} alt='' className='forget-icon' />
                            </div>
                            <div className='forget-item-right'>
                                <input type='password' value={loginUser.password} onChange={({ target }) => { setLoginUser({ ...loginUser, password: target.value }) }} placeholder='请输入密码' className='forget-input' />
                            </div>
                        </div>
                        <div className='logo-desc logon-desc'>还没有账号,前往<Link to='/register' className='logo-link'>注册</Link>或
                            <Link to='/index' className='logo-link' onClick={() => {
                                localStorage.removeItem("userInfo")
                                localStorage.removeItem("token")
                            }}>游客登陆</Link></div>

                        <div className='next-btn'>
                            <button className='next-submit' onClick={() => {
                                login(loginUser).then(({ success, message, result }) => {
                                    if (success) {
                                        const { userInfo, token } = result
                                        localStorage.setItem("userInfo", JSON.stringify(userInfo))
                                        localStorage.setItem("token", token)
                                        history.push("/index")
                                    }
                                    else {
                                        Toast.fail(message)
                                    }

                                })
                            }}>登录</button>
                        </div>
                        <div className='logo-desc'><Link to='/forget' className='logon-link'>忘记密码</Link></div>
                    </div>

                </div>
            </div>
        </div>
    )
}
