import React, { useEffect, useState } from 'react';
import './../../css/company.css';
import companyPic from './../../assets/hot-company.png';
import addressIco from './../../assets/address.png';
import viewsIco from './../../assets/views.png';
import zanIco from './../../assets/zan_icon.png';
import recIco from './../../assets/rec.png';
import backImg from "../../assets/left.png";
import {
    dtBooking,
    myproducts,
    myvideos,
    companyList,
    userInfoByUserName,
    deleteDtBooking,
    editDtBooking
} from "../../api/user";
import bankPic from "../../assets/bank_logo.png";
import clockPic from "../../assets/date_icon.png";
import kehu from "../../assets/kehu.png";
import { getProduct } from "../../api/product";
import { resource } from "../../utils/resource";
import { Toast } from "antd-mobile-v2";
import popupClose from "../../assets/popup-close.png";
import { config } from './../../utils/config'

export default function Search({ history }) {
    const [booking, setBooking] = useState([])
    const [popup, setPopup] = useState({});
    let pageNo = 0
    let total = 0
    let loading = false
    let init = true
    let orignialArr = []

    const closePopup2 = () => {
        setVisible2('hidden')
    }
    const [visible2, setVisible2] = useState('hidden');
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo === null || userInfo === undefined) {
        userInfo = {
            "id": "",
            "username": "",
            "realname": "",
            "avatar": "",
            "birthday": null,
            "sex": null,
            "email": null,
            "phone": "",
            "orgCode": null,
            "orgCodeTxt": null,
            "status": 1,
            "delFlag": 0,
            "workNo": null,
            "post": null,
            "telephone": "11",
            "createBy": null,
            "createTime": "",
            "updateBy": "",
            "updateTime": "",
            "activitiSync": 0,
            "userIdentity": null,
            "departIds": null,
            "relTenantIds": null,
            "clientId": null,
            "userWx": null,
            "userQrCode": "",
            "userLocation": "上海市",
            "userStatus": 5,
            "userCompany": "",
            "identityLevel": "",
            "userIdCard": "",
            "certificationInformation1": null,
            "certificationInformation2": "",
            "introduction": "",
            "identityStatus": null
        }
    } else {
    }
    const openPopup2 = () => {
        if (userInfo.id === '' || userInfo.id === undefined) {
            Toast.fail("请登录")

            history.push("/login")
        }
        setVisible2('visible')
    }
    useEffect(() => {

        init = true
        pageNo = 0
        orignialArr = []
        getData()
        window.onscroll = function () {
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            //滚动条到底部的条件
            if (scrollTop + windowHeight == scrollHeight) {
                if(!init){
                    getData()
                }
                
                console.log('scrollTop')
            }
        }
    }, [])
    function back() {
        window.history.back(-1)
    }
    const getData = ()=>{
        if (!loading){
            let isAdd = (pageNo < (total/config.pageSize)) || (pageNo==0)
            console.log(pageNo,'22222assss')
            if (isAdd){
                pageNo++
                loading = true
                dtBooking(config.pageSize,pageNo).then(res => {
                    orignialArr = [...orignialArr,...res.result.records]
                    setBooking(orignialArr)
                    total = res.result.total
                    
                    loading = false
                    init = false
                })
            }
        }
        
    }
    return (
        // <div className='wrapper'>
        //     <div className='container'>
        //         <div className='page-title'>
        //             <img src={backImg} alt='' className='title-left1' onClick={back} />
        //             我的客户预约记录
        //         </div>
        //     </div>
        <div className="wrapper-video" style={{ paddingBottom: "0", height: "100%" }}>
            <div style={{ paddingTop: "1.125rem", height: "100%" }}>
                {/* 顶部标题 */}
                <div className="page-title" style={{ backgroundColor: "#E1E0FF" }}>
                    <img src={backImg} alt="" className="title-left1" onClick={back} />
                    <div>我的客户预约记录</div>
                </div>
                </div>

            <div className='yu-content container2' style={{ height: "100%", marginTop: "2.5rem" }}>
                {booking.map(item => {
                    return (
                        <div className='yu-item'>
                            <div className='yu-item-top'>
                                <div className='yu-top-flex'>
                                    <div className='yu-top-logo'>
                                        <img src={item.managerAvatar||item.productAvatar||bankPic} alt='' className='bank-logo' />
                                    </div>
                                    <div className='yu-top-middle'>
                                        <div style={{marginLeft:'0'}} className='yu-top-title'>{item.productName||item.managerUsername}</div>
                                        <div className='yu-top-desc'>预约进度：<span className={item.isRead === 1 ? 'desc-span1' : 'desc-span'}>{item.isRead === 1 ? '已查看' : '尚未被查看'}</span></div>
                                    </div>
                                    <div className='yu-top-right'>
                                        <img src={clockPic} alt='' className='clock-icon' /><span className='clock-span'>{item.createTime}</span>
                                    </div>
                                </div>
                                <div className='yu-top-desc'>
                                    <div className='yu-desc-tip'>预约客户:</div>
                                    <div className='yu-desc-avatar'>
                                        <img src={item.userAvatar||kehu} alt='' className='keHu' />
                                        <div className='kehu-word'>{item.username}</div>
                                    </div>
                                    {/*<div className='yu-desc-avatar'>*/}
                                    {/*    <img src={kehu} alt='' className='keHu' />*/}
                                    {/*    <div className='kehu-word'>陈默含</div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className='yu-item-btm'>
                                <div className='yu-btm-cancel'>
                                    <button className='cancel-btn' onClick={() => {
                                        deleteDtBooking(item.id).then(res => {
                                            window.location.reload()
                                        });
                                    }}>删除</button>
                                </div>
                                <div className='yu-btm-check'>

                                    <button className='check-btn' onClick={() => {
                                        openPopup2()
                                        setPopup({
                                            phone: item.phone,
                                            userQrCode: item.userQrCode
                                        })
                                        if (item.isRead !== 1 && userInfo.username !== item.username) {
                                            editDtBooking({ "isRead": 1, "id": item.id }).then(res => {

                                            })
                                        }
                                    }}>查看</button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='popup' style={{ visibility: visible2 }}>
                <div className='popop-box'>
                    <div className='popup-close'>
                        <img src={popupClose} alt='' className='popup-close-icon' onClick={closePopup2} />
                    </div>
                    <div className='popup-con'>
                        <span className="delete-titile" >手机号：</span><div />{popup.phone === null || popup.phone === '' ? "暂未上传" : popup.phone}
                        <div />
                        <span className="delete-titile" >微信号：</span>{popup.userQrCode === null || popup.userQrCode === '' ? "暂未上传" : <img src={popup.userQrCode} />}

                    </div>
                    <div className='popup-btn' onClick={closePopup2}>确定</div>
                </div>
            </div>

        </div>
    )

}