import React, { useState } from 'react';
import './../../css/member.css';
import vIcon from './../../assets/v.png';
import xianIcon from './../../assets/xian.png';
import vipOne from './../../assets/vip2.png';
import vipTwo from './../../assets/vip3.png';
import vipThree from './../../assets/vip4.png';
import vipFour from './../../assets/vip1.png';
import vipFive from './../../assets/vip6.png';
import tuiIcon from './../../assets/tui.png';

export default function Message() {
    const [vipList, setVipList] = useState(
        [
            { 'id': 1, 'name': '3天会员', 'price': 9.99, 'line': 15, 'reccoment': true, 'sales': 0 },
        { 'id': 2, 'name': '月度会员', 'price': 199, 'line': 240, 'reccoment': false, 'sales': 0 },
        { 'id': 3, 'name': '季度会员', 'price': 488, 'line': 500, 'reccoment': false, 'sales': 12 }
        ]
    );
    const [currentIndex, setCurrentIndex] = useState(1);

    const typeSelect = (id) => {
        setCurrentIndex(id)
    }

    return (
        <div className='wrapper'>
            <div className='container'>
                <div className='page-title'>会员</div>
                <div className='manager-con'>
                    <div className='member-box'>
                        <div className='member-top'>
                            <div className='member-top-flex'>
                                <div className='member-top-left'>
                                    <img src={vIcon} alt='' className='v-icon' />
                                </div>
                                <div className='member-top-right'>
                                    <div className='memeber-top-right-title'>认证用户专属会员</div>
                                    <div className='member-top-right-desc'>此会员仅限认证客户购买</div>
                                </div>
                            </div>
                            <div className='member-top-btm'>
                                <div className='member-btn'>
                                    <div className='member-free'>免费使用</div>
                                    <img src={xianIcon} alt='' className='xian-icon' />
                                </div>
                                <div className='member-price'>￥68/月起</div>
                            </div>
                        </div>
                        <div className='member-one'></div>
                        <div className='memeber-two'></div>
                    </div>
                    <div className='member-item'>
                        <div className='member-item-title'>会员套餐尊享</div>
                        <div className='member-item-con'>
                            <div className='member-con-box member-opacity-one'>
                                <div className='member-con-box-title'>VIP+认证</div>
                                <div className='member-con-box-li'>
                                    <div className='member-line'>
                                        <img src={vipOne} alt='' className='vip-li-icon' />
                                        <span className='vip-li-name'>上传视频</span>
                                    </div>
                                    <div className='member-line'>
                                        <img src={vipTwo} alt='' className='vip-li-icon' />
                                        <span className='vip-li-name'>热门视频</span>
                                    </div>
                                    <div className='member-line'>
                                        <img src={vipThree} alt='' className='vip-li-icon' />
                                        <span className='vip-li-name'>上传产品</span>
                                    </div>
                                    <div className='member-line'>
                                        <img src={vipFour} alt='' className='vip-li-icon' />
                                        <span className='vip-li-name'>本地资讯（无数限制）</span>
                                    </div>
                                    <div className='member-line'>
                                        <img src={vipFive} alt='' className='vip-li-icon' />
                                        <span className='vip-li-name'>参加热门客户经理排行</span>
                                    </div>
                                </div>
                            </div>
                            <div className='member-con-box member-opacity'>
                                <div className='member-con-box-title'>仅认证</div>
                                <div className='member-con-box-li'>
                                    <div className='member-line'>
                                        <img src={vipOne} alt='' className='vip-li-icon' />
                                        <span className='vip-li-name'>上传视频</span>
                                    </div>
                                    <div className='member-line'>
                                        <img src={vipTwo} alt='' className='vip-li-icon' />
                                        <span className='vip-li-name'>热门视频</span>
                                    </div>
                                    <div className='member-line'>
                                        <img src={vipThree} alt='' className='vip-li-icon' />
                                        <span className='vip-li-name'>上传产品</span>
                                    </div>
                                    <div className='member-line'>
                                        <img src={vipFour} alt='' className='vip-li-icon' />
                                        <span className='vip-li-name'>本地资讯（无数限制）</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='member-item'>
                        <div className='member-item-title'>会员套餐推荐</div>
                        <div className='member-item-tao'>
                            {
                                vipList.map((item, index) =>
                                    <div key={index} className={currentIndex === item.id ? 'member-flex-li member-active' : 'member-flex-li'} onClick={() => {
                                        typeSelect(item.id)
                                    }}>
                                        <div className='member-flex-li-title'>{item.name}</div>
                                        <div className='member-flex-li-price'><span className='member-price-iocn'>￥</span>{item.price}</div>
                                        <div className='member-flex-li-through'>￥{item.line}</div>
                                        <div className='member-sales'>
                                            <span className={item.sales > 0 ? 'sales-span sales-show' : 'sales-span sales-hidden'}>立省{item.sales}元</span>
                                        </div>
                                        <img src={tuiIcon} className={item.reccoment ? 'tui-icon tuan-show' : 'tui-icon tuan-hidden'} alt='' />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className='member-btm'>立即开通</div>
                </div>
            </div>
        </div>
    )

}