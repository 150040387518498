// VideoItem.js
import React, { useRef, useEffect } from 'react';
import { resource } from "../utils/resource";

export default function VideoItem(props){
    const target = useRef(null);
    let { src, cover, curPlaySrc } = props

    useEffect(() => {
      console.log(curPlaySrc,'ffffffff')
        // 当当当前应该播放的视频url等于当前视频组件的src时，播放视频
        if(curPlaySrc === src) {
          target.current && target.current.play();
        }else{
          target.current && target.current.pause();
        }
    }, [curPlaySrc, src])
    // return <div data-src={src}>
    //     <div ref={videoRef}></div>
    // </div>
    return <video
      ref={target}
      playsInline={true}
      preload="false"
      poster={cover}
      controls
      src={resource(src)}
      className="video-img2 video-item-unique"
  />
}