import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './../../css/bankDetail.css';
import renIcon from './../../assets/ren.png';
import kehu from './../../assets/kehu.png';
import videoPlay from './../../assets/play_icon.png';
import commentIco from './../../assets/comment_icon.png';
import zanIcon from './../../assets/zan_icon.png';
import pointIco from './../../assets/point.png';
import collected from './../../assets/collected.png';
import commentAvatar from './../../assets/comment_avatar.png';
import commentMessage from './../../assets/comment_message.png';
import commentWrite from './../../assets/comment_write.png';
import down from './../../assets/down.png';
import videoPic from "../../assets/jhk-1629292297794.png";

import {
    commentList,
    commentAdd,
    reComments, deleteComment, deleteReComment
} from '../../api/comment'
import {
    booking, productListByCondition
} from '../../api/product'
import {
    Toast
} from 'antd-mobile-v2'
import './index.css'
import backImg from "../../assets/left.png";
import { resource } from "../../utils/resource";
import { addLike, userInfoByUserName } from "../../api/user";
export default function BankDetail({ history }) {
    const { product } = history.location.state
    console.log(product);
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const [comments, setComments] = useState()
    const [tabs, setTabs] = useState(
        [
            { id: 1, tabName: '产品简介' },
            { id: 2, tabName: '产品详情' }
        ]
    );
    const [currentIndex, setCurrentIndex] = useState(1);
    const [videos, setVideos] = useState([]);
    const [keywords, setKeywords] = useState('');

    const tabChoiced = (id) => {
        setCurrentIndex(id)
    }

    const [company, setCompany] = useState({});
    const [reKeywords, setReKeywords] = useState('');

    var isBox1Show = currentIndex === 1 ? 'block' : 'none';
    var isbox2Show = currentIndex === 2 ? 'block' : 'none';
    var tabList = tabs.map(function (res, index) {
        // 遍历标签页，如果标签的id等于tabid，那么该标签就加多一个active的className
        var tabStyle = res.id === currentIndex ? 'subCtrl active' : 'subCtrl';
        return <li key={index} onClick={() => {
            tabChoiced(res.id)
        }} className={tabStyle}>{res.tabName}</li>
    });
    function back() {
        window.history.back(-1)
    }

    useEffect(() => {
        userInfoByUserName(product.createBy).then(res => {
            const result = res.result.records[0];
            const videos = result.videos;
            setCompany(result);
            setVideos(videos);
            commentList(product.id, "product").then(res => {
                setComments(res.result.records)
            })
        });
        //页面滚动到顶部
        document.body.scrollIntoView();
    }, [])
    if (userInfo === null || userInfo === undefined) {
        userInfo = {
            "id": "",
            "username": "",
            "realname": "",
            "avatar": "",
            "birthday": null,
            "sex": null,
            "email": null,
            "phone": "",
            "orgCode": null,
            "orgCodeTxt": null,
            "status": 1,
            "delFlag": 0,
            "workNo": null,
            "post": null,
            "telephone": "11",
            "createBy": null,
            "createTime": "",
            "updateBy": "",
            "updateTime": "",
            "activitiSync": 0,
            "userIdentity": null,
            "departIds": null,
            "relTenantIds": null,
            "clientId": null,
            "userWx": null,
            "userQrCode": "",
            "userLocation": "上海市",
            "userStatus": 5,
            "userCompany": "",
            "identityLevel": "",
            "userIdCard": "",
            "certificationInformation1": null,
            "certificationInformation2": "",
            "introduction": "",
            "identityStatus": null
        }
    }
    return (
        <div className='wrapper'>
            <div className='mine-top'>
                <div className='mine-top-title' style={{ color: '#fff' }}>
                    <img src={backImg} alt='' className='title-left1' onClick={back} />详情
                </div>
                <div className='manager-item' style={{ margin: '0 10px', backgroundColor: 'rgba(255,255,255,1)' }}>
                    <div className='manager-item-top'>
                        <div className='item-top-left'>
                            <img src={`${product.proAvator}`} alt='' className='manager-avatar-pic' />
                        </div>
                        <div className='item-top-right'>
                            <div className='item-top-ren'>
                                <div className='item-top-ren-title'>{product.proName}</div>

                            </div>
                            <div className='sort-item-middle' style={{ justifyContent: 'space-between', padding: '0' }}>
                                <div className='middle-item'>
                                    <div className='middle-item-top top-red' style={{ fontSize: '10px', lineHeight: '20px' }}>{product.proAmount}</div>
                                    <div className='middle-item-btm' style={{ fontSize: '10px', lineHeight: '15px' }}>贷款金额</div>
                                </div>
                                <div className='middle-item'>
                                    <div className='middle-item-top' style={{ fontSize: '10px', lineHeight: '20px' }}>{!product.proRate?(product.proRateMin + '~'+ product.proRateMax + '%'):product.proRate}</div>
                                    <div className='middle-item-btm' style={{ fontSize: '10px', lineHeight: '15px' }}>参考利率</div>
                                </div>
                                <div className='middle-item'>
                                    <div className='middle-item-top' style={{ fontSize: '10px', lineHeight: '20px' }}>{!product.proCicle?(product.proCicleMin + '~'+ product.proCicleMax + '月'):product.proCicle}</div>
                                    <div className='middle-item-btm' style={{ fontSize: '10px', lineHeight: '15px' }}>贷款周期</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sort-item-btm'>
                        <div className='btm-top'>
                            <div className='btm-item'>{product.proLabel === "1" ? "车贷" : product.proLabel === "2" ? "房贷" : "信用贷"}</div>
                            {/* <div className='btm-item'>公积金</div>
                            <div className='btm-item'>按揭房</div>
                            <div className='btm-item'>公积金</div>
                            <div className='btm-item'>公积金</div> */}
                        </div>
                    </div>
                    {/*<div className='detail-btm'>*/}
                    {/*    <img src={collected} alt='' className='cancelCollect' /><span className='collect-name'>取消收藏</span>*/}
                    {/*</div>*/}
                </div>
                <div className='bank-detail-btn'>
                    <div className='bank-detail-btn-free' onClick={() => {
                        booking({ productId: product.id }).then(({ message }) => {
                            Toast.success("预约成功")
                        })
                    }}>免费预约</div>
                    <div className='bank-detail-btn-online' onClick={() => {
                        booking({ productId: product.id }).then(({ message }) => {
                            Toast.success("预约成功")
                        })
                    }}>线上申请</div>
                </div>
            </div>
            <div className='mine-btm'>
                <div className='hot-part'>
                    <div className='hot-titled'>
                        <div className="listWrap">
                            <ul className="subNavWrap">
                                {tabList}
                            </ul>
                            <div className="newsList">
                                <div style={{ "display": isBox1Show }} >
                                    <div className="product-desc" dangerouslySetInnerHTML={{ "__html": product.proDesc }} />
                                </div>
                                <div style={{ "display": isbox2Show }}>
                                    <div className='product-desc' dangerouslySetInnerHTML={{ "__html": product.proDetail }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='hot-content'>
                    <div className='hot-title'>
                        <div className='hot-title-left'>相关视频</div>
                        <div className='hot-title-right'><Link to='/video' className='hot-a'>更多</Link></div>
                    </div>
                    {videos.length > 0 ? '' : "暂无内容"}
                    <div className="hot-content-scroll">
                        {
                            videos.map(item => {
                                var count = 0
                                return (

                                    <div className='mine-video-item deS' style={{ width: '15rem' }} >
                                        <div className='video-item-top'>
                                            <video className='video-img1' src={resource(item.videoUrl)} onClick={() => history.push("videoDetail", item)} />

                                        </div>
                                        <div className='video-item-middle'>{item.remark}</div>
                                        <div className='video-item-btm'>
                                            <div className='video-item-btm-left'>
                                                <img src={kehu} alt='' className='video-avatar' /><span className='video-name'>{company.realname}</span>
                                            </div>
                                            <div className='video-item-btm-right' onClick={() => {
                                                if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                                    Toast.fail("未注册/登录/认证！")
                                                } else {
                                                    addLike({
                                                        likeId: item.id,
                                                        likeType: 1
                                                    }).then(res => {

                                                        const d = [];
                                                        videos.forEach(video => {
                                                            if (video.id === item.id) {
                                                                video.likeCount = res.result;
                                                            }
                                                            d.push(video);
                                                        })
                                                        setVideos(d);
                                                    })
                                                }
                                            }}>
                                                <div className='video-right-item'>
                                                    <img src={commentIco} alt='' className='comment-pic' />
                                                    <span className='comment-title'>{item.commentCount||0}</span>
                                                </div>
                                                <div className='video-right-item'>
                                                    <img src={zanIcon} alt='' className='comment-pic' />
                                                    <span className='zan-title'>{item.likeCount}</span>
                                                </div>
                                                <div className='video-right-item'>
                                                    <img src={pointIco} alt='' className='comment-pic' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='comment-box'>
                    <div className='comment-box-title'>全部评论</div>
                    <div className='comment-box-content'>
                        <div className='comment-box-top'>
                            <div className='comment-box-top-left'>
                                <img src={commentAvatar} alt='' className='comment-avatar-pic' />
                            </div>
                            <div className='comment-box-top-right'>
                                <img src={commentWrite} alt='' className='comment-write' />
                                <input type='text' placeholder='别吝啬，快来说几句啦…' className='comment-input' onChange={(e) => {
                                    setKeywords(e.target.value);
                                }} />
                                <button className='search-btn left-button' style={{ float: 'right' }} onClick={() => {
                                    console.log(keywords)
                                    if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                        Toast.fail("未注册/登录/认证！")
                                    } else {
                                        if (keywords !== null && keywords !== '') {
                                            commentAdd({
                                                objType: "product",
                                                objId: product.id,
                                                comment: keywords
                                            }).then(({ success, message }) => {
                                                (success ? Toast.success : Toast.fail)(message)
                                                commentList(product.id, "product").then(res => {
                                                    setComments(res.result.records)
                                                })
                                            })
                                        } else {
                                            Toast.fail("评论内容为空")
                                        }
                                    }

                                }}>发送</button>
                            </div>
                        </div>
                        {(comments || []).map(comment => {
                            return (
                                <div className='comment-box-item' key={comment.id}>
                                    <div className='comment-item-top'>
                                        <div className='comment-item-top-left'>
                                            <img src={comment.avatar} alt='' className='comment-person' />
                                        </div>
                                        <div className='comment-item-top-right'>
                                            <div className='commnet-item-top-right-name'>{comment.realname}</div>
                                            <div className='comment-item-top-right-time'>{comment.createTime}</div>
                                        </div>
                                    </div>
                                    {(comment.images || []).map(item => {
                                        return (
                                            <span className='input-item-right' style={{ textAlign: "right" }}>
                                                <img src={resource(item)} alt='' className='upload-com' />
                                            </span>
                                        )
                                    })}
                                    <div className='comment-item-content'>{comment.comment}</div>
                                    <div className='comment-item-btm'>
                                        <div className='video-right-item'>
                                            <span className={comment.create_by === userInfo.username ? "delete-titile" : "hide"} onClick={() => {
                                                deleteComment(comment.id).then(res => {
                                                    if (res.success) {
                                                        Toast.success(res.message)
                                                        commentList(product.id, "product").then(res => {
                                                            setComments(res.result.records)
                                                        })
                                                    }
                                                })
                                            }}>删除</span>
                                        </div>
                                        <div className='video-right-item'>
                                            <img src={commentMessage} alt='' className='comment-pic' />
                                            <span className='comment-title'>{comment.reback.length}</span>
                                        </div>
                                        <div className='video-right-item' onClick={() => {
                                            if (userInfo.identityStatus === 1 || userInfo.identityStatus === 2) {
                                                addLike({
                                                    likeId: comment.id,
                                                    likeType: 2
                                                }).then(res => {
                                                    const d = [];
                                                    comments.forEach(message1 => {
                                                        if (message1.id === comment.id) {
                                                            message1.likeCount = res.result;
                                                        }
                                                        d.push(message1);
                                                    })
                                                    setComments(d);
                                                })
                                            } else {
                                                Toast.fail("未注册/登录/认证！")
                                            }
                                        }}>
                                            <img src={zanIcon} alt='' className='comment-pic' />
                                            <span className='zan-title'>{comment.likeCount}</span>
                                        </div>
                                    </div>

                                    <button className='search-btn1 left-button' style={{ float: 'right' }} onClick={() => {
                                        if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                            Toast.fail("未注册/登录/认证！")
                                        } else {
                                            if (reKeywords !== null && reKeywords !== '') {

                                                reComments({
                                                    reCommentId: comment.id,
                                                    comment: reKeywords
                                                }).then(({ success, message }) => {
                                                    (success ? Toast.success : Toast.fail)(message)
                                                    commentList(product.id, "product").then(res => {
                                                        setComments(res.result.records)
                                                    })

                                                })
                                            } else {
                                                Toast.fail("评论内容为空")
                                            }
                                        }
                                    }}>发送</button>

                                    <input type='text' onChange={(e) => {
                                        console.log(e.target.value)
                                        setReKeywords(e.target.value)
                                    }} onKeyPress={(e) => {
                                        if (e.which === 13) {
                                            reComments({
                                                reCommentId: comment.id,
                                                comment: e.target.value
                                            }).then(({ success, message }) => {
                                                (success ? Toast.success : Toast.fail)(message)
                                                commentList(product.id, "product").then(res => {
                                                    setComments(res.result.records)
                                                })
                                            })
                                        }
                                    }} placeholder='别吝啬，快来说几句啦…' className='comment-input' />
                                    {(comment.reback || []).map(item => {
                                        return <div key={item.id} className='comment-word'>{item.realname}：{item.comment}
                                            <div className='video-right-item'>
                                                <span className={item.create_by === userInfo.username ? "delete-titile" : "hide"} onClick={() => {
                                                    deleteReComment(item.id).then(res => {
                                                        if (res.success) {
                                                            Toast.success(res.message)
                                                            commentList(product.id, "product").then(res => {
                                                                setComments(res.result.records)
                                                            })
                                                        }
                                                    })
                                                }}>删除</span>
                                            </div>
                                        </div>
                                    })}
                                </div>

                            )
                        })}

                        <div className='comment-more'>
                            <div className='comment-more-word'>展开</div><img src={down} alt='' className='down' />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}
