import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "./../../css/managerDetail.css"
import renIcon from "./../../assets/ren.png"
import { resource } from "../../utils/resource"
import mobile from "./../../assets/mobile.png"
import qrcode from "./../../assets/qrcode.png"
import backImg from "./../../assets/left.png"
import kehu from "./../../assets/kehu.png"
import videoPoster from "./../../assets/jhk-1629292297794.png"
import videoPlay from "./../../assets/play_icon.png"
import commentIco from "./../../assets/comment_icon.png"
import zanIcon from "./../../assets/zan_icon.png"
import pointIco from "./../../assets/point.png"
import bankImg from "./../../assets/bank.png"
import moreIco from "./../../assets/point.png"
import popupClose from "./../../assets/popup-close.png"
import phonePic from "../../assets/phone_icon.png"
import {
    addFans,
    addLike,
    deleteFans,
    fansCount1,
    isFans,
    queryBookingByUsername,
    userInfoByUserName
} from "../../api/user"
import { booking, getProduct } from "../../api/product"
import commentAvatar from "../../assets/comment_avatar.png"
import commentWrite from "../../assets/comment_write.png"
import { commentAdd, commentList, deleteComment, deleteReComment, reComments } from "../../api/comment"
import { Toast } from "antd-mobile-v2"
import { InfiniteScroll } from "antd-mobile"
import commentMessage from "../../assets/comment_message.png"
import down from "../../assets/down.png"
import collect from "../../assets/collect.png"
import videoPic from "../../assets/jhk-1629292297794.png"
import hotManager from "../../assets/hot-manager.png"

export default function HotManagerDetail({ history }) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const user = history.location.state
    console.log(user)
    const [visible, setVisible] = useState("hidden")
    const [visible1, setVisible1] = useState("hidden")
    const [videos, setVideos] = useState([])
    const [reKeywords, setReKeywords] = useState("")
    // 渲染出的视频列表
    const [products, setProducts] = useState([])

    const openPopup = () => {
        if (userInfo.id === "" || userInfo.id === undefined) {
            Toast.fail("请登录")
            history.push("/login")
        }
        setVisible("visible")
    }
    const openPopup1 = () => {
        if (userInfo.id === "" || userInfo.id === undefined) {
            Toast.fail("请登录")

            history.push("/login")
        }
        setVisible1("visible")
    }
    const closePopup = () => {
        setVisible("hidden")
    }
    const closePopup1 = () => {
        setVisible1("hidden")
    }

    const back = () => {
        window.history.back(-1)
    }
    const [flag, setFlag] = useState(false)

    const [comments, setComments] = useState()
    const [fansCount, setFansCount] = useState(0)
    const [fansFlag, setFansFlag] = useState(false)
    const [isAttention, setIsAttention] = useState()
    const [keywords, setKeywords] = useState("")

    if (userInfo === null || userInfo === undefined) {
        userInfo = {
            id: "",
            username: "",
            realname: "",
            avatar: "",
            birthday: null,
            sex: null,
            email: null,
            phone: "",
            orgCode: null,
            orgCodeTxt: null,
            status: 1,
            delFlag: 0,
            workNo: null,
            post: null,
            telephone: "11",
            createBy: null,
            createTime: "",
            updateBy: "",
            updateTime: "",
            activitiSync: 0,
            userIdentity: null,
            departIds: null,
            relTenantIds: null,
            clientId: null,
            userWx: null,
            userQrCode: "",
            userLocation: "上海市",
            userStatus: 5,
            userCompany: "",
            identityLevel: "",
            userIdCard: "",
            certificationInformation1: null,
            certificationInformation2: "",
            introduction: "",
            identityStatus: null
        }
    }

    const getFansData = () =>{
        fansCount1(user.username).then((res) => {
            setFansCount(res.result)
        })
    }

    useEffect(() => {
        userInfoByUserName(user.username).then((res) => {
            console.log(res.result.records)
            const result = res.result.records[0]
            const videos = result.videos
            const products = result.products
            setVideos(videos)
            setProducts(products)

            if (
                userInfo.id !== "" &&
                userInfo.id !== null &&
                userInfo.id !== undefined &&
                userInfo.username !== result.username
            ) {
                queryBookingByUsername(user.username).then((res1) => {
                    setFlag(res1.result)
                })
            }
        })
        commentList(user.id, "user").then((res) => {
            setComments(res.result.records)
        })
        if (userInfo.id !== "" && userInfo.id !== null && userInfo.id !== undefined) {
            isFans(user.username).then((res1) => {
                setFansFlag(res1.result)
            })
        }

        fansCount1(user.username).then((res) => {
            setFansCount(res.result)
        })

        //页面滚动到顶部
        // document.body.scrollIntoView()
    }, [])
    return (
        <div className="wrapper" style={{ paddingBottom: "0" }}>
            <div className="mine-top">
                {/* 头部标题 */}
                <div className="mine-top-title" style={{ color: "#fff" }}>
                    <img src={backImg} alt="" className="title-left1" onClick={back} />
                    热门经理
                </div>

                <div
                    className="manager-item manager-detail-back"
                    style={{ margin: "0 10px", backgroundColor: "rgba(255,255,255,1)" }}
                >
                    <div className="manager-item-top">
                        {/* 热门经理头像 */}
                        <div className="item-top-left">
                            <img
                                src={
                                    user.avatar === null || user.avatar === undefined
                                        ? hotManager
                                        : resource(user.avatar)
                                }
                                alt=""
                                className="manager-avatar-pic"
                            />
                        </div>
                        <div className="item-top-right">
                            {/* 热门经理名称 */}
                            <div className="item-top-ren">
                                <div className="item-top-ren-title">{user.realname}</div>
                            </div>
                            {/* 热门经理被关注数 */}
                            <div className="attention-word-btm">
                                <span className="awbr">
                                    已有
                                    {fansCount === undefined || fansCount === null || fansCount === "" ? 0 : fansCount}
                                    人关注了他
                                </span>
                            </div>
                            {/* ??? */}
                            <div className="item-top-desc">{user.post_dictText}</div>
                            {/* 热门经理介绍 */}
                            <div className="item-top-con">{user.introduction}</div>
                        </div>
                    </div>

                    <div className="detail-btm">
                        {/* 联系经理 */}
                        <div className="detail-btm-btn">
                            <img src={mobile} alt="" className="mobile-icon" />
                            <span
                                className="mobile-span"
                                onClick={() => {
                                    if (user.username === userInfo.username) {
                                        Toast.fail("本人无需申请")
                                    } else {
                                        if (!flag) {
                                            // managerId: user.username
                                            booking({ managerId: user.id, userQrCode: user.userWx }).then(
                                                ({ message }) => {
                                                    // Toast.success("申请成功")
                                                    console.log(message)
                                                }
                                            )
                                        }
                                        openPopup1()
                                    }
                                }}
                            >
                                联系经理
                            </span>
                        </div>
                        {/* 二维码 */}
                        <div className="detail-btm-btn qrcode">
                            <img src={qrcode} alt="" className="mobile-icon" />
                            <span
                                className="mobile-span"
                                onClick={() => {
                                    if (user.username === userInfo.username) {
                                        Toast.fail("本人无需申请")
                                    } else {
                                        if (!flag) {
                                            booking({ managerId: user.id }).then(({ message }) => {
                                                Toast.success("申请成功")
                                            })
                                        }
                                        openPopup()
                                    }
                                }}
                            >
                                二维码
                            </span>
                        </div>
                        {/* 关注 */}
                        <div className="detail-btm-btn">
                            <img src={collect} alt="" className={"mobile-icon"} />
                            <span
                                className="mobile-span"
                                onClick={() => {
                                    if (user.username === userInfo.username) {
                                        Toast.fail("不能关注自己")
                                    } else {
                                        if (fansFlag) {
                                            deleteFans(user.username).then((res) => {
                                                Toast.success("取关成功", 2, () => {
                                                    setFansFlag(false)
                                                    getFansData()
                                                })
                                            })
                                        } else {
                                            addFans(user.username).then((res) => {
                                                Toast.success("关注成功", 2, () => {
                                                    setFansFlag(res.result)
                                                    getFansData()
                                                })
                                            })
                                        }
                                        // window.location.reload()
                                    }
                                }}
                            >
                                {fansFlag ? "取消关注" : "关注"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mine-btm">
                <div className="hot-content">
                    <div className="hot-title">
                        <div className="hot-title-left">相关视频</div>
                        <div className="hot-title-right">
                            <Link to="/video" className="hot-a">
                                更多
                            </Link>
                        </div>
                    </div>
                    {videos.length > 0 ? "" : "暂无内容"}
                    <div className="hot-content-scroll">
                        {videos.map((item, index) => {
                            // var count = 0
                            return (
                                <div className="mine-video-item deS" style={{ width: "15rem" }} key={index}>
                                    <div className="video-item-top">
                                        <video
                                            className="video-img1"
                                            src={resource(item.videoUrl)}
                                            onClick={() => history.push("videoDetail", item)}
                                        />
                                    </div>
                                    <div className="video-item-middle">{item.remark}</div>
                                    <div className="video-item-btm">
                                        <div className="video-item-btm-left">
                                            <img src={kehu} alt="" className="video-avatar" />
                                            <span className="video-name">{user.realname}</span>
                                        </div>
                                        <div
                                            className="video-item-btm-right"
                                            onClick={() => {
                                                if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                                    Toast.fail("未注册/登录/认证！")
                                                } else {
                                                    if (userInfo.username === user.username) {
                                                        Toast.fail("不能给自己点赞")
                                                    } else {
                                                        addLike({
                                                            likeId: item.id,
                                                            likeType: 1
                                                        }).then((res) => {
                                                            const d = []
                                                            videos.forEach((video) => {
                                                                if (video.id === item.id) {
                                                                    video.likeCount = res.result
                                                                }
                                                                d.push(video)
                                                            })
                                                            setVideos(d)
                                                        })
                                                    }
                                                }
                                            }}
                                        >
                                            <div className="video-right-item">
                                                <img src={commentIco} alt="" className="comment-pic" />
                                                <span className="comment-title">{item.commentCount}</span>
                                            </div>
                                            <div className="video-right-item">
                                                <img src={zanIcon} alt="" className="comment-pic" />
                                                <span className="zan-title">{item.likeCount}</span>
                                            </div>
                                            <div className="video-right-item">
                                                <img src={pointIco} alt="" className="comment-pic" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="hot-part">
                    <div className="hot-title">
                        <div className="hot-title-left">贷款产品</div>
                        {/*<div className='hot-title-right'><Link to='/' className='hot-a'>更多</Link></div>*/}
                    </div>
                    <div className="hot-content">
                        {products.length > 0 ? "" : "暂无内容"}
                        {products.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="bank-item"
                                    style={{ backgroundColor: "rgba(255,255,255,.5)", marginTop: "10px" }}
                                    onClick={() => {
                                        getProduct(item.id).then(({ result }) => {
                                            history.push("/BankDetail", { product: result })
                                        })
                                    }}
                                >
                                    <div className="bank-item-top">
                                        <div className="bank-top-left">
                                            <img src={bankImg} alt="" className="bank-image" />
                                            <div className="bank-top-center">
                                                <div className="yu-top-title">{item.proName}</div>
                                                <div className="sort-item-middle">
                                                    <div className="middle-item">
                                                        <div className="middle-item-top top-red">{item.proAmount}</div>
                                                        <div className="middle-item-btm">贷款金额</div>
                                                    </div>
                                                    <div className="middle-item">
                                                        <div className="middle-item-top">{item.proRate}</div>
                                                        <div className="middle-item-btm">参考利率</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bank-item-btm">
                                        <div className="btm-top">
                                            <div className="btm-top">
                                                <div className="btm-item">{item.proLabel}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bank-point">
                                        <img src={moreIco} alt="" className="point-icon" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="comment-box">
                <div className="comment-box-title">全部评论</div>
                <div className="comment-box-content">
                    <div className="comment-box-top">
                        <div className="comment-box-top-left">
                            <img src={commentAvatar} alt="" className="comment-avatar-pic" />
                        </div>
                        <div className="comment-box-top-right">
                            <img src={commentWrite} alt="" className="comment-write" />
                            <input
                                type="text"
                                readOnly={userInfo.identityStatus != null ? "" : "readOnly"}
                                onChange={(e) => {
                                    setKeywords(e.target.value)
                                }}
                                placeholder="别吝啬，快来说几句啦…"
                                className="comment-input"
                            />
                            <button
                                className="search-btn left-button"
                                style={{ float: "right" }}
                                onClick={() => {
                                    if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                        Toast.fail("未注册/登录/认证！")
                                    } else {
                                        if (keywords !== null && keywords !== "") {
                                            commentAdd({
                                                objType: "user",
                                                objId: user.id,
                                                comment: keywords
                                            }).then(({ success, message }) => {
                                                ;(success ? Toast.success : Toast.fail)(message)
                                                commentList(user.id, "user").then((res) => {
                                                    setComments(res.result.records)
                                                })
                                            })
                                        } else {
                                            Toast.fail("评论内容为空")
                                        }
                                    }
                                }}
                            >
                                发送
                            </button>
                        </div>
                    </div>
                    {(comments || []).map((comment) => {
                        return (
                            <div className="comment-box-item" key={comment.id}>
                                <div className="comment-item-top">
                                    <div className="comment-item-top-left">
                                        <img src={comment.avatar} alt="" className="comment-person" />
                                    </div>
                                    <div className="comment-item-top-right">
                                        <div className="commnet-item-top-right-name">{comment.realname}</div>
                                        <div className="comment-item-top-right-time">{comment.createTime}</div>
                                    </div>
                                </div>
                                {(comment.images || []).map((item) => {
                                    return (
                                        <span className="input-item-right" style={{ textAlign: "right" }}>
                                            <img src={resource(item)} alt="" className="upload-com" />
                                        </span>
                                    )
                                })}

                                <div className="comment-item-content">{comment.comment}</div>
                                <div className="comment-item-btm">
                                    <div className="video-right-item">
                                        <span
                                            className={
                                                comment.create_by === userInfo.username ? "delete-titile" : "hide"
                                            }
                                            onClick={() => {
                                                deleteComment(comment.id).then((res) => {
                                                    if (res.success) {
                                                        Toast.success(res.message)
                                                        commentList(user.id, "user").then((res) => {
                                                            setComments(res.result.records)
                                                        })
                                                    }
                                                })
                                            }}
                                        >
                                            删除
                                        </span>
                                    </div>

                                    <div className="video-right-item">
                                        <img src={commentMessage} alt="" className="comment-pic" />
                                        <span className="comment-title">{comment.reback.length}</span>
                                    </div>

                                    <div
                                        className="video-right-item"
                                        onClick={() => {
                                            if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                                Toast.fail("未注册/登录/认证！")
                                            } else {
                                                if (userInfo.username === user.username) {
                                                    Toast.fail("不能给自己点赞")
                                                } else {
                                                    addLike({
                                                        likeId: comment.id,
                                                        likeType: 2
                                                    }).then((res) => {
                                                        const d = []
                                                        comments.forEach((message1) => {
                                                            if (message1.id === comment.id) {
                                                                message1.likeCount = res.result
                                                            }
                                                            d.push(message1)
                                                        })
                                                        setComments(d)
                                                    })
                                                }
                                            }
                                        }}
                                    >
                                        <img src={zanIcon} alt="" className="comment-pic" />
                                        <span className="zan-title">{comment.likeCount}</span>
                                    </div>
                                </div>
                                <button
                                    className="search-btn1 left-button"
                                    style={{ float: "right" }}
                                    onClick={() => {
                                        if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                            Toast.fail("未注册/登录/认证！")
                                        } else {
                                            if (reKeywords !== null && reKeywords !== "") {
                                                reComments({
                                                    reCommentId: comment.id,
                                                    comment: reKeywords
                                                }).then(({ success, message }) => {
                                                    ;(success ? Toast.success : Toast.fail)(message)
                                                    commentList(user.id, "user").then((res) => {
                                                        setComments(res.result.records)
                                                    })
                                                })
                                            } else {
                                                Toast.fail("评论内容为空")
                                            }
                                        }
                                    }}
                                >
                                    发送
                                </button>

                                <input
                                    type="text"
                                    onKeyPress={(e) => {
                                        if (e.which === 13) {
                                            if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                                Toast.fail("未注册/登录/认证！")
                                            } else {
                                                reComments({
                                                    reCommentId: comment.id,
                                                    comment: e.target.value
                                                }).then(({ success, message }) => {
                                                    ;(success ? Toast.success : Toast.fail)(message)
                                                    commentList(user.id, "user").then((res) => {
                                                        setComments(res.result.records)
                                                    })
                                                })
                                            }
                                        }
                                    }}
                                    placeholder="别吝啬，快来说几句啦…"
                                    className="comment-input"
                                    onChange={(e) => setReKeywords(e.target.value)}
                                />
                                {(comment.reback || []).map((item) => {
                                    return (
                                        <div key={item.id} className="comment-word">
                                            {item.realname}：{item.comment}
                                            <div className="video-right-item">
                                                <span
                                                    className={
                                                        item.create_by === userInfo.username ? "delete-titile" : "hide"
                                                    }
                                                    onClick={() => {
                                                        deleteReComment(item.id).then((res) => {
                                                            if (res.success) {
                                                                Toast.success(res.message)
                                                                commentList(user.id, "user").then((res) => {
                                                                    setComments(res.result.records)
                                                                })
                                                            }
                                                        })
                                                    }}
                                                >
                                                    删除
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}

                    <div className="comment-more">
                        <div className="comment-more-word">展开</div>
                        <img src={down} alt="" className="down" />
                    </div>
                </div>
            </div>

            <div className="popup" style={{ visibility: visible }}>
                <div className="popup-div" />
                <div className="popop-box4">
                    <div className="popup-close">
                        <img src={popupClose} alt="" className="popup-close-icon" onClick={closePopup} />
                    </div>
                    <div className="popup-con">
                        {flag ? (
                            <img src={user.userQrCode} alt="" />
                        ) : (
                            "查看经理联系方式需等经理同意请稍后在个人中心查看结果"
                        )}
                    </div>
                    <div className="popup-btn" onClick={closePopup}>
                        确定
                    </div>
                </div>
            </div>

            <div className="popup" style={{ visibility: visible1 }}>
                <div className="popup-div" />
                <div className="popop-box">
                    <div className="popup-close">
                        <img src={popupClose} alt="" className="popup-close-icon" onClick={closePopup1} />
                    </div>
                    <div className="popup-con">
                        {flag ? user.telephone : "查看经理联系方式需等经理同意请稍后在个人中心查看结果"}
                    </div>
                    <div className="popup-btn" onClick={closePopup1}>
                        确定
                    </div>
                </div>
            </div>
        </div>
    )
}
