import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import "./../../css/mine.css"
import "./../../css/person.css"
import popupClose from "./../../assets/popup-close.png"
import phonePic from "./../../assets/phone_icon.png"
import wechatPic from "./../../assets/wechat_icon.png"
import managerPic from "./../../assets/avatar.png"
import viper from "./../../assets/viper.png"
import homeIcon from "./../../assets/home_select.png"
import mineIcon from "./../../assets/mine_selected.png"
import menuIcon from "./../../assets/menu_address.png"
import kehu from "./../../assets/kehu.png"
import bankPic from "./../../assets/bank_logo.png"
import clockPic from "./../../assets/date_icon.png"
import bankImg from "./../../assets/bank.png"
import videoPoster from "./../../assets/jhk-1629292297794.png"
import videoPlay from "./../../assets/play_icon.png"
import commentIco from "./../../assets/comment_icon.png"
import zanIcon from "./../../assets/zan_icon.png"
import pointIco from "./../../assets/point.png"
import collectIcon from "./../../assets/collect.png"
import videoPic from "../../assets/jhk-1629292297794.png"
import {
    dtBooking,
    myvideos,
    myproducts,
    getUserDetail,
    userInfoByUserName,
    editUser,
    deleteDtBooking,
    myFansAndAttention,
    logout,
    editDtBooking,
    myComments,
    addLike
} from "../../api/user"
import { resource } from "../../utils/resource"
import { Button, List, Picker, Toast } from "antd-mobile-v2"
import Assert from "assert"
import addressIcon from "../../assets/add.png"
import rightIcon from "../../assets/right.png"
import { getProduct } from "../../api/product"
import { commentList, deleteComment, deleteReComment, reComments } from "../../api/comment"
import commentMessage from "../../assets/comment_message.png"

export default function Mine({ history }) {
    // console.log(history.location.state)
    const user = history.location.state
    const [products, setProducts] = useState([])
    const [videos, setVideos] = useState([])
    const [content, setContent] = useState([])
    const [booking, setBooking] = useState([])
    const [userInfo1, setUserInfo] = useState({})
    const [fansAttention, setFansAttention] = useState({})
    const [visible, setVisible] = useState(false)
    const [visible1, setVisible1] = useState("hidden")
    const [visible2, setVisible2] = useState("hidden")
    const [popup, setPopup] = useState({})
    const closePopup = () => {
        setVisible1("hidden")
    }
    const openPopup = () => {
        setVisible1("visible")
    }

    const closePopup2 = () => {
        setVisible2("hidden")
    }
    const openPopup2 = () => {
        if (userInfo.id === "" || userInfo.id === undefined) {
            Toast.fail("请登录")

            history.push("/login")
        }
        setVisible2("visible")
    }
    if (!localStorage.getItem("userInfo")) history.push("/login")
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    let identityFlag = false
    if (userInfo === null || userInfo === undefined) {
        userInfo = {
            id: "",
            username: "",
            realname: "",
            avatar: "",
            birthday: null,
            sex: null,
            email: null,
            phone: "",
            orgCode: null,
            orgCodeTxt: null,
            status: 1,
            delFlag: 0,
            workNo: null,
            post: null,
            telephone: "11",
            createBy: null,
            createTime: "",
            updateBy: "",
            updateTime: "",
            activitiSync: 0,
            userIdentity: null,
            departIds: null,
            relTenantIds: null,
            clientId: null,
            userWx: null,
            userQrCode: "",
            userLocation: "上海市",
            userStatus: 5,
            userCompany: "",
            identityLevel: "",
            userIdCard: "",
            certificationInformation1: null,
            certificationInformation2: "",
            introduction: "",
            identityStatus: null
        }
    } else {
        identityFlag = userInfo.identityStatus === 1 || userInfo.identityStatus === 2
    }

    useEffect(() => {
        if (userInfo.identityStatus !== null && userInfo.identityStatus !== undefined) {
            dtBooking(3).then((res) => {
                const result = res.result.records
                setBooking(result)
                console.log(result)
            })

            myvideos(1,5).then((res) => {
                const result = res.result.records
                setVideos(result)
            })

            myproducts(1,3).then((res) => {
                let result = res.result.records
                var arr = []
                result.forEach(item=>{
                    if (item.proType=='抵押贷'){
                        arr = [
                            { id: 1, name: '房一抵', checked: false },
                        { id: 2, name: '房二抵', checked: false },
                        { id: 3, name: '车辆抵押', checked: false },
                        { id: 4, name: '股权抵押', checked: false },
                        { id: 5, name: '按揭房抵押', checked: true },
                        { id: 20, name: '其他合法财产抵押', checked: true },
                        ]
                    }else{
                        arr = [
                            { id: 6, name: '社保', checked: false },
                            { id: 7, name: '公积金', checked: false },
                            { id: 8, name: '打卡工资', checked: false },
                            { id: 9, name: '个税', checked: false },
                            { id: 10, name: '房贷', checked: true },
                            { id: 11, name: '车贷', checked: false },
                            { id: 12, name: '保单', checked: true },
                            { id: 13, name: '信用卡', checked: false },
                            { id: 14, name: '微粒贷', checked: false },
                            { id: 15, name: '流水贷', checked: false },
                            { id: 16, name: '烟草证贷', checked: false },
                            { id: 17, name: '学历贷', checked: false },
                            { id: 18, name: '装修贷', checked: false },
                            { id: 19, name: '房值贷', checked: false },
                        ]
                    }
                        let temp = []
                        if (item.proConditionList){
                            item.proConditionList.forEach(item1=>{
                                let res = arr.find((item2)=>{
                                    console.log(item2,item1)
                                    return item2.id == item1
                                })
                                console.log(res)
                                if (res) temp.push(res.name)
                                
                            })
                        }
                        item.condition = temp
                        
                    
                })

                setProducts(result)
            })

            myFansAndAttention().then((res) => {
                if (!res.success) {
                    setFansAttention({ attentionCount: 0, fansCount: 0 })
                } else {
                    const result = res.result
                    setFansAttention(result)
                }
            })
            myComments(userInfo.username).then((res) => {
                const result = res.result.slice(0, 2)
                setContent(result)
            })
        }
    }, [])

    return (
        <div className="wrapper">
            <div className="mine-top">
                <div className="mine-top-title"></div>
                <div className="mine-top-middle">
                    {/* 手机号 */}
                    <div className="middle-left">
                        <img src={phonePic} alt="" className="phone-icon" />
                        <div className="phone-word">{userInfo.phone}</div>
                    </div>
                    {/* 头像 用户名称 所属公司 */}
                    <div className="middle-center">
                        <img
                            src={userInfo.avatar ? resource(userInfo.avatar) : kehu}
                            alt=""
                            className="avatar-icon"
                            style={{ borderRadius: "25%" }}
                        />
                        <div className="avatar-word">{userInfo.realname}</div>
                        <div className="avatar-desc">
                            <span className="avatar-text">
                                {userInfo.userCompany && userInfo.userCompany.length > 5
                                    ? userInfo.userCompany.substring(0, 5) + "..."
                                    : userInfo.userCompany}
                            </span>
                        </div>
                    </div>
                    {/* 微信号 */}
                    <div className="middle-left">
                        <img src={wechatPic} alt="" className="phone-icon" />
                        <div className="phone-word">
                            {userInfo.userWx === null || userInfo.userWx === undefined || userInfo.userWx === ""
                                ? "暂未填写"
                                : userInfo.userWx}
                        </div>
                    </div>
                </div>

                <div className="mine-top-btm">
                    {/* 认证状态 */}
                    <div className="btm-left">
                        <span
                            className="btm-left-item"
                            onClick={() => {
                                setVisible(true)
                            }}
                        >
                            {identityFlag
                                ? "已认证"
                                : userInfo.identityStatus === 4 || userInfo.identityStatus === 3
                                ? "待审核"
                                : "未认证"}
                        </span>
                    </div>
                    {/* 跳转个人信息页面 */}
                    <div className="btm-right">
                        <span
                            className="btm-right-item"
                            onClick={() => {
                                history.push("/personInfo")
                            }}
                        >
                            个人信息
                        </span>
                    </div>
                </div>
                {/* 跳转获赞 粉丝 关注页面 */}
                <div
                    className={userInfo.identityStatus === 2 || userInfo.identityStatus === 1 ? "person-flex" : "hide"}
                    onClick={() => {
                        history.push("/attention")
                    }}
                >
                    <div className="person-item">
                        <div className="person-item-top">获赞</div>
                        <div className="person-item-btm">{userInfo.activitiSync}</div>
                    </div>
                    <div className="person-item">
                        <div className="person-item-top">粉丝</div>
                        <div className="person-item-btm">{fansAttention.fansCount}</div>
                    </div>
                    <div className="person-item">
                        <div className="person-item-top">关注</div>
                        <div className="person-item-btm">{fansAttention.attentionCount}</div>
                    </div>
                </div>
            </div>

            <div className="mine-btm">
                {/* 成为会员 功能未启用*/}
                <div className="vip-submit" onClick={openPopup}>
                    <img src={viper} alt="" className="viper-icon" />
                </div>
                <div className="hot-part">
                    <div className="hot-title">
                        <div className="hot-title-left">客户预约记录</div>
                        <div className="hot-title-right">
                            <Link to="/myProductsBookings" className="hot-a">
                                更多
                            </Link>
                        </div>
                    </div>
                    {/* 预约列表 */}
                    <div className="yu-content">
                        {booking.length > 0 ? "" : "暂无内容"}
                        {booking.map((item) => {
                            console.log(item)
                            return (
                                <div className="yu-item" key={item.id}>
                                    <div className="yu-item-top">
                                        <div className="yu-top-flex">
                                            <div className="yu-top-logo" style={{width:'4rem'}}>
                                                <img src={item.managerAvatar||item.productAvatar||bankPic} alt="" className="bank-logo" />
                                            </div>
                                            <div className="yu-top-middle" style={{width:'calc(100% - 4rem)'}}>
                                                <div style={{display:'flex',justifyContent:'space-between',marginLeft:'0.5rem'}}> 
                                                <div style={{marginLeft:'0'}} className="yu-top-title">{item.productName||item.managerUsername}</div>
                                                <div className="yu-top-right">
                                                    <img src={clockPic} alt="" className="clock-icon" />
                                                    <span className="clock-span">{item.createTime}</span>
                                                </div>
                                                </div>
                                               
                                                <div className="yu-top-desc" style={{marginLeft:'0.5rem'}}>
                                                    预约进度：
                                                    <span className={item.isRead === 1 ? "desc-span1" : "desc-span"}>
                                                        {item.isRead === 1 ? "已查看" : "尚未被查看"}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* 预约记录创建时间 */}
                                           
                                        </div>
                                        {/* 预约客户信息 */}
                                        <div className="yu-top-desc">
                                            <div className="" style={{marginLeft:'4.5rem'}}>预约客户：</div>
                                            <div className="yu-desc-avatar" style={{marginLeft:'0.25rem'}}>
                                                <img src={item.userAvatar||kehu} style={{width:'2.3rem',height:'2.3rem',borderRadius:'0.5rem'}} alt="" className="keHu" />
                                                <div className="kehu-word">{item.username}</div>
                                            </div>
                                            {/*<div className='yu-desc-avatar'>*/}
                                            {/*    <img src={kehu} alt='' className='keHu' />*/}
                                            {/*    <div className='kehu-word'>陈默含</div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>

                                    <div className="yu-item-btm">
                                        {/* 删除预约 */}
                                        <div className="yu-btm-cancel">
                                            <button
                                                className="cancel-btn"
                                                onClick={() => {
                                                    deleteDtBooking(item.id).then((res) => {
                                                        if (res.success) {
                                                            Toast.success(res.message)
                                                            dtBooking(3).then((res) => {
                                                                const result = res.result.records
                                                                setBooking(result)
                                                            })
                                                        }
                                                    })
                                                }}
                                            >
                                                删除
                                            </button>
                                        </div>
                                        {/* 查看预约 */}
                                        <div className="yu-btm-check">
                                            <button
                                                className="check-btn"
                                                onClick={() => {
                                                    if (item.isRead !== 1 && userInfo.username === item.productId) {
                                                        editDtBooking({ isRead: 1, id: item.id }).then((res) => {
                                                            if (res.code === 200) {
                                                                item.isRead = 1
                                                                setPopup({
                                                                    phone: item.phone,
                                                                    userQrCode: item.userQrCode,
                                                                    flag: item.isRead === 1
                                                                })
                                                                openPopup2()
                                                            } else {
                                                                Toast.fail(res.message)
                                                            }
                                                        })
                                                    } else if (
                                                        item.isRead === 1 &&
                                                        userInfo.username !== item.productId
                                                    ) {
                                                        getUserDetail(item.productId).then((r) => {
                                                            console.log(r)
                                                            setPopup({
                                                                phone: item.productId,
                                                                userQrCode: r.result.userWx,
                                                                flag: item.isRead === 1
                                                            })
                                                            openPopup2()
                                                        })
                                                    } else {
                                                        setPopup({
                                                            phone: item.phone,
                                                            userQrCode: item.userQrCode,
                                                            flag: item.isRead === 1
                                                        })
                                                        openPopup2()
                                                    }
                                                }}
                                            >
                                                查看
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={userInfo.identityStatus === 2 || userInfo.identityStatus === 1 ? "hot-part" : "hide"}>
                    <div className="hot-title">
                        <div className="hot-title-left">我的产品</div>
                        <div className="hot-title-right">
                            <Link to="/myProducts" className="hot-a">
                                更多
                            </Link>
                        </div>
                    </div>
                    <div className="yu-content">
                        {products.length > 0 ? "" : "暂无内容"}
                        {products.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    className="bank-item"
                                    onClick={() => {
                                        getProduct(item.id).then(({ result }) => {
                                            history.push("/BankDetail", { product: result })
                                        })
                                    }}
                                >
                                    <div className="bank-item-top">
                                        <div className="bank-top-left">
                                            <img src={item.proAvator||bankImg} alt="" className="bank-image" />
                                            <div className="bank-top-center">
                                                <div className="yu-top-title">{item.proName}</div>
                                                <div className="sort-item-middle">
                                                    <div className="middle-item">
                                                        <div className="middle-item-top top-red">{item.proAmount}</div>
                                                        <div className="middle-item-btm">贷款金额</div>
                                                    </div>
                                                    <div className="middle-item">
                                                        <div className="middle-item-top">{!item.proRate?(item.proRateMin + '~'+ item.proRateMax + '%'):item.proRate}</div>
                                                        <div className="middle-item-btm">参考利率</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className='bank-top-right'>*/}
                                        {/*    <img src={collectIcon} alt='' className='collect-img' /><span className='collect-span'>取消收藏</span>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="bank-item-btm">
                                        <div className="btm-top">
                                        {item.condition.map((item5,idx)=>
                                        <div className='btm-item'>{item5}</div>
                                    )
                                    }
                                        </div>
                                    </div>
                                    {/*<div className='bank-item-'>*/}
                                    {/*    <span className='-span'>已买</span>*/}
                                    {/*</div>*/}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={userInfo.identityStatus === 2 || userInfo.identityStatus === 1 ? "hot-part" : "hide"}>
                    <div className="hot-title">
                        <div className="hot-title-left">我的视频</div>
                        <div className="hot-title-right">
                            <Link to="/myvideo" className="hot-a">
                                更多
                            </Link>
                        </div>
                    </div>
                    <div className="hot-content">
                        {videos.length > 0 ? "" : "暂无内容"}
                        <div className="hot-content-scroll">
                            {videos.map((item, index) => {
                                return (
                                    <div
                                        key={item.id}
                                        className="mine-video-item deS"
                                        style={{ width: "15rem" }}
                                        onClick={() => history.push("videoDetail", item)}
                                    >
                                        <div className="video-item-top">
                                            <video
                                                className="video-img1"
                                                src={resource(item.videoUrl)}
                                                onClick={() => history.push("videoDetail", item)}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={userInfo.identityStatus === 2 || userInfo.identityStatus === 1 ? "hot-part" : "hide"}>
                    <div className="hot-title">
                        <div className="hot-title-left">我的动态</div>
                        <div className="hot-title-right">
                            <Link to="/myComments" className="hot-a">
                                更多
                            </Link>
                        </div>
                    </div>
                    <div className="hot-content">
                        {content.length > 0 ? "" : "暂无内容"}
                        <div className="hot-content-scroll">
                            {(content || []).map((comment) => {
                                return (
                                    <div className="comment-box-item" key={comment.id}>
                                        <div className="comment-item-top">
                                            <div className="comment-item-top-left">
                                                <img src={comment.avatar} alt="" className="comment-person" />
                                            </div>
                                            <div className="comment-item-top-right">
                                                <div className="commnet-item-top-right-name">{comment.realname}</div>
                                                <div className="comment-item-top-right-time">{comment.createTime}</div>
                                            </div>
                                        </div>
                                        {(comment.images || []).map((item, index) => {
                                            return (
                                                <span
                                                    className="input-item-right"
                                                    style={{ textAlign: "right" }}
                                                    key={index}
                                                >
                                                    <img src={resource(item)} alt="" className="upload-com" />
                                                </span>
                                            )
                                        })}

                                        <div className="comment-item-content">{comment.comment}</div>
                                        <div className="comment-item-btm">
                                            <div className="video-right-item">
                                                <span
                                                    className={
                                                        comment.create_by === userInfo.username
                                                            ? "delete-titile"
                                                            : "hide"
                                                    }
                                                    onClick={() => {
                                                        deleteComment(comment.id).then((res) => {
                                                            if (res.success) {
                                                                Toast.success(res.message)
                                                                myComments(userInfo.username).then((res) => {
                                                                    setContent(res.result.slice(0, 2))
                                                                })
                                                            }
                                                        })
                                                    }}
                                                >
                                                    删除
                                                </span>
                                            </div>

                                            <div className="video-right-item">
                                                <img src={commentMessage} alt="" className="comment-pic" />
                                                <span className="comment-title">{comment.reback.length}</span>
                                            </div>

                                            <div
                                                className="video-right-item"
                                                onClick={() => {
                                                    if (
                                                        userInfo.identityStatus !== 1 &&
                                                        userInfo.identityStatus !== 2
                                                    ) {
                                                        Toast.fail("未注册/登录/认证！")
                                                    } else {
                                                        console.log(userInfo,user,'cccccccccc')
                                                        if (userInfo.username == comment.realname) {
                                                            Toast.fail("不能给自己点赞")
                                                        } else {
                                                            addLike({
                                                                likeId: comment.id,
                                                                likeType: 2
                                                            }).then((res) => {
                                                                const d = []
                                                                // TODO 点赞成功后
                                                                content.forEach(message1 => {
                                                                    if (message1.id === comment.id) {
                                                                        message1.likeCount = res.result;
                                                                    }
                                                                    d.push(message1);
                                                                })
                                                                setContent(d);
                                                            })
                                                        }
                                                    }
                                                }}
                                            >
                                                <img src={zanIcon} alt="" className="comment-pic" />
                                                <span className="zan-title">{comment.likeCount}</span>
                                            </div>
                                        </div>

                                        {(comment.reback || []).map((item) => {
                                            return (
                                                <div key={item.id} className="comment-word">
                                                    {item.realname}：{item.comment}
                                                    <div className="video-right-item">
                                                        <span
                                                            className={
                                                                item.create_by === userInfo.username
                                                                    ? "delete-titile"
                                                                    : "hide"
                                                            }
                                                            onClick={() => {
                                                                deleteReComment(item.id).then((res) => {
                                                                    if (res.success) {
                                                                        Toast.success(res.message)
                                                                        myComments(userInfo.username).then((res) => {
                                                                            setContent(res.result.slice(0, 2))
                                                                        })
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            删除
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="tarbar">
                <div className="tarbar-home">
                    <img src={homeIcon} alt="" className="home-icon" />
                    <div className="home-name">
                        <Link to="/">首页</Link>
                    </div>
                </div>
                <div className="tarbar-middle">
                    <img
                        src={menuIcon}
                        alt=""
                        onClick={() => {
                            history.push("/message")
                        }}
                        className="menu-icon"
                    />
                </div>
                <div className="tarbar-home">
                    <img src={mineIcon} alt="" className="home-icon" />
                    <div className="home-named">
                        <Link to="/person">我的</Link>
                    </div>
                </div>
            </div>
            <div className="popup" style={{ display: visible ? "block" : "none" }}>
                <div className="popup-div" />
                <div className="popop-box">
                    <div className="popup-close">
                        <img
                            src={popupClose}
                            alt=""
                            className="popup-close-icon"
                            onClick={() => {
                                setVisible(false)
                            }}
                        />
                    </div>
                    <div className="popup-con" style={{ display: "flex", width: "280px", height: "105px" }}>
                        <Button
                            type="primary"
                            onClick={() => {
                                history.push("/companyAttest")
                            }}
                            style={{
                                flex: 1,
                                marginRight: "5px",
                                marginTop: "20px",
                                background: "linear-gradient(0deg, #7683D9, #D8A0FE)"
                            }}
                        >
                            企业认证
                        </Button>
                        <br />
                        <Button
                            type="primary"
                            onClick={() => {
                                history.push("/attest")
                            }}
                            style={{
                                flex: 1,
                                marginTop: "20px",
                                background: "linear-gradient(0deg, #7683D9, #D8A0FE)"
                            }}
                        >
                            个人认证
                        </Button>
                    </div>
                </div>
            </div>
            <div className="popup" style={{ visibility: visible1 }}>
                <div className="popop-box1">
                    <div className="popup-close">
                        <img src={popupClose} alt="" className="popup-close-icon" onClick={closePopup} />
                    </div>
                    <div className="popup-con">此功能暂不开启祝业绩长虹</div>
                    <div className="popup-btn" onClick={closePopup}>
                        确定
                    </div>
                </div>
            </div>
            {/* 查看预约弹窗 */}
            <div className="popup" style={{ visibility: visible2 }}>
                <div className="popop-box3">
                    <div className="popup-close">
                        <img src={popupClose} alt="" className="popup-close-icon" onClick={closePopup2} />
                    </div>
                    <div className="popup-con">
                        {popup.flag ? "" : "等待对方同意"}
                        <div className={popup.flag ? "delete-titile" : "hide"}>
                            <span className={popup.flag ? "delete-titile" : "hide"}>手机号：</span>
                            <div />
                            {popup.phone === null || popup.phone === "" ? "暂未上传" : popup.phone}
                            <div />
                            <span className={popup.flag ? "delete-titile" : "hide"}>微信号：</span>
                            {
                                popup.userQrCode === null || popup.userQrCode === "" ? "暂未上传" : popup.userQrCode
                                // <img src={popup.userQrCode} alt="" />
                            }
                        </div>
                    </div>
                    <div className="popup-btn1" onClick={closePopup2}>
                        确定
                    </div>
                </div>
            </div>
        </div>
    )
}
