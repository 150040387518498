import React, { useEffect, useState, useRef } from "react";
import "./../../css/video.css";
import "./../../css/mine.css";
import videoPoster from "./../../assets/jhk-1629292297794.png";
import videoPlay from "./../../assets/play_icon.png";
import commentIco from "./../../assets/comment_icon.png";
import zanIcon from "./../../assets/zan_icon.png";
import pointIco from "./../../assets/point.png";
import kehu from "./../../assets/kehu.png";
import top from "./../../assets/top.png";
import { getRealName, hotVideoList, uploadVideo } from "../../api/video";
import { config } from './../../utils/config'
import { getUserDetail } from "../../api/user";
import { resource } from "../../utils/resource";
import backImg from "../../assets/left.png";
import videoPic from "../../assets/jhk-1629292297794.png";
import { List, Toast } from "antd-mobile-v2";
import { InfiniteScroll, DotLoading } from "antd-mobile";

import { addLike } from "../../api/user";
import { List as VirtualizedList, AutoSizer, WindowScroller } from "react-virtualized/dist/es";
import { useAsyncEffect } from "ahooks";
import VideoItem from '../../components/videoItem'

export let videoPageIndex = 0;

// 底部显示内容
// const InfiniteScrollContent = ({ hasMore }) => {
//     return (
//         <>
//             {hasMore ? (
//                 <>
//                     <span>Loading</span>
//                     <DotLoading />
//                 </>
//             ) : (
//                 <span>--- 我是有底线的 ---</span>
//             )}
//         </>
//     )
// }

export default function HotVideo({ history }) {
    const [videoList, setVideoList] = useState([]);
    // 控制触底显示内容
    const [hasMore, setHasMore] = useState(true);
    // 全部视频长度
    // const dataLen = useRef(0)
    // 已显示的视频页数
    const [videoListPage, setVideoListPage] = useState(1);
    const scrollRef = useRef(null);
    const [curPlaySrc, setCurPlaySrc] = useState('')
    let pageNo = 0
    let total = 0
    let loading = false
    let init = true
    let orignialArr = []

    const getData = ()=>{
        if (!loading){
            let isAdd = (pageNo < (total/config.pageSize)) || (pageNo==0)
            console.log(pageNo,'22222assss')
            if (isAdd){
                pageNo++
                loading = true
                console.log(pageNo,'sdsdsdsd')
                hotVideoList('',config.pageSize,pageNo).then(res => {
                    orignialArr = [...orignialArr,...res.result.records]
                    setVideoList(orignialArr)
                    if(pageNo ===1){
                        setCurPlaySrc(orignialArr[0].videoUrl)
                    }
                    total = res.result.total
                    
                    loading = false
                    init = false
                })
            }
        }
        
    }
  

    useEffect(() => {
        init = true
        pageNo = 0
        orignialArr = []
        getData()

        window.onscroll = function () {
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            //滚动条到底部的条件
            console.log(scrollTop,'dddddddddddd')
            let index = Math.floor(scrollTop/330) + 1
            index = index - Math.floor(index/15)
                            
                                   
                                    // }
            // console.log(index,orignialArr,'fffff')
            if (orignialArr.length>=1){
                setCurPlaySrc(orignialArr[index].videoUrl)
            }
                                    
                                    
            if (scrollTop + windowHeight == scrollHeight) {
                if(!init){
                    getData()
                }
                
                console.log('scrollTop')
            }
        }
    }, [])

    async function getHotVideoList(videoListPage) {
        let location = localStorage.getItem('position') || ''
        const { result } = await hotVideoList(location,config.pageSizeVideo,videoListPage);
        const list = result.records;
        let list2 = [];
        let taskList = list.map((item) => {
            return new Promise((resolve, reject) => {
                getUserDetail(item.createBy).then((r) => {
                    let temp = Object.assign({}, item, { realname: r.result.realname, avatar: r.result.avatar });
                    list2.push(temp);
                    resolve();
                });
            });
        });
        return Promise.all(taskList).then((res) => {
            return list2;
        });
    }

    // 触底时触发
    async function loadMore() {
        const append = await getHotVideoList(videoListPage);
       
        setVideoListPage(videoListPage + 1);
        setVideoList((val) => [...val, ...append]);
        if (videoListPage===1&&append.length>=1){
            setCurPlaySrc(append[0].videoUrl)
            console.log('chushi')
        }
       

        setHasMore(append.length > 0);
        // const { result } = await hotVideoList(4, videoListPage);
        // setVideoListPage(videoListPage + 1);
        // setVideoList((val) => [...val, ...result.records]);
        // setHasMore(result.records.length > 0);
        // console.log(videoList);
    }

    function back() {
        videoPageIndex = 0;
        window.history.back(-1);
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo === null || userInfo === undefined) {
        userInfo = {
            id: "",
            username: "",
            realname: "",
            avatar: "",
            birthday: null,
            sex: null,
            email: null,
            phone: "",
            orgCode: null,
            orgCodeTxt: null,
            status: 1,
            delFlag: 0,
            workNo: null,
            post: null,
            telephone: "11",
            createBy: null,
            createTime: "",
            updateBy: "",
            updateTime: "",
            activitiSync: 0,
            userIdentity: null,
            departIds: null,
            relTenantIds: null,
            clientId: null,
            userWx: null,
            userQrCode: "",
            userLocation: "上海市",
            userStatus: 5,
            userCompany: "",
            identityLevel: "",
            userIdCard: "",
            certificationInformation1: null,
            certificationInformation2: "",
            introduction: "",
            identityStatus: null
        };
    }

    async function setUsername(name) {
        let rp = await getRealName(name);
        return rp.result;
    }

    function RowRenderer(
        key, // 行数组中的唯一键
        index, // 集合中行的索引
        // isScrolling, // The List is currently being scrolled
        // isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style // Style object to be applied to row, (to position it)
    ) {
        // console.log(index,videoList);
        let video = videoList[index];
        // let [realname, setRealname] = useState("")
        // useAsyncEffect(async () => {
        //     let rp = await getRealName(video.createBy);
        //     console.log(rp);
        //     setRealname(rp.result);
        // }, [])
        // let realname = "";
        // getUserDetail(video.createBy).then((r) => {
        //     try {
        //         let d = document.getElementById(key);
        //         console.log("key");
        //         console.log(key);
        //         console.log(d);
        //         // console.log(d.getElementByClassName("video-name"));
        //         d.getElementsByClassName("video-name")[0].innerHTML = r.result.realname;
        //         // 设置头像 为空则显示默认头像
        //         d.getElementsByClassName("video-avatar")[0].src = r.result.avatar ? r.result.avatar : kehu;
        //     } catch (error) {
        //         console.log("error");
        //         console.log(error);
        //     }
        // });

        if (video === undefined) {
            return <div></div>;
        }

        return (
            <div className="mine-video-item border-video" key={key}>
                <div
                className="video-item-top"
                    style={{ backgroundColor: "white" }}
                    onClick={() => {
                        history.push("/videoDetail", video);
                        videoPageIndex = index;
                    }}
                >
                    <VideoItem  src={video.videoUrl} cover={video.cover} curPlaySrc={curPlaySrc} />
                        {/* <video
                            playsInline={true}
                            preload="false"
                            autoPlay={index==0?true:false}
                            poster={video.cover}
                            controls
                            src={resource(video.videoUrl)}
                            alt=""
                            className="video-img2"
                        /> */}
                    </div>
                    <div className="video-item-middle">{video.name} </div>
                    <div className="video-item-btm">
                        <div className="video-item-btm-left">
                            {/* TODO 头像 */}
                            {/* <img src={video.} alt="" className="video-avatar" style={{ borderRadius: "50%" }} /> */}
                            <img src={kehu} alt="" className="video-avatar" style={{ borderRadius: "50%" }} />
                            <span id="realname" className="video-name">
                                {video.realname}
                            </span>
                        </div>
                        <div className="video-item-btm-right">
                            {/* 评论 */}
                            <div className="video-right-item">
                                <img src={commentIco} alt="" className="comment-pic" />
                                <span className="comment-title">{video.commentCount||0}</span>
                            </div>
                            {/* 点赞 */}
                            <div
                                className="video-right-item"
                                onClick={() => {
                                    if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                        Toast.fail("未注册/登录/认证！");
                                    } else {
                                        addLike({
                                            likeId: video.id,
                                            likeType: 1
                                        }).then((res) => {
                                            const d = [];
                                            videoList.forEach((item) => {
                                                if (video.id === item.id) {
                                                    item.likeCount = res.result;
                                                }
                                                d.push(item);
                                            });
                                            setVideoList(d);
                                        });
                                    }
                                }}
                            >
                                <img src={zanIcon} alt="" className="comment-pic" />
                                <span className="zan-title">{video.likeCount || 0}</span>
                            </div>
                            <div className="video-right-item">
                                <img src={pointIco} alt="" className="comment-pic" />
                            </div>
                        </div>
                    </div>
                    <div className="video-btm-time">{video.createTime}</div>
                </div>
        );
    }

    return (
        <div className="wrapper-video" style={{ paddingBottom: "0", height: "100%" }}>
            <div style={{ paddingTop: "1.125rem", height: "100%" }}>
                {/* 顶部标题 */}
                <div className="page-title" style={{ backgroundColor: "#E1E0FF" }}>
                    <img src={backImg} alt="" className="title-left1" onClick={back} />
                    <div>热门视频</div>
                </div>
                {/* 视频列表 */}
                <div className="company-con" style={{ height: "100%", marginTop: "2.5rem" }}>
                {(videoList || []).map((video,index) => {
                    // console.log(index)
                        return  (<div className="mine-video-item border-video" key={video.id}>
                        <div
                        className=""
                            style={{ backgroundColor: "white" }}
                           
                        >
                            <VideoItem  src={video.videoUrl} cover={video.cover} curPlaySrc={curPlaySrc} />
                                {/* <video
                                    playsInline={true}
                                    preload="false"
                                    autoPlay={index==0?true:false}
                                    poster={video.cover}
                                    controls
                                    src={resource(video.videoUrl)}
                                    alt=""
                                    className="video-img2"
                                /> */}
                            </div>
                            <div  onClick={() => {
                                history.push("/videoDetail", video);
                                videoPageIndex = index;
                            }}>
                            <div className="video-item-middle">{video.name} </div>
                            <div className="video-item-btm">
                                <div className="video-item-btm-left">
                                    {/* TODO 头像 */}
                                    {/* <img src={video.} alt="" className="video-avatar" style={{ borderRadius: "50%" }} /> */}
                                    <img src={kehu} alt="" className="video-avatar" style={{ borderRadius: "50%" }} />
                                    <span id="realname" className="video-name">
                                        {video.realname}
                                    </span>
                                </div>
                                <div className="video-item-btm-right">
                                    {/* 评论 */}
                                    <div className="video-right-item">
                                        <img src={commentIco} alt="" className="comment-pic" />
                                        <span className="comment-title">{video.commentCount||0}</span>
                                    </div>
                                    {/* 点赞 */}
                                    <div
                                        className="video-right-item"
                                        onClick={() => {
                                            if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                                Toast.fail("未注册/登录/认证！");
                                            } else {
                                                addLike({
                                                    likeId: video.id,
                                                    likeType: 1
                                                }).then((res) => {
                                                    const d = [];
                                                    videoList.forEach((item) => {
                                                        if (video.id === item.id) {
                                                            item.likeCount = res.result;
                                                        }
                                                        d.push(item);
                                                    });
                                                    setVideoList(d);
                                                });
                                            }
                                        }}
                                    >
                                        <img src={zanIcon} alt="" className="comment-pic" />
                                        <span className="zan-title">{video.likeCount || 0}</span>
                                    </div>
                                    <div className="video-right-item">
                                        <img src={pointIco} alt="" className="comment-pic" />
                                    </div>
                                </div>
                            </div>
                            <div className="video-btm-time">{video.createTime}</div>
                        </div>
                        </div>
                )
                    })}
                    {/* {videoList === undefined ? (
                        ""
                    ) : (
                        <div ref={scrollRef}>
                            <WindowScroller
                                // Element to attach scroll event listeners. Defaults to window.
                                // scrollElement={scrollRef.current || undefined}
                                onScroll={({ scrollTop }) => {
                                    
                                    console.log("scrollToperrererer", scrollTop);
                                    
                                    let index = Math.floor(scrollTop/240) + 1
                                        index = index - Math.floor(index/4.2)
                            
                                    console.log(index,videoList,'fffff')
                                    setCurPlaySrc(videoList[index].videoUrl)
                                    
                                    

                                }}
                            >
                                {({ height, scrollTop, isScrolling }) => (
                                    <List>
                                        <AutoSizer disableHeight>
                                            {({ width }) => (
                                                <VirtualizedList
                                                    autoHeight
                                                    rowCount={videoList.length}
                                                    rowRenderer={RowRenderer}
                                                    width={width}
                                                    scrollToIndex={videoPageIndex}
                                                    height={height}
                                                    rowHeight={315}
                                                    overscanRowCount={10}
                                                    isScrolling={isScrolling}
                                                    scrollTop={scrollTop}
                                                />
                                            )}
                                        </AutoSizer>
                                    </List>
                                )}
                            </WindowScroller>
                            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={1}>
                                {/* <InfiniteScrollContent hasMore={hasMore} /> */}
                            {/* </InfiniteScroll> */}
                        {/* </div>
                    )}  */}

                    {/* <div className='mine-video-item border-video'>
                        <div className='video-item-top'>
                            <img src={videoPoster} alt='' className='video-img' />
                            <img src={videoPlay} alt='' className='play-img' />
                        </div>
                        <div className='video-item-middle' style={{ whiteSpace: 'normal' }}>没有人可以绝对把握意外事故的发生，但是懂得风险管理的人， 一定不会遇事惊慌失措，因为一切都在其预料之中。 </div>
                        <div className='video-item-btm'>
                            <div className='video-item-btm-left'>
                                <img src={kehu} alt='' className='video-avatar' /><span className='video-name'>王晓庆</span>
                            </div>
                            <div className='video-item-btm-right'>
                                <div className='video-right-item'>
                                    <img src={commentIco} alt='' className='comment-pic' />
                                    <span className='comment-title'>2220</span>
                                </div>
                                <div className='video-right-item'>
                                    <img src={zanIcon} alt='' className='comment-pic' />
                                    <span className='zan-title'>280</span>
                                </div>
                                <div className='video-right-item'>
                                    <img src={pointIco} alt='' className='comment-pic' />
                                </div>
                            </div>
                        </div>
                        <div className='video-btm-time'>2021-07-20 15:30:00</div>
                    </div> */}
                </div>
            </div>
            {/* 上传 */}
            <div className="toTop" onClick={() => {
                        if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                            Toast.fail("未注册/登录/认证！");
                        } else {
                            history.push("/uploadVideo");
                        }
                    }}>
                <img src={top} alt="" className="top-pic" />
                <div
                    className="top-title"
                    
                >
                    上传
                </div>
            </div>
        </div>
    );
}
