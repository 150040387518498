import React, { useEffect, useState } from "react"
import "./../../css/zttention.css"
import addressIcon from "./../../assets/add.png"
import addIcon from "./../../assets/tianjiahaoyou.png"
import searchIcon from "./../../assets/search.png"
import moreIcon from "./../../assets/more.png"
import avatar from "./../../assets/hot-manager.png"
import renY from "./../../assets/ren.png"
import renB from "./../../assets/ren_blue.png"
import closeIcon from "./../../assets/close_ico.png"
import { resource } from "../../utils/resource"
import {
    addFans,
    deleteFans,
    editDtBooking,
    listAttention,
    listAttention1,
    listFans,
    listFans1,
    userInfoByUserName
} from "../../api/user"
import { commentList } from "../../api/comment"
import { Toast } from "antd-mobile-v2"

import backImg from "../../assets/left.png"
import hotManager from "../../assets/hot-manager.png"

export default function Attention({ history }) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const [tabs, setTabs] = useState([
        { id: 1, tabName: "我的关注" },
        { id: 2, tabName: "我的粉丝" }
    ])
    const [currentIndex, setCurrentIndex] = useState(1)
    const [keywords, setKeywords] = useState("")
    const [attentions, setAttentions] = useState([])
    const [fans, setFans] = useState([])
    const back = () => {
        window.history.back(-1)
    }

    const tabChoiced = (id) => {
        setCurrentIndex(id)
    }

    const inputChange = (e) => {
        setKeywords(e.target.value)
        console.log(keywords)
    }

    var isBox1Show = currentIndex === 1 ? "block" : "none"
    var isbox2Show = currentIndex === 2 ? "block" : "none"
    var tabList = tabs.map(function (res, index) {
        var tabStyle = res.id === currentIndex ? "subCtrl active" : "subCtrl"
        return (
            <li
                key={index}
                onClick={() => {
                    tabChoiced(res.id)
                }}
                className={tabStyle}
            >
                {res.tabName}
            </li>
        )
    })
    useEffect(() => {
        listAttention().then((res) => {
            const result = res.result.records
            setAttentions(result)
        })
        listFans().then((res) => {
            const result = res.result.records
            setFans(result)
        })
    }, [])
    return (
        <div className="wrapper">
            <div className="container">
                <div className="manager-con">
                    <div className="attention-top">
                        <div className="attention-top-left">
                            <img src={backImg} alt="" className="title-left1" onClick={back} />
                        </div>
                        <div className="attention-top-middle">{userInfo.realname}</div>
                        <div className="attention-top-right">
                            <img
                                src={addIcon}
                                alt=""
                                className="attention-add"
                                onClick={() => history.push("/manager")}
                            />
                        </div>
                    </div>
                    <div className="attention-btm">
                        <div className="listWrapper">
                            <ul className="subNavWrap">{tabList}</ul>
                            <div className="newslist">
                                <div style={{ display: isBox1Show }}>
                                    <div className="home-search">
                                        <div className="home-search-box">
                                            <img src={searchIcon} className={"search-icon"} alt="" />
                                            <input
                                                type="text"
                                                defaultValue={keywords}
                                                placeholder="搜索"
                                                className="home-search-input"
                                                onChange={inputChange}
                                            />
                                            <button
                                                className="search-btn"
                                                onClick={() => {
                                                    listAttention1(keywords).then((res) => {
                                                        const result = res.result.records
                                                        setAttentions(result)
                                                    })
                                                    listFans1(keywords).then((res) => {
                                                        const result = res.result.records
                                                        setFans(result)
                                                    })
                                                }}
                                            >
                                                搜索
                                            </button>
                                        </div>
                                    </div>
                                    <div className="attention-title">我的关注</div>
                                    <div className="attention-box">
                                        {attentions.map((item) => {
                                            return (
                                                <div className="attention-item">
                                                    <div
                                                        className="attention-item-left"
                                                        onClick={() => {
                                                            userInfoByUserName(item.fansId).then((res) => {
                                                                console.log("人员信息" + res.result.records)
                                                                history.push("/managerDetail", res.result.records[0])
                                                            })
                                                            history.push("/")
                                                        }}
                                                    >
                                                        <div className="attention-left-avatar">
                                                            <img
                                                                src={
                                                                    item.fansUserInfo === null ||
                                                                    item.fansUserInfo.avatar === null
                                                                        ? ""
                                                                        : resource(item.fansUserInfo.avatar)
                                                                }
                                                                alt=""
                                                                className="avatar-pic"
                                                            />
                                                        </div>
                                                        <div className="attention-left-word">
                                                            <div className="attention-word-top">
                                                                <span className="alw">
                                                                    {item.fansUserInfo === null ||
                                                                    item.fansUserInfo.realname === null
                                                                        ? "佚名"
                                                                        : item.fansUserInfo.realname}
                                                                </span>
                                                                <img src={renB} alt="" className="attention-ren-icon" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="attention-item-right">
                                                        <div
                                                            className="attention-right-btn"
                                                            onClick={() => {
                                                                deleteFans(item.fansId).then((res) => {
                                                                    Toast.success("取关成功")
                                                                    window.location.reload()
                                                                })
                                                            }}
                                                        >
                                                            已关注
                                                        </div>
                                                        <img src={moreIcon} alt="" className="attention-more-icon" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div style={{ display: isbox2Show }}>
                                    <div className="home-search">
                                        <div
                                            className="home-search-box"
                                            onClick={() => {
                                                listAttention1(keywords).then((res) => {
                                                    const result = res.result.records
                                                    setAttentions(result)
                                                })
                                                listFans1(keywords).then((res) => {
                                                    const result = res.result.records
                                                    setFans(result)
                                                })
                                            }}
                                        >
                                            <img src={searchIcon} className={"search-icon"} alt="" />
                                            <input
                                                type="text"
                                                defaultValue={keywords}
                                                placeholder="搜索"
                                                className="home-search-input"
                                                onChange={inputChange}
                                            />
                                            <button className="search-btn">搜索</button>
                                        </div>
                                    </div>
                                    <div className="attention-title">我的粉丝</div>
                                    <div className="attention-box">
                                        {fans.map((item) => {
                                            console.log(item.fansUserInfo)
                                            if (item !== null && item !== undefined) {
                                                return (
                                                    <div className="attention-item">
                                                        <div className="attention-item-left">
                                                            <div className="attention-left-avatar">
                                                                <img
                                                                    src={
                                                                        item.fansUserInfo === null ||
                                                                        item.fansUserInfo.avatar === null
                                                                            ? ""
                                                                            : resource(item.fansUserInfo.avatar)
                                                                    }
                                                                    alt=""
                                                                    className="avatar-pic"
                                                                />
                                                            </div>
                                                            <div className="attention-left-word">
                                                                <div className="attention-word-top">
                                                                    <span className="alw">
                                                                        {item.fansUserInfo === null ||
                                                                        item.fansUserInfo.realname === null
                                                                            ? "佚名"
                                                                            : item.fansUserInfo.realname}
                                                                    </span>
                                                                    <img
                                                                        src={renY}
                                                                        alt=""
                                                                        className="attention-ren-icon"
                                                                    />
                                                                </div>
                                                                <div className="attention-word-btm">
                                                                    <span className="awbr">该用户关注了你</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="attention-item-right"
                                                            onClick={() => {
                                                                if (item.flag) {
                                                                    deleteFans(item.createBy).then((res) => {
                                                                        Toast.success("取关成功")
                                                                    })
                                                                } else {
                                                                    addFans(item.createBy).then((res) => {
                                                                        Toast.success("关注成功")
                                                                    })
                                                                }

                                                                window.location.reload()
                                                            }}
                                                        >
                                                            <div className="attention-right-btn">
                                                                {item.flag ? "取消关注" : "回关"}
                                                            </div>
                                                            <img
                                                                src={closeIcon}
                                                                alt=""
                                                                className="attention-more-icon"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
