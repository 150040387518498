import request from "../utils/request"

export function productList(pageSize, pageNo, location) {
    console.log(location,'678768678')
    return request({
        url:
            "product/tbProduct/list?column=score&order=desc&pageNo=" +
            pageNo +
            "&pageSize=" +
            pageSize +
            `&location=${location}`,
        method: "get"
    })
}

export function proNameList() {
    return request({
        url: "product/tbProduct/proNameList",
        method: "get"
    })
}

export function productListByCondition(data, flag) {
    return request({
        url: "product/tbProduct/listByCondition?keyword=" + data + "&flag=" + flag,
        method: "post"
    })
}

export function productListByConditionNew(data) {
    let url = "product/tbProduct/listByCondition"
    if (data){
        url+='?'
        for(let name in data){
            url= url+ name +'='+data[name] + '&'
        }
        url = url.slice(0,url.length-1)
    }
    return request({
        url: url,
        method: "post"
    })
}

export function booking(data) {
    return request({
        url: "booking/dtBooking/add",
        method: "post",
        data
    })
}

export function addProduct(data) {
    return request({
        url: "product/tbProduct/add",
        method: "post",
        data
    })
}

export function deleteProduct(id) {
    return request({
        url: "product/tbProduct/delete?id=" + id,
        method: "delete"
    })
}

export function myProducts() {
    return request({
        url: "product/tbProduct/mylist",
        method: "get"
    })
}

export function getProduct(id) {
    return request({
        url: "product/tbProduct/queryById?id=" + id,
        method: "GET"
    })
}

export function getLabelList() {
    return request({
        url: "product/tbProduct/getLabelList",
        method: "GET"
    })
}
