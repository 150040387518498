import React, { useState } from 'react';
import './../../css/input.css';

export default function InputInfo() {

    const [radioValue, setRadioValue] = useState('yes');
    const [radioValue1, setRadioValue1] = useState('yes');
    const [radioValue2, setRadioValue2] = useState('yes');
    const [radioValue3, setRadioValue3] = useState('yes');

    const handleChange = (e) => {
        setRadioValue(e.target.value)
    }
    const handleChange1 = (e) => {
        setRadioValue1(e.target.value)
    }
    const handleChange2 = (e) => {
        setRadioValue2(e.target.value)
    }
    const handleChange3 = (e) => {
        setRadioValue3(e.target.value)
    }


    return (
        <div className='wrapper'>
            <div className='container'>
                <div className='page-title'>输入信息</div>
                <div className='manager-con'>
                    <div className='input-box'>
                        <div className='input-item'>
                            <div className='input-item-left'>您的姓名</div>
                            <div className='input-item-right'>
                                <input type='text' placeholder='请输入姓名' className='input-text' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>手机号</div>
                            <div className='input-item-right'>
                                <input type='text' placeholder='请输入手机号' className='input-text' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>地址</div>
                            <div className='input-item-right'>
                                <input type='text' placeholder='请输入地址' className='input-text' />
                            </div>
                        </div>
                        <div className='input-itemd'>
                            <div className='input-item-left'>是否有社会保险</div>
                            <div className='input-item-right'>
                                <label>
                                    <input type='radio' value='yes' checked={radioValue === 'yes'} onChange={handleChange} /><span className='span-radio'>是</span>
                                </label>
                                <label>
                                    <input type='radio' value='no' checked={radioValue === 'no'} onChange={handleChange} /><span className='span-radio'>否</span>
                                </label>
                            </div>
                        </div>
                        <div className='input-itemd'>
                            <div className='input-item-left'>是否有公积金</div>
                            <div className='input-item-right'>
                                <label>
                                    <input type='radio' value='yes' checked={radioValue1 === 'yes'} onChange={handleChange1} /><span className='span-radio'>是</span>
                                </label>
                                <label>
                                    <input type='radio' value='no' checked={radioValue1 === 'no'} onChange={handleChange1} /><span className='span-radio'>否</span>
                                </label>
                            </div>
                        </div>
                        <div className='input-itemd'>
                            <div className='input-item-left'>是否有车产</div>
                            <div className='input-item-right'>
                                <label>
                                    <input type='radio' value='yes' checked={radioValue2 === 'yes'} onChange={handleChange2} /><span className='span-radio'>是</span>
                                </label>
                                <label>
                                    <input type='radio' value='no' checked={radioValue2 === 'no'} onChange={handleChange2} /><span className='span-radio'>否</span>
                                </label>
                            </div>
                        </div>
                        <div className='input-itemd'>
                            <div className='input-item-left'>是否有房产</div>
                            <div className='input-item-right'>
                                <label>
                                    <input type='radio' value='yes' checked={radioValue3 === 'yes'} onChange={handleChange3} /><span className='span-radio'>是</span>
                                </label>
                                <label>
                                    <input type='radio' value='no' checked={radioValue3 === 'no'} onChange={handleChange3} /><span className='span-radio'>否</span>
                                </label>
                            </div>
                        </div>
                        <div className='input-area'>
                            <div className='input-title'>留言</div>
                            <div className='input-textarea'>
                                <textarea rows='6' cols='20' className='textarea-box' placeholder='我的信息正确,请尽快联系我'></textarea>
                            </div>
                        </div>
                        <div className='input-btn'>
                            <button className='input-submit'>提交</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}