import React, { useState } from 'react';
import './../../css/forget.css';
import mobileIcon from './../../assets/mobile_icon.png';
import codeIcon from './../../assets/code_icon.png';
import {
    sendSms,
    forgetPassword
} from '../../api/user'
import {
    Toast
} from 'antd-mobile-v2'
import backImg from "../../assets/left.png";

function back() {
    window.history.back(-1)
}

export default function ForgetPassword() {
    const [forgetUser, setForgetUser] = useState({
        username: "",
        password: "123456",
        smscode: "",
        phone: ""
    })
    function back() {
        window.history.back(-1)
    }
    return (
        <div className='wrapper forget'>
            <div className='container'>
                <div className='manager-con'>
                    <div className='input-box forget-box'>
                        <div className='forget-title'>
                            <img src={backImg} alt='' className='title-left1' onClick={back} />忘记密码</div>
                        <div className='forget-item'>
                            <div className='forget-item-left'>
                                <img src={mobileIcon} alt='' className='forget-icon' />
                            </div>
                            <div className='forget-item-right'>
                                <input type='number' value={forgetUser.phone} onChange={({ target }) => {
                                    setForgetUser({
                                        ...forgetUser,
                                        phone: target.value,
                                        username: target.value
                                    })
                                }} placeholder='请输入手机号' className='forget-input' />
                            </div>
                        </div>
                        <div className='forget-item'>
                            <div className='forget-item-left'>
                                <img src={codeIcon} alt='' className='forget-icon' />
                            </div>
                            <div className='forget-item-right'>
                                <input type='number' value={forgetUser.smscode} placeholder='请输入验证码' className='forget-input' />
                            </div>
                            <div className='forget-item-count' onClick={() => {
                                sendSms(forgetUser.phone).then(({ success, message, result }) => {
                                    if (success) {
                                        setForgetUser({
                                            ...forgetUser,
                                            smscode: result
                                        })
                                    } else {
                                        Toast.fail(message)
                                    }
                                })
                            }}>获取验证码</div>
                        </div>
                        <div className='next-btn'>
                            <button className='next-submit' onClick={() => {
                                forgetPassword(forgetUser).then(({ success, message }) => {
                                    if (success) {
                                        Toast.success("重置密码成功，重置后密码为123456")
                                    } else {
                                        Toast.fail(message)
                                    }
                                })
                            }}>下一步</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}