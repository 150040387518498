import React, { useState, useRef } from 'react';
import './../../css/uploadVideo.css';
import { Picker, List, ImagePicker } from 'antd-mobile-v2';
import uploadIcon from './../../assets/upload_btn.png';
import uploadCom from './../../assets/videoImg.png';
import addressIcon from './../../assets/add.png';
import rightIcon from './../../assets/right.png';
import {
    upload
} from '../../api/upload'
import {
    uploadVideo
} from '../../api/video'
import {
    Toast
} from 'antd-mobile-v2'
import backImg from "../../assets/left.png";
import { resource } from "../../utils/resource";

import provinceCity from './../../assets/provinceCity.json';

const CustomChildren = props => (
    <div
        onClick={props.onClick}
        style={{ backgroundColor: '#fff', paddingLeft: 15 }}
    >
        <div className="test" style={{ display: 'flex', height: '45px', lineHeight: '45px' }}>
            <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{props.children}</div>
            <div style={{ textAlign: 'right', color: '#888', marginRight: 15 }}>{props.extra}</div>
        </div>
    </div>
);

export default function UploadVideo({ history }) {
    const [imgUrl, setImgUrl] = useState(uploadCom);
    const [flag, setFlag] = useState(false);
    const [city, setCity] = useState('');
    const [sValue, setSValue] = useState(['上海市']);
    const [pickerValue, setPickerValue] = useState('');
    const [videoForm, setVideoForm] = useState({
        name: "",
        remark: "",
        location: "",
        videoUrl: "",
        cover:""
    })
    const [fileInfo, setfileInfo] = useState([]);


    let input1 = useRef();

    const setFormValue = (name, value) => {
        setVideoForm({
            ...videoForm,
            [name]: value
        })
    }

    const addImage = () => {
        input1.current.click()
        console.log('sdsdsd')
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        upload(file).then(url => {
            setFormValue('videoUrl', url)
            setImgUrl(url)
            setFlag(true)

        })
    }
    const back = () => {
        window.history.back(-1)
    }
    const setAttestValue = (name, value) => {
        setFormValue("cover", value)
    }
    const onChange = (name, fs, type, index) => {
        if ('remove' !== type) {
            upload(fs[fs.length - 1].file).then((url) => {
                fs[fs.length - 1].url = url
                setAttestValue(name, JSON.stringify(fs.map(item => { return { url: item.url } })))
            })
        } else {
            fs.splice(index, 0)
            setAttestValue(name, JSON.stringify(fs.map(item => { return { url: item.url } })))
        }
    }
    return (
        <div className='wrapper1'>
            <div className='container1'>
                <div className='page-title'>
                    <img src={backImg} alt='' className='title-left1' onClick={back} />视频上传
                </div>
                <div className='manager-con container2'>
                    <div className='input-box bgrgb'>
                        <div className='input-item'>
                            <div className='input-item-left'>视频名称</div>
                            <div className='input-item-right'>
                                <input type='text' placeholder='请输入视频名称' onChange={(e) => setFormValue('name', e.target.value)} className='input-text' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>内容简介</div>
                            <div className='input-item-right'>
                                <textarea rows='5' cols='30' placeholder='请输入内容简介' onChange={(e) => setFormValue('remark', e.target.value)} className='upload-textarea' />
                            </div>
                        </div>
                        {/* TODO 增加封面 */}
                        <div className='input-item'>
                            <div className='input-item-left'>视频封面</div>
                            <div className='input-item-right'>
                            <ImagePicker
                                    onChange={(fs, var2, var3) => {
                                        console.log(fs, var2, var3)
                                       if (fs.length>0){
                                        if (fs[fs.length - 1].file){
                                            upload(fs[fs.length - 1].file).then((url) => {
                                                fs[fs.length - 1].url = url
                                                console.log(url)
                                                // fileInfo = fs
                                                fs = [fs[fs.length - 1]]
                                                setfileInfo(fs)
                                                setFormValue('cover', url)
                                                // setAttestValue(name, JSON.stringify(fs.map(item => { return { url: spliceUrl(item.url) } })))
                                            })
                                            
                                        }
                                       }else{
                                        setfileInfo(fs)
                                       }
                                        
                                       
                                    }}
                                    files={(() => {
                                        console.log(fileInfo,'jhkiuoiuo')
                                        return fileInfo.map(item => {
                                            item.url = resource(item.url)
                                            return item;
                                        })
                                    })()}
                                    accept="image/gif,image/jpeg,image/jpg,image/png"
                                />
                            </div>
                        </div>

                        <div className='upload-top'>
                            <img src={imgUrl} alt='' className={flag ? 'hide' : 'upload-video'} />
                            <video src={resource(imgUrl)} alt='' className={!flag ? 'hide' : 'upload-video'} />
                            <img src={uploadIcon} alt='' className='upload-btn upload-video-btn' />
                            <input style={{ 
                             position:'absolute',
                             zIndex:'100',
                             left:'7.8rem',
                            
                             fontSize:'20px',
                             opacity:'0',
                             filter:'alpha(opacity=0)'
                             
                         }} ref={input1} type='file' accept='video/*' onChange={handleImageChange} />
                       
                        </div>
                       <div className='upload-address'>
                            <div className='upload-address-left'>
                                <img src={addressIcon} alt='' className='upload-address-icon' />
                            </div>
                            <div className='upload-address-right'>
                                <List style={{ width: '100%', border: "none" }} >
                                    <Picker
                                        title="选择地区"
                                        extra=""
                                        cols='1'
                                        data={provinceCity}
                                        value={[videoForm.location]}
                                        onChange={v => setVideoForm({ ...videoForm, location: v[0] })}
                                        onOk={v => setVideoForm({ ...videoForm, location: v[0] })}
                                    >
                                        <CustomChildren>所在位置</CustomChildren>
                                    </Picker>
                                </List>
                                <img src={rightIcon} alt='' className='upload-right-icon' />
                            </div>
                        </div>
                    </div>
                    <div className='input-btn'>
                        <button className='input-submit' onClick={() => {
                            uploadVideo(videoForm).then(() => {
                                Toast.success("上传成功，等待审核！")
                                history.push("/myvideo")
                            })

                        }}>上传</button>
                    </div>
                </div>
            </div>
        </div>
    )

}
