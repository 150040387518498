import request from "../utils/request"

export function login(data) {
    return request({
        url: "sys/mLogin",
        data,
        method: "POST"
    })
}

export function logout() {
    return request({
        url: "sys/logout",
        method: "POST"
    })
}

export function sendSms(phone) {
    return request({
        url: `sys/user/sendSms?phone=${phone}`,
        method: "GET"
    })
}

export function register(data) {
    return request({
        url: "sys/user/register",
        data,
        method: "POST"
    })
}

export function forgetPassword(params) {
    return request({
        url: "sys/user/passwordChange",
        method: "GET",
        params
    })
}

export function editUser(data) {
    return request({
        url: "/sys/user/edit",
        data,
        method: "put"
    })
}

export function userApplication(data) {
    return request({
        url: "sys/user/application",
        data,
        method: "post"
    })
}

export function bankapplication(data) {
    return request({
        url: "sys/user/bankapplication",
        data,
        method: "post"
    })
}

export function managerList(location) {
    return request({
        url: "sys/user/list?userStatus=5&userLocation="+location,
        method: "Get"
    })
}
export function myFansAndAttention() {
    return request({
        url: "sys/user/myFansAndAttention",
        method: "Get"
    })
}
export function isFans(username) {
    return request({
        url: "sys/user/isFans?username=" + username,
        method: "Get"
    })
}

export function getUserDetail(userName) {
    return request({
        url: "/sys/user/getUserDetail?username=" + userName,
        method: "get"
    })
}

export function companyList(location) {
    return request({
        url: "sys/user/list?userStatus=6&userLocation="+location,
        method: "Get"
    })
}

export function dtBooking(data,pageNo='') {
    return request({
        url: "booking/dtBooking/manager?pageSize=" + data+'&pageNo='+pageNo,
        method: "get"
    })
}

export function queryBookingByUsername(username) {
    return request({
        url: "booking/dtBooking/queryBookingByUsername?username=" + username,
        method: "post"
    })
}

export function deleteDtBooking(id) {
    return request({
        url: "booking/dtBooking/delete?id=" + id,
        method: "delete"
    })
}

export function editDtBooking(data) {
    return request({
        url: "booking/dtBooking/edit",
        method: "put",
        data
    })
}

export function myvideos(pageNo='',pageSize='') {
    return request({
        url: "videos/tbVideos/mylist?pageNo="+pageNo +'&pageSize='+pageSize,
        method: "get"
    })
}

export function deleteVideos(id) {
    return request({
        url: "videos/tbVideos/delete?id=" + id,
        method: "delete"
    })
}

export function myproducts(pageNo='',pageSize='') {
    return request({
        url: "product/tbProduct/mylist?pageNo="+pageNo +'&pageSize='+pageSize,
        method: "get"
    })
}

export function myComments(username) {
    return request({
        url: "likeandcomment/dtComments/myComments?createBy=" + username,
        method: "get"
    })
}

export function userInfoByUserName(username) {
    return request({
        url: "sys/user/list?username=" + username,
        method: "Get"
    })
}

export function addLike(data) {
    return request({
        url:
            "sys/user/addLike?userId=" +
            JSON.parse(localStorage.getItem("userInfo")).id +
            "&likeType=" +
            data.likeType +
            "&likeId=" +
            data.likeId,
        method: "post"
    })
}
export function addFans(fansId) {
    return request({
        url: "/likeandcomment/dtFans/add?fansId=" + fansId,
        method: "post"
    })
}
export function listAttention() {
    return request({
        url: "/likeandcomment/dtFans/list",
        method: "get"
    })
}

export function listFans() {
    return request({
        url: "/likeandcomment/dtFans/list/fans",
        method: "get"
    })
}

export function listAttention1(username) {
    return request({
        url: "/likeandcomment/dtFans/list?username=" + username,
        method: "get"
    })
}

export function listFans1(username) {
    return request({
        url: "/likeandcomment/dtFans/list/fans?username=" + username,
        method: "get"
    })
}

export function fansCount1(username) {
    return request({
        url: "/likeandcomment/dtFans/fansCount?fansId=" + username,
        method: "put"
    })
}

export function deleteFans(id) {
    return request({
        url: "/likeandcomment/dtFans/delete?id=" + id,
        method: "post"
    })
}
