import React, { useState, useRef } from 'react';
import '../../css/uploadVideo.css';
import { Picker, List, ImagePicker } from 'antd-mobile-v2';
import uploadIcon from './../../assets/upload_btn.png';
import uploadCom from './../../assets/videoImg.png';
import addressIcon from './../../assets/add.png';
import rightIcon from './../../assets/right.png';
import {
    upload
} from '../../api/upload'
import {
    uploadVideo
} from '../../api/video'
import {
    Toast
} from 'antd-mobile-v2'
import backImg from "../../assets/left.png";
import { resource, spliceUrl } from "../../utils/resource";
import { bankapplication } from "../../api/user";
import bianIcon from "../../assets/bianji.png";
import { commentAdd } from "../../api/comment";


const CustomChildren = props => (
    <div
        onClick={props.onClick}
        style={{ backgroundColor: '#fff', paddingLeft: 15 }}
    >
        <div className="test" style={{ display: 'flex', height: '45px', lineHeight: '45px' }}>
            <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{props.children}</div>
            <div style={{ textAlign: 'right', color: '#888', marginRight: 15 }}>{props.extra}</div>
        </div>
    </div>
);

export default function UploadVideo({ history }) {
    const [comments, setComment] = useState({
        objType: "message",
        objId: 1,
        imageUrl: "",
        comment: ""
    })


    const setAttestValue = (name, value) => {
        setComment({
            ...comments,
            [name]: value
        })
    }



    const onChange = (name, fs, type, index) => {
        if ('remove' !== type) {
            upload(fs[fs.length - 1].file).then((url) => {
                fs[fs.length - 1].url = url
                setAttestValue(name, JSON.stringify(fs.map(item => { return { url: spliceUrl(item.url) } })))
            })
        } else {
            fs.splice(index, 0)
            setAttestValue(name, JSON.stringify(fs.map(item => { return { url: spliceUrl(item.url) } })))
        }

    }
    const back = () => {
        window.history.back(-1)
    }
    return (

        <div className='wrapper1'>
            <div className='container1'>
                <div className='page-title'>
                    <img src={backImg} alt='' className='title-left1' onClick={back} />本地消息
                </div>
                <div className='manager-con container2'>
                    <div className='input-box'>
                        <div className='publish-text'>
                            <img src={bianIcon} alt='' className='bian-icon' />
                            <textarea onChange={({ target }) => {
                                console.log(target.value)
                                setAttestValue("comment", target.value)
                            }} rows='5' cols='30' placeholder='说点什么吧...' className='upload-textarea bian-textarea'>
                            </textarea>
                        </div>
                        <div className='attest-item'>
                            <div className='attest-left'>图片上传</div>
                            <div className='attest-box'>
                                <ImagePicker
                                    onChange={(var1, var2, var3) => onChange('imageUrl', var1, var2, var3)}
                                    files={(() => {
                                        const files = JSON.parse(comments.imageUrl || "[]")
                                        return files.map(item => {
                                            item.url = resource(item.url)
                                            return item;
                                        })
                                    })()}
                                    accept="image/gif,image/jpeg,image/jpg,image/png"
                                />
                            </div>
                        </div>

                    </div>
                    <div className='input-btn'>
                        <button className='input-submit' onClick={() => {
                            commentAdd(comments).then(({ success, message }) => {
                                (success ? Toast.success : Toast.fail)(message)
                                history.push("/message")
                            })
                        }}>提交</button>
                    </div>
                </div>
            </div>
        </div>

    )

}
