import React, { useState, useRef } from 'react';
import './../../css/upload.css';
import { Picker, List } from 'antd-mobile-v2';
import uploadIcon from './../../assets/upload_btn.png';
import uploadCom from './../../assets/upload_com.png';
import addressIcon from './../../assets/add.png';
import rightIcon from './../../assets/right.png';
import {
    upload
} from '../../api/upload'
import {
    addProduct
} from '../../api/product'
import {
    Toast
} from 'antd-mobile-v2'
import backImg from "../../assets/left.png";

import { provinceCity } from '../index';

const CustomChildren = props => (
    <div
        onClick={props.onClick}
        style={{ backgroundColor: '#fff', paddingLeft: 15 }}
    >
        <div className="test" style={{ display: 'flex', height: '45px', lineHeight: '45px' }}>
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{props.children}</div>
            <div style={{ textAlign: 'right', color: '#888', marginRight: 15 }}>{props.extra}</div>
        </div>
    </div>
);

export default function Upload({ history }) {
    const [pickerValue, setPickerValue] = useState('');
    const [imgUrl, setImgUrl] = useState(uploadCom);
    const [flag, setFlag] = useState(false);
    const [showlocation, setShowlocation] = useState();
    const [tabs, setTabs] = useState([
        { id: 1, tabName: '抵押贷' },
        { id: 2, tabName: '信用贷' }
    ]);
    const [tabsClass, setTabsClass] = useState([
        { id: 1, tabName: '企业贷款' },
        { id: 2, tabName: '个人贷款' }
    ]);
    const [tabsPurpose, setTabsPurpose] = useState([
        { id: 1, tabName: '经营贷款' },
        { id: 2, tabName: '消费贷款' }
    ]);
    const [lx, setLx] = useState([
        { value: 0, label: '先息后本' },
        { value: 1, label: '等额本息' },
        { value: 2, label: '等额本金' },
        { value: 3, label: '其他' },
    ]);
    const [lxIndex, setLxIndex] = useState([0]);

    const [useIndex, setUseIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [apply,setApply] = useState([
        { id: 6, name: '社保', checked: false },
        { id: 7, name: '公积金', checked: false },
        { id: 8, name: '打卡工资', checked: false },
        { id: 9, name: '个税', checked: false },
        { id: 10, name: '房贷', checked: true },
        { id: 11, name: '车贷', checked: false },
        { id: 12, name: '保单', checked: true },
        { id: 13, name: '信用卡', checked: false },
        { id: 14, name: '微粒贷', checked: false },
        { id: 15, name: '流水贷', checked: false },
        { id: 16, name: '烟草证贷', checked: false },
        { id: 17, name: '学历贷', checked: false },
        { id: 18, name: '装修贷', checked: false },
        { id: 19, name: '房值贷', checked: false },
    ]);
    const [typeIndex, setTypeIndex] = useState(0);

    const [productForm, setProductForm] = useState({
        "proName": "",//没有公司名称，公司名称改成产品名称
        "proDesc": "",//页面的产品名称改成产品简介
        "proDetail": "",
        "proAmount": "",
        "proRate": "",
        proAmountMin: "",
        proAmountMax: "",	
        "proCicle": "",
        proCicleMax: "",
        proCicleMax: "",
        proCicleMin: "",
        "proType": "",
        "location": "",
        proClass: "",
        proPurpose: "",

    });

    const setProductValue = (name, v) => {
        console.log(name);
        console.log(v);
        setProductForm({
            ...productForm,
            [name]: v
        })
    }

    let input = useRef();

    const addImage = () => {
        input.current.click();
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        upload(file).then(url => {
            setProductValue("proAvator", url)
            setImgUrl(url);
        })
    }
    const tabChoiced = (id) => {
        setCurrentIndex(id)
        if (id==0){
            setApply([
                { id: 6, name: '社保', checked: false },
                { id: 7, name: '公积金', checked: false },
                { id: 8, name: '打卡工资', checked: false },
                { id: 9, name: '个税', checked: false },
                { id: 10, name: '房贷', checked: true },
                { id: 11, name: '车贷', checked: false },
                { id: 12, name: '保单', checked: true },
                { id: 13, name: '信用卡', checked: false },
                { id: 14, name: '微粒贷', checked: false },
                { id: 15, name: '流水贷', checked: false },
                { id: 16, name: '烟草证贷', checked: false },
                { id: 17, name: '学历贷', checked: false },
                { id: 18, name: '装修贷', checked: false },
                { id: 19, name: '房值贷', checked: false },
            ]);
        }else{
            setApply([
                { id: 1, name: '房一抵', checked: false },
                { id: 2, name: '房二抵', checked: false },
                { id: 3, name: '车辆抵押', checked: false },
                { id: 4, name: '股权抵押', checked: false },
                { id: 5, name: '按揭房抵押', checked: true },
                { id: 20, name: '其他合法财产抵押', checked: true },
            ]);
        }
    }
    const tabChoicedClass = (id) => {
        setTypeIndex(id)
    }
    const  tabChoicedPurpose = (id) => {
        setUseIndex(id)
    }
    var isBox1Show = currentIndex === 0 ? 'block' : 'none';
    var isbox2Show = currentIndex === 1 ? 'block' : 'none';
    var tabList = tabs.map(function (res, index) {
        var tabStyle = index === currentIndex ? 'subCtrl active' : 'subCtrl';
        return <li key={index} onClick={() => {
            tabChoiced(index)
            console.log(res.tabName,'ggggggggggg')
            setProductValue('proType', res.tabName)
        }} className={tabStyle}>{res.tabName}</li>
    });
    let tabListClass = tabsClass.map(function (res, index) {
        var tabStyle = index === typeIndex ? 'subCtrl active' : 'subCtrl';
        return <li key={index} onClick={() => {
            tabChoicedClass(index)
            console.log(res.tabName,'ggggggggggg')
            setProductValue('proClass', res.tabName)
        }} className={tabStyle}>{res.tabName}</li>
    });
    let tabListPurpose = tabsPurpose.map(function (res, index) {
        var tabStyle = index === useIndex ? 'subCtrl active' : 'subCtrl';
        return <li key={index} onClick={() => {
            tabChoicedPurpose(index)
            console.log(res.tabName,'ggggggggggg')
            setProductValue('proPurpose', res.id)
        }} className={tabStyle}>{res.tabName}</li>
    });
    const back = () => {
        window.history.back(-1)
    }
    return (
        <div className='wrapper1'>
            <div className='container1'>
                <div className='page-title'>
                    <img src={backImg} alt='' className='title-left1' onClick={back} />产品上传
                </div>
                <div className='manager-con container2'>
                    <div className='input-box bgrgb'>
                        <div className='upload-top'>
                            <img src={imgUrl} alt='' className='upload-com' />
                            <img src={uploadIcon} alt='' className='upload-btn' onClick={addImage} />
                        </div>
                        <input style={{ display: 'none' }} ref={input} type='file' accept='image/*' onChange={handleImageChange} />

                        <div className='input-item'>
                            <div className='input-item-left'>产品分类</div>
                            <div className='input-item-right'>
                                <div className='upload-flex-right-tab'>
                                    <ul className="subNavWrap">
                                        {tabList}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='input-item'>
                            <div className='input-item-left'>产品级别</div>
                            <div className='input-item-right'>
                                <div className='upload-flex-right-tab'>
                                    <ul className="subNavWrap">
                                        {tabListClass}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        

                        <div className='input-item'>
                            <div className='input-item-left'>公司名称</div>
                            <div className='input-item-right'>
                                <input type='text' onChange={(e) => setProductValue('companyName', e.target.value)} placeholder='请输入公司名称' className='input-text' />
                            </div>
                        </div>

                        <div className='input-item'>
                            <div className='input-item-left'>产品名称</div>
                            <div className='input-item-right'>
                                <input type='text' onChange={(e) => setProductValue('proName', e.target.value)} placeholder='请输入产品名称' className='input-text' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>贷款用途</div>
                            <div className='input-item-right'>
                                <div className='upload-flex-right-tab'>
                                    <ul className="subNavWrap">
                                        {tabListPurpose}
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div className='input-item'>
                            <div className='input-item-left'>还款方式</div>
                            <div className='input-item-right'>
                            <List style={{ width: "100%", border: "none" }}>
                                <Picker
                                    title="选择还款方式"
                                    extra="请选择"
                                    cols="1"
                                    data={lx}
                                    value={lxIndex}
                                    // onChange={v => { setPickerValue(v) }}
                                    onOk={(v) => {
                                        setLxIndex(v)
                                    }}
                                    // onPickerChange={v => onPickerChange(v)}
                                >
                                    <CustomChildren style={{ width:'0px'}}></CustomChildren>
                                </Picker>
                            </List>
                            </div>
                            
                        </div>



                        <div className='input-item'>
                            <div className='input-item-left'>申请条件（多选）</div>
                            <div className='input-item-right'>
                            <div className='sort-flex'>
                                                {apply.map((item, index) =>
                                                    <span key={index} className={item.checked ? 'sbir sbir-active' : 'sbir'} onClick={() => {
                                                        let items = [...apply];
                                                        items[index].checked = !items[index].checked;
                                                        setApply(items)
                                                        // setFormValue("label", items)

                                                    }}>{item.name}</span>
                                                )}
                                            </div>
                            </div>
                            
                        </div>


                        {/* <div className='input-item'>
                            <div className='input-item-left'>利息区间</div>
                            <div className='input-item-right'>
                                <input type='text' onChange={(e) => setProductValue('proRate', e.target.value)} placeholder='请输入利息区间' className='input-text' />
                            </div>
                        </div> */}
                        <div className='input-item'>
                            <div className='input-item-left'>最低贷款利率%</div>
                            <div className='input-item-right'>
                                <input type='number' onChange={(e) => setProductValue('proRateMin', e.target.value)} placeholder='请输入数字' className='input-text' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>最高贷款利率%</div>
                            <div className='input-item-right'>
                                <input type='number' onChange={(e) => setProductValue('proRateMax', e.target.value)} placeholder='请输入数字' className='input-text' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>额度区间</div>
                            <div className='input-item-right'>
                                <input type='text' onChange={(e) => setProductValue('proAmount', e.target.value)} placeholder='请输入额度区间' className='input-text' />
                            </div>
                        </div>
                        {/* <div className='input-item'>
                            <div className='input-item-left'>贷款期数</div>
                            <div className='input-item-right'>
                                <input type='text' onChange={(e) => setProductValue('proCicle', e.target.value)} placeholder='请输入贷款期数' className='input-text' />
                            </div>
                        </div> */}
                        <div className='input-item'>
                            <div className='input-item-left'>最短贷款周期(月)	</div>
                            <div className='input-item-right'>
                                <input type='text' onChange={(e) => setProductValue('proCicleMin', e.target.value)} placeholder='请输入整数' className='input-text' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>最长贷款周期(月)</div>
                            <div className='input-item-right'>
                                <input type='text' onChange={(e) => setProductValue('proCicleMax', e.target.value)} placeholder='请输入整数' className='input-text' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>标签</div>
                            <div className='input-item-right'>
                                <input type='text' onChange={(e) => setProductValue('proLabel', e.target.value)} placeholder='请输入产品标签' className='input-text' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>产品简介</div>
                            <div className='input-item-right'>
                                <textarea onChange={(e) => setProductValue('proDesc', e.target.value)} rows='5' cols='30' placeholder='请输入产品简介' className='upload-textarea' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>产品详细</div>
                            <div className='input-item-right'>
                                <div style={{ "display": isBox1Show }}>
                                    <textarea onChange={(e) => setProductValue('proDetail', e.target.value)} rows='5' cols='30' placeholder='产品详细' className='upload-textarea' />
                                </div>
                                <div style={{ "display": isbox2Show }}>
                                    <textarea onChange={(e) => setProductValue('proDetail', e.target.value)} rows='5' cols='30' placeholder='产品详细' className='upload-textarea' />
                                </div>
                            </div>
                        </div>


                        <div className='upload-address'>
                            <div className='upload-address-left'>
                                <img src={addressIcon} alt='' className='upload-address-icon' />
                            </div>
                            <div className='upload-address-right'>
                                <List style={{ width: '100%', border: "none" }} >
                                    <Picker
                                        title="选择地区"
                                        extra=""
                                        cols='2'
                                        data={provinceCity}
                                        value={showlocation}
                                        // onChange={v => setProductValue('location', v[0])}
                                        onOk={v => {
                                            setShowlocation(v);
                                            console.log(v,'aaaaaaaaaaaa')
                                            setProductValue('location', v[1]);
                                        }}
                                    >
                                        <CustomChildren>所在位置</CustomChildren>
                                    </Picker>
                                </List>
                                <img src={rightIcon} alt='' className='upload-right-icon' />
                            </div>
                        </div>
                    </div>
                    <div className='input-btn'>
                        <button className='input-submit' onClick={() => {
                           let json = productForm
                           console.log(json)
                           let applyIdsList = ''
                            apply.forEach(item=>{
                                if (item.checked) applyIdsList+=item.id+','
                            })
                            if(applyIdsList){
                                applyIdsList = applyIdsList.slice(0,applyIdsList.length-1)
                            }
                            json.proConditionList = applyIdsList
                            console.log(json)
                            addProduct(json).then(() => {
                                Toast.success("添加产品成功")
                                history.push("/myProducts")
                            })
                        }}>上传</button>
                    </div>
                </div>
            </div>
        </div>
    )

}
