import React, { useEffect, useRef, useState } from 'react';
import './../../css/message.css';
import commentPerson from './../../assets/comment_person.png';
import commentMessage from './../../assets/comment_message.png';
import zanIcon from './../../assets/zan_icon.png';
import top from './../../assets/write.png';
import {
    commentList,
    commentAdd,
    reComments, deleteComment, deleteReComment
} from '../../api/comment'
import {
    Toast
} from 'antd-mobile-v2'
import { addLike, myvideos as hotVideoList } from "../../api/user";
import { resource } from "../../utils/resource";
import backImg from "../../assets/left.png";
import deleted from "../../assets/popup-close.png";
import { config } from './../../utils/config'

export default function Message({ history }) {
    const [comments, setComments] = useState()
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let pageNo = 0
    let total = 0
    let loading = false
    let init = true
    let orignialArr = []

    const [page,setPage] = useState(0)
    

    const [messageNum, setMessageNum] = useState(5)
    const [reKeywordsTemp, setReKeywordsTemp] = useState([]);

    useEffect(() => {
        console.log('ccccccc')
        init = true
        pageNo = 0
        orignialArr = []
        getData()
        window.onscroll = function () {
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            //滚动条到底部的条件
            if (scrollTop + windowHeight == scrollHeight) {
                if(!init){
                    getData()
                }
                
                console.log('scrollTop')
            }
        }
    }, [])
    const [reKeywords, setReKeywords] = useState('');

    const getData = ()=>{
        if (!loading){
            let isAdd = (pageNo < (total/config.pageSize)) || (pageNo==0)
            console.log(pageNo,'22222assss')
            if (isAdd){
                pageNo++
                setPage(pageNo)
                console.log(pageNo,'assss')
                loading = true
                commentList(1, "message",pageNo,config.pageSize).then(res => {
                    orignialArr = [...orignialArr,...res.result.records]
                    setComments(orignialArr)
                    total = res.result.total
                    let arr = []
                    orignialArr.forEach(item=>{
                        arr.push('')
                    })
                    setReKeywordsTemp(arr)
                    loading = false
                    init = false
                })
            }
        }
        
    }

    const input = useRef();
    function back() {
        window.history.back(-1)
    }

    if (userInfo === null || userInfo === undefined) {
        userInfo = {
            "id": "",
            "username": "",
            "realname": "",
            "avatar": "",
            "birthday": null,
            "sex": null,
            "email": null,
            "phone": "",
            "orgCode": null,
            "orgCodeTxt": null,
            "status": 1,
            "delFlag": 0,
            "workNo": null,
            "post": null,
            "telephone": "11",
            "createBy": null,
            "createTime": "",
            "updateBy": "",
            "updateTime": "",
            "activitiSync": 0,
            "userIdentity": null,
            "departIds": null,
            "relTenantIds": null,
            "clientId": null,
            "userWx": null,
            "userQrCode": "",
            "userLocation": "上海市",
            "userStatus": 5,
            "userCompany": "",
            "identityLevel": "",
            "userIdCard": "",
            "certificationInformation1": null,
            "certificationInformation2": "",
            "introduction": "",
            "identityStatus": null
        }
    }
    return (
        // <div className='wrapper'>
        //     <div className='container'>
        //         <div className='page-title'>
        //             <img src={backImg} alt='' className='title-left1' onClick={back} />本地消息
        //         </div>
        <div className="wrapper-video" style={{ paddingBottom: "0", height: "100%" }}>
            <div style={{ paddingTop: "1.125rem", height: "100%" }}>
                {/* 顶部标题 */}
                <div className="page-title" style={{ backgroundColor: "#E1E0FF" }}>
                    <img src={backImg} alt="" className="title-left1" onClick={back} />
                    <div>本地消息</div>
                </div>


                <div className='manager-con container2' style={{ height: "100%", marginTop: "2.5rem" }}>
                    <div className='input-box' style={{padding:'0.9375rem'}}>
                        {(comments || []).map((message,idx) => {

                            return (
                                <div className='comment-box-item' key={message.id}>
                                    <div className='comment-item-top'>
                                        <div className='comment-item-top-left'>
                                            <img src={message.avatar} alt='' className='comment-person' />
                                        </div>
                                        <div className='comment-item-top-right'>
                                            <div className='commnet-item-top-right-name'>{message.realname}</div>
                                            <div className='comment-item-top-right-time'>{message.createTime}</div>
                                        </div>
                                    </div>
                                    {(message.images || []).map(item => {
                                        return (
                                            <span className='input-item-right' style={{ textAlign: "right" }}>
                                                <img src={resource(item)} alt='' className='upload-com' />
                                            </span>
                                        )
                                    })}
                                    <div className='comment-item-content'>{message.comment}</div>
                                    <div className='comment-item-btm'>
                                        <div className='video-right-item'>
                                            <span className={message.createBy == userInfo.username ? "delete-titile" : "hide"} onClick={() => {
                                                deleteComment(message.id).then(res => {
                                                    if (res.success) {
                                                        Toast.success(res.message)
                                                        commentList(1, "message",1,(config.pageSize)*page).then(res => {
                                                            setComments(res.result.records)
                                                            orignialArr = res.result.records
                                                            console.log('1111aaaaaaaaaaaa')
                                                            setReKeywords('')
                                                            let arr = []
                                                            res.result.records.forEach(item=>{
                                                                arr.push('')
                                                            })
                                                            setReKeywordsTemp(arr)
                                                        })
                                                    }

                                                })
                                            }}>删除</span>
                                        </div>
                                        <div className='video-right-item' onClick={() => {
                                            if (messageNum == message.reback.length) {
                                                setMessageNum(5);
                                            } else {
                                                setMessageNum(message.reback.length);
                                            }
                                        }}>
                                            <img src={commentMessage} alt='' className='comment-pic' />
                                            <span className='comment-title'>{message.reback.length}</span>
                                        </div>
                                        <div className='video-right-item' onClick={() => {
                                            if (userInfo.identityStatus != null) {
                                                addLike({
                                                    likeId: message.id,
                                                    likeType: 2
                                                }).then(res => {
                                                    const d = [];
                                                    comments.forEach(message1 => {
                                                        if (message1.id === message.id) {
                                                            message1.likeCount = res.result;
                                                        }
                                                        d.push(message1);
                                                    })
                                                    setComments(d);
                                                    
                                                })
                                            }
                                        }}>
                                            <img src={zanIcon} alt='' className='comment-pic' />
                                            <span className='zan-title'>{message.likeCount}</span>

                                        </div>
                                    </div>
                                    <button className='search-btn2 left-button' style={{ float: 'right' }} onClick={() => {
                                        if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                            Toast.fail("未注册/登录/认证！")
                                        } else {
                                            if (reKeywords !== null && reKeywords !== '') {

                                                reComments({
                                                    reCommentId: message.id,
                                                    comment: reKeywords
                                                }).then(({ success, message }) => {
                                                    (success ? Toast.success : Toast.fail)(message)

                                                    commentList(1, "message",1,(config.pageSize)*page).then(res => {
                                                        setComments(res.result.records)
                                                        orignialArr = res.result.records
                                                        console.log('1111aaaaaaaaaaaa')
                                                        setReKeywords('')
                                                        let arr = []
                                                        reKeywordsTemp.forEach((item,idxKey)=>{
                                                            if (idxKey==idx) arr.push('')
                                                            else arr.push(item)
                                                        })
                                                        setReKeywordsTemp(arr)
                                                        console.log(reKeywordsTemp,'gfyyyyyyyyyyy')
                                                    })

                                                })
                                            } else {
                                                Toast.fail("评论内容为空")
                                            }
                                        }
                                    }}>发送</button>

                                    <input type='text' value={reKeywordsTemp[idx]} onChange={(e) =>{ 
                                        let arr = reKeywordsTemp
                                        arr[idx] = e.target.value
                                        setReKeywordsTemp(arr)
                                        setReKeywords(e.target.value)}} onKeyPress={(e) => {
                                        if (e.which === 13) {
                                            if (userInfo.id === null || userInfo.id === "") {

                                                Toast.fail("请登录")
                                            } else {
                                                if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                                    Toast.fail("未注册/登录/认证！")
                                                } else {
                                                    reComments({
                                                        reCommentId: message.id,
                                                        comment: e.target.value
                                                    }).then(({ success, message }) => {
                                                        (success ? Toast.success : Toast.fail)(message)
                                                        commentList(1, "message",1,(config.pageSize)*page).then(res => {
                                                            setComments(res.result.records)
                                                            orignialArr = res.result.records
                                                            console.log('aaaaaaaaaaaa')
                                                            setReKeywords('')
                                                            // let arr = reKeywordsTemp
                                                            // arr[idx] = '123123'
                                                            let arr = []
                                                            reKeywordsTemp.forEach((item,idxKey)=>{
                                                                if (idxKey==idx) arr.push('')
                                                                else arr.push(item)
                                                            })
                                                            setReKeywordsTemp(arr)
                                                            console.log(reKeywordsTemp,'gfyyyyyyyyyyy')
                                                        })
                                                    })

                                                }
                                            }
                                        }
                                    }} placeholder='别吝啬，快来说几句啦…' className='comment-input' />
                                    {(message.reback || []).map((item, index2) => {
                                        if (index2 < messageNum) {
                                            return <div key={item.id} className='comment-word'>
                                                {item.realname}：{item.comment}
                                                {/* <div className='video-right-item'> */}
                                                    <span className={item.realname == userInfo.username ? "delete-titile ml10" : "hide"} onClick={() => {
                                                        deleteReComment(item.id).then(res => {
                                                            if (res.success) {
                                                                Toast.success(res.message)
                                                                commentList(1, "message",1,(config.pageSize)*page).then(res => {
                                                                    setComments(res.result.records)
                                                                    orignialArr = res.result.records
                                                                })
                                                            }

                                                        })
                                                    }}>删除</span>
                                                {/* </div> */}
                                            </div>
                                        }

                                    })}
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
            <div className='toTop' onClick={() => {
                    if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                        Toast.fail("未注册/登录/认证！")
                    } else {
                        history.push("/uploadMessage")
                    }
                }}>
                <img src={top} alt='' className='write-pic' />
                <div className='top-title' >发布</div>
            </div>
        </div>
    )

}
