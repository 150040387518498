import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import './../../css/managerDetail.css';
import renIcon from './../../assets/ren.png';
import {resource} from '../../utils/resource'
import mobile from './../../assets/mobile.png';
import qrcode from './../../assets/qrcode.png';
import backImg from './../../assets/left.png'
import kehu from './../../assets/kehu.png';
import videoPoster from './../../assets/jhk-1629292297794.png';
import videoPlay from './../../assets/play_icon.png';
import commentIco from './../../assets/comment_icon.png';
import zanIcon from './../../assets/zan_icon.png';
import pointIco from './../../assets/point.png';
import bankImg from './../../assets/bank.png';
import moreIco from './../../assets/point.png';
import popupClose from './../../assets/popup-close.png';
import phonePic from "../../assets/phone_icon.png";
import videoPic from "../../assets/video-detail.png";
import {addLike, userInfoByUserName} from "../../api/user";
import {commentList} from "../../api/comment";

export default function ManagerDetail({history}) {
    const user=history.location.state[0]

    const [visible, setVisible] = useState('hidden');
    const [visible1, setVisible1] = useState('hidden');
    const [videos, setVideos] = useState([]);

    const closePopup = () => {
        setVisible('hidden')
    }
    const openPopup = () => {
        setVisible('visible')
    }
    const closePopup1 = () => {
        setVisible1('hidden')
    }
    const openPopup1 = () => {
        setVisible1('visible')
    }
    const back = () => {
        window.history.back(-1)
    }
    const [comments, setComments] = useState()

    useEffect(() => {
        userInfoByUserName(user.username).then(res=>{
            console.log(res.result.records)
            const result = res.result.records[0];
            const videos = result.videos;
            setVideos(videos);
        });
        commentList(user.id, "user").then(res => {
            setComments(res.result)
        })
    }, [])
    return (
        <div className='wrapper'>
            <div className='mine-top'>

                <div className='mine-top-title' style={{ color: '#fff' }}>
                    <img src={backImg} alt='' className='title-left1' onClick={back} />人员信息
                </div>

                <div className='manager-item manager-detail-back' style={{ margin: '0 10px', backgroundColor: 'rgba(255,255,255,1)' }}>
                    <div className='manager-item-top'>
                        <div className='item-top-left'>
                            <img src={resource(history.location.state[0].avatar)} alt='' className='manager-avatar-pic' />
                        </div>
                        <div className='item-top-right'>
                            <div className='item-top-ren'>
                                <div className='item-top-ren-title'>{history.location.state[0].realname}</div>
                            </div>
                            <div className='item-top-desc'>{history.location.state[0].post_dictText}</div>
                            <div className='item-top-con'>{history.location.state[0].introduction}</div>
                        </div>
                    </div>
                    <div className='detail-btm'>
                        <div className='detail-btm-btn' onClick={openPopup1}>
                            <img src={mobile} alt='' className='mobile-icon' /><span className='mobile-span'>联系方式</span>
                        </div>
                        <div className='detail-btm-btn qrcode' onClick={openPopup}>
                            <img src={qrcode} alt='' className='mobile-icon' /><span className='mobile-span'>二维码</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mine-btm'>
                <div className='hot-content'>
                    <div className='hot-title'>
                        <div className='hot-title-left'>相关视频</div>
                        <div className='hot-title-right'><Link to='/video' className='hot-a'>更多</Link></div>
                    </div>
                    {videos.length > 0 ? '': "暂无内容"}
                    {
                        videos.map(item => {
                            var count = 0
                            return (

                                <div className='mine-video-item deS' style={{ width: '15rem' }} >
                                    <div className='video-item-top'>
                                        <video className='video-img' src={videoPic} onClick={() => history.push("videoDetail", item)}/>

                                        <img src={videoPlay} alt='' className='play-img' />
                                    </div>
                                    <div className='video-item-middle'>{item.remark}</div>
                                    <div className='video-item-btm'>
                                        <div className='video-item-btm-left'>
                                            <img src={kehu} alt='' className='video-avatar' /><span className='video-name'>{user.realname}</span>
                                        </div>
                                        <div className='video-item-btm-right' onClick={()=>{
                                            addLike({
                                                likeId: item.id,
                                                likeType: 1
                                            }).then(res=>{

                                                const d = [];
                                                videos.forEach(video=>{
                                                    if(video.id === item.id){
                                                        video.likeCount = res.result;
                                                    }
                                                    d.push(video);
                                                })
                                                setVideos(d);
                                            })
                                        }}>
                                            <div className='video-right-item'>
                                                <img src={commentIco} alt='' className='comment-pic' />
                                                <span className='comment-title'>{item.commentCount}</span>
                                            </div>
                                            <div className='video-right-item'>
                                                <img src={zanIcon} alt='' className='comment-pic' />
                                                <span className='zan-title'>{item.likeCount}</span>
                                            </div>
                                            <div className='video-right-item'>
                                                <img src={pointIco} alt='' className='comment-pic' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
            <div className='popup' style={{ visibility: visible }}>
                <div className='popup-div'></div>
                <div className='popop-box'>
                    <div className='popup-close'>
                        <img src={popupClose} alt='' className='popup-close-icon' onClick={closePopup} />
                    </div>
                    <div className='popup-con'>
                        {history.location.state[0].userQrCode === undefined || history.location.state[0].userQrCode ? "暂无":""}
                        <img className={history.location.state[0].userQrCode === undefined || history.location.state[0].userQrCode ?'hide':''} src={history.location.state[0].userQrCode} alt="" />
                    </div>
                    <div className='popup-btn' onClick={closePopup}>确定</div>
                </div>
            </div>
            <div className='popup' style={{ visibility: visible1 }}>
                <div className='popup-div'/>
                <div className='popop-box'>
                    <div className='popup-close'>
                        <img src={popupClose} alt='' className='popup-close-icon' onClick={closePopup1} />
                    </div>
                    <div className='popup-con'>
                        {history.location.state[0].phone === undefined || history.location.state[0].phone === null ?"暂无":history.location.state[0].phone}
                    </div>
                    <div className='popup-btn' onClick={closePopup1}>确定</div>
                </div>
            </div>
        </div>
    )

}