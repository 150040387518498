import React, { useState } from 'react';
import './../../css/forget.css';
import mobileIcon from './../../assets/mobile_icon.png';
import passwordIcon from './../../assets/password_icon.png';
import backImg from "../../assets/left.png";

export default function UpdatePassword() {
    const back = () => {
        window.history.back(-1)
    }
    return (
        <div className='wrapper forget'>
            <div className='container'>
                <div className='manager-con'>
                    <div className='input-box forget-box'>
                        <div className='forget-title'>
                            <img src={backImg} alt='' className='title-left1' onClick={back} />修改密码
                        </div>
                        <div className='forget-item'>
                            <div className='forget-item-left'>
                                <img src={mobileIcon} alt='' className='forget-icon' />
                            </div>
                            <div className='forget-item-right'>
                                <input type='number' placeholder='请输入手机号' className='forget-input' />
                            </div>
                        </div>
                        <div className='forget-item'>
                            <div className='forget-item-left'>
                                <img src={passwordIcon} alt='' className='forget-icon' />
                            </div>
                            <div className='forget-item-right'>
                                <input type='password' placeholder='请输入密码' className='forget-input' />
                            </div>
                        </div>
                        <div className='forget-item'>
                            <div className='forget-item-left'>
                                <img src={passwordIcon} alt='' className='forget-icon' />
                            </div>
                            <div className='forget-item-right'>
                                <input type='password' placeholder='请再次输入密码' className='forget-input' />
                            </div>
                        </div>
                        <div className='next-btn'>
                            <button className='next-submit'>提交</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}