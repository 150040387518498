import React, { useState, useRef } from 'react';
import './../../css/personInfo.css';
import { Picker, List, Toast } from 'antd-mobile-v2';
import uploadCom from './../../assets/hot-manager.png';
import addressIcon from './../../assets/add.png';
import rightIcon from './../../assets/right.png';
import {
    resource
} from '../../utils/resource'
import {
    editUser, logout,
} from '../../api/user'
import {
    upload
} from '../../api/upload'
import backImg from "../../assets/left.png";
import right from "../../assets/upload_btn.png";
import hotManager from "../../assets/hot-manager.png";
// import provinceCity from './../../assets/provinceCity.json';
import { provinceCity } from '../index';

const CustomChildren = props => (
    <div
        onClick={props.onClick}
        style={{ backgroundColor: '#fff', paddingLeft: 15 }}
    >
        <div className="test" style={{ display: 'flex', height: '45px', lineHeight: '45px' }}>
            <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{props.children}</div>
            <div style={{ textAlign: 'right', color: '#888', marginRight: 15 }}>{props.extra}</div>
        </div>
    </div>
);

export default function PersonInfo({ history }) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const [showlocation, setShowlocation] = useState();

    const [personUser, setPersonUser] = useState({
        id: userInfo.id,
        avatar: userInfo.avatar,
        realname: userInfo.realname,
        telephone: userInfo.telephone,
        user_wx: userInfo.userWx,
        userQrCode: userInfo.userQrCode,
        user_location: userInfo.userLocation,
        introduction: userInfo.introduction
    })


    const setPersonValue = (name, { value }) => {
        setPersonUser({
            ...personUser,
            [name]: value
        })
    }

    const input = useRef();
    const input1 = useRef();

    const addImage = () => {
        input.current.click()
    }
    const addImage1 = () => {
        input1.current.click()
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        upload(file).then(value => {
            setPersonValue('avatar', { value })
        })
    }

    const handleImageChangeQCode = (e) => {
        const file = e.target.files[0];
        upload(file).then(value => {
            setPersonValue('userQrCode', { value })
        })
    }
    const back = () => {
        window.history.back(-1)
    }

    return (
        // <div className='wrapper'>
        //     <div className='container'>
        //         <div className='page-title'>
        //             <img src={backImg} alt='' className='title-left1' onClick={back} />个人信息
        //         </div>
                <div className="wrapper-video" style={{ paddingBottom: "0", height: "100%" }}>
            <div style={{ paddingTop: "1.125rem", height: "100%" }}>
                {/* 顶部标题 */}
                <div className="page-title" style={{ backgroundColor: "#E1E0FF" }}>
                    <img src={backImg} alt="" className="title-left1" onClick={back} />
                    <div>个人信息</div>
                </div>
                <div className='manager-con' style={{ height: "100%", marginTop: "2.5rem",padding:".625rem" }}>
                    <div className='input-box person-bg'>
                        <input style={{ display: 'none' }} ref={input} type='file' accept='image/*' onChange={handleImageChange} />
                        <div className='input-item'>
                            <div className='input-item-left'>头像</div>
                            <div className='input-item-right' style={{ textAlign: "right" }}>
                                <img src={personUser.avatar === null || personUser.avatar === '' || personUser.avatar === undefined ? right : resource(personUser.avatar)} alt='' className='upload-com' onClick={addImage} />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>昵称</div>
                            <div className='input-item-right'>
                                <input type='text' value={personUser.realname || ''} onChange={({ target }) => { setPersonValue('realname', target) }} className='input-text person-input' />
                            </div>
                        </div>
                        <input style={{ display: 'none' }} ref={input1} type='file' accept='image/*' onChange={handleImageChangeQCode} />
                        <div className='input-item'>
                            <div className='input-item-left'>二维码</div>
                            <div className='input-item-right' style={{ textAlign: "right" }}>
                                <img src={personUser.userQrCode === null || personUser.userQrCode === '' || personUser.userQrCode === undefined ? right : resource(personUser.userQrCode)} alt='' className='upload-com' onClick={addImage1} />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left' >手机号</div>
                            <div className='input-item-right'>
                                <input type='text' value={personUser.telephone || ''} onChange={({ target }) => { setPersonValue('telephone', target) }} placeholder='' className='input-text person-input' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>微信号</div>
                            <div className='input-item-right'>
                                <input type='text' value={personUser.user_wx || ''} onChange={({ target }) => { setPersonValue('user_wx', target) }} placeholder='' className='input-text person-input' />
                            </div>
                        </div>
                        <div className='input-item'>
                            <div className='input-item-left'>个人简介</div>
                            <div className='input-item-right'>
                                <input type='text' value={personUser.introduction || ''} onChange={({ target }) => { setPersonValue('introduction', target) }} placeholder='' className='input-text person-input' />
                            </div>
                        </div>
                        <div className='upload-address'>
                            <div className='upload-address-left'>
                                <img src={addressIcon} alt='' className='upload-address-icon' />
                            </div>
                            <div className='upload-address-right'>
                                <List style={{ width: '100%', border: "none" }} >
                                    <Picker
                                        title="选择地区"
                                        extra=""
                                        cols='2'
                                        data={provinceCity}
                                        value={showlocation}

                                        // onChange={v => { setPersonValue('user_location', { value: v[0] }) }}
                                        onOk={v => {
                                            setShowlocation(v);
                                            setPersonValue('proLocation', v[0]);
                                        }}
                                    >
                                        <CustomChildren>所在位置</CustomChildren>
                                    </Picker>
                                </List>
                                <img src={rightIcon} alt='' className='upload-right-icon' />
                            </div>
                        </div>
                        <div className='input-btn'>
                            <button className='input-submit' onClick={() => {
                                editUser(personUser).then(res => {
                                    (res.success ? Toast.success : Toast.fail)(res.message)
                                    if (res.success) {
                                        localStorage.setItem("userInfo", JSON.stringify({
                                            ...userInfo,
                                            ...personUser,
                                            userWx: personUser.user_wx,
                                            userLocation: personUser.user_location
                                        }))
                                    }
                                })
                                console.log(personUser);
                            }}>提交</button>
                            <div className={userInfo.id === "" || userInfo.id === undefined ? 'hide' : 'input-btn'}>
                                <button className='input-submit' onClick={() => {
                                    logout().then(res => {
                                        if (res.success) {
                                            localStorage.removeItem("userInfo");
                                            localStorage.removeItem("token");
                                            Toast.success(res.message)
                                            history.push("/login")
                                        }
                                    })
                                }}>退出</button>
                            </div>
                        </div>


                    </div>



                </div>
            </div>
        </div>
    )

}
