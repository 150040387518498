import React, { useEffect, useState } from "react"
import "./../../css/company.css"
import companyPic from "./../../assets/hot-company.png"
import addressIco from "./../../assets/address.png"
import viewsIco from "./../../assets/views.png"
import zanIco from "./../../assets/zan_icon.png"
import recIco from "./../../assets/rec.png"
import backImg from "../../assets/left.png"
import { dtBooking, myproducts, myvideos, companyList, userInfoByUserName, addLike } from "../../api/user"
import bankPic from "../../assets/bank_logo.png"
import clockPic from "../../assets/date_icon.png"
import kehu from "../../assets/kehu.png"
import { Toast } from "antd-mobile-v2"

import { hotVideoList, uploadVideo } from "../../api/video"

export default function Company({ history }) {
    const [company, setCompany] = useState([])
    let location = localStorage.getItem('position') || ''
    useEffect(() => {
        companyList(location).then((res) => {
            console.log(res.result.records)
            setCompany(res.result.records||[])
        })
    }, [])
    function back() {
        window.history.back(-1)
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    if (userInfo === null || userInfo === undefined) {
        userInfo = {
            id: "",
            username: "",
            realname: "",
            avatar: "",
            birthday: null,
            sex: null,
            email: null,
            phone: "",
            orgCode: null,
            orgCodeTxt: null,
            status: 1,
            delFlag: 0,
            workNo: null,
            post: null,
            telephone: "11",
            createBy: null,
            createTime: "",
            updateBy: "",
            updateTime: "",
            activitiSync: 0,
            userIdentity: null,
            departIds: null,
            relTenantIds: null,
            clientId: null,
            userWx: null,
            userQrCode: "",
            userLocation: "上海市",
            userStatus: 5,
            userCompany: "",
            identityLevel: "",
            userIdCard: "",
            certificationInformation1: null,
            certificationInformation2: "",
            introduction: "",
            identityStatus: null
        }
    }

    return (
        // <div className="wrapper">
        //     <div className="container">
        //         <div className="page-title">
        //             <img src={backImg} alt="" className="title-left1" onClick={back} />
        //             热门公司推荐
        //         </div>
        <div className="wrapper-video" style={{ paddingBottom: "0", height: "100%" }}>
            <div style={{ paddingTop: "1.125rem", height: "100%" }}>
                {/* 顶部标题 */}
                <div className="page-title" style={{ backgroundColor: "#E1E0FF" }}>
                    <img src={backImg} alt="" className="title-left1" onClick={back} />
                    <div>热门公司推荐</div>
                </div>
                <div className="company-con" style={{ height: "100%", marginTop: "2.5rem" }}>
                    {company.map((item) => {
                        return (
                            <div className="company-item">
                                <img
                                    src={companyPic}
                                    alt=""
                                    className="company-pic"
                                    onClick={() => {
                                        userInfoByUserName(item.username).then((res) => {
                                            history.push("/bank", res.result.records[0])
                                        })
                                    }}
                                />
                                <div className="company-bottom">
                                    <div className="company-bottom-left">
                                        {/* 公司名称 */}
                                        <div className="bottom-left-top">{item.userCompany}</div>
                                        {/* 公司地点 */}
                                        <div className="bottom-left-btm">
                                            <img src={addressIco} alt="" className="address-image" />
                                            <span className="address-span">{item.userLocation}</span>
                                        </div>
                                    </div>
                                    <div className="video-item-btm-right">
                                        {/* 查看数量 */}
                                        <div className="video-right-item">
                                            <img src={viewsIco} alt="" className="comment-pic" />
                                            <span className="comment-title">{item.commentCount}</span>
                                        </div>
                                        {/* 点赞 */}
                                        <div
                                            className="video-right-item"
                                            onClick={() => {
                                                if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                                    Toast.fail("未注册/登录/认证！")
                                                } else {
                                                    addLike({
                                                        likeId: item.id,
                                                        likeType: 1
                                                    }).then((res) => {
                                                        const d = []
                                                        company.forEach((item_) => {
                                                            if (item_.id === item.id) {
                                                                item_.likeCount = res.result
                                                            }
                                                            d.push(item_)
                                                        })
                                                        setCompany(d)
                                                    })
                                                }
                                            }}
                                        >
                                            <img
                                                src={zanIco}
                                                alt=""
                                                className="comment-pic"
                                                style={{ height: ".8125rem" }}
                                            />
                                            <span className="zan-title">{item.likeCount || 0}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="fixed-btm-middle">
                                    <img src={recIco} alt="" className="rec-pic" />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
