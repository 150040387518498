import React, { useEffect, useRef,useState } from "react"
import "./../../css/hotVideoDetail.css"
import videoPoster from "./../../assets/video-detail.png"
import videoPlay from "./../../assets/play_icon.png"
import commentIco from "./../../assets/comment_icon.png"
import zanIcon from "./../../assets/zan_icon.png"
import pointIco from "./../../assets/point.png"
import kehu from "./../../assets/kehu.png"
import commentAvatar from "./../../assets/comment_avatar.png"
import commentPerson from "./../../assets/comment_person.png"
import commentMessage from "./../../assets/comment_message.png"
import commentWrite from "./../../assets/comment_write.png"
import down from "./../../assets/down.png"
import { commentList, commentAdd, reComments, deleteComment, deleteReComment } from "../../api/comment"
import { resource } from "../../utils/resource"
import { Toast } from "antd-mobile-v2"
import backImg from "../../assets/left.png"
import { addLike, userInfoByUserName } from "../../api/user"
import { getRealName } from "../../api/video"

export default function HotVideoDetail({ history }) {
    const target = useRef(null);
    const [comments, setComments] = useState([])
    const [keyword, setKeyword] = useState([])
    const video = history.location.state
    console.log("=====================")
    console.log(video)
    const [realname, setRealname] = useState("")
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    if (userInfo === null || userInfo === undefined) {
        userInfo = {
            id: "",
            username: "",
            realname: "",
            avatar: "",
            birthday: null,
            sex: null,
            email: null,
            phone: "",
            orgCode: null,
            orgCodeTxt: null,
            status: 1,
            delFlag: 0,
            workNo: null,
            post: null,
            telephone: "11",
            createBy: null,
            createTime: "",
            updateBy: "",
            updateTime: "",
            activitiSync: 0,
            userIdentity: null,
            departIds: null,
            relTenantIds: null,
            clientId: null,
            userWx: null,
            userQrCode: "",
            userLocation: "上海市",
            userStatus: 5,
            userCompany: "",
            identityLevel: "",
            userIdCard: "",
            certificationInformation1: null,
            certificationInformation2: "",
            introduction: "",
            identityStatus: null
        }
    }
    const [reKeywords, setReKeywords] = useState("")
    useEffect(() => {
        commentList(video.id, "video").then((res) => {
            setComments(res.result.records)
            setTimeout(() => {
                target.current && target.current.play();
            }, 1000);
        })
        getRealName(video.createBy).then((r) => {
            setRealname(r.result)
        })
    }, [])
    function back() {
        window.history.back(-1)
    }

    return (
        // <div className="wrapper">
        //     <div className="containered">
        //         <div className="page-title">
        //             <img src={backImg} alt="" className="title-left1" onClick={back} />
        //             热门视频
        //         </div>
        <div className="wrapper-video" style={{ paddingBottom: "0", height: "100%" }}>
            <div style={{ paddingTop: "1.125rem", height: "100%" }}>
                {/* 顶部标题 */}
                <div className="page-title" style={{ backgroundColor: "#E1E0FF" }}>
                    <img src={backImg} alt="" className="title-left1" onClick={back} />
                    <div>热门视频</div>
                </div>
                <div className="company-cond">
                    <div className="mine-video-item">
                        <div className="video-item-top">
                            <video
                                ref={target}
                                playsInline={true}
                                controls
                                autoPlay={true}
                                src={resource(video.videoUrl)}
                                alt=""
                                className="video-img3"
                            />
                            {/* <img src={videoPlay} alt='' className='play-img' /> */}
                        </div>
                        <div className="video-item-middle" style={{ whiteSpace: "normal", padding: "0 10px" }}>
                            {video.name}
                        </div>
                        <div className="video-item-btm" style={{ padding: "0 10px" }}>
                            <div className="video-item-btm-left">
                                <img src={kehu} alt="" className="video-avatar" />
                                {/* TODO video头像 */}
                                {/* <img src={video.} alt="" className="video-avatar" /> */}
                                <span className="video-name">{realname}</span>
                            </div>
                            <div className="video-item-btm-right">
                                <div className="video-right-item">
                                    <img src={commentIco} alt="" className="comment-pic" />
                                    <span className="comment-title">{(comments || []).length}</span>
                                </div>
                                <div className="video-right-item">
                                    <img src={zanIcon} alt="" className="comment-pic" />
                                    <span className="zan-title">{video.likeCount || 0}</span>
                                </div>
                                <div className="video-right-item">
                                    <img src={pointIco} alt="" className="comment-pic" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="comment-box">
                        <div className="comment-box-title">全部评论</div>
                        <div className="comment-box-content">
                            <div className="comment-box-top">
                                <div className="comment-box-top-left">
                                    <img src={commentAvatar} alt="" className="comment-avatar-pic" />
                                </div>
                                <div className="comment-box-top-right">
                                    <img src={commentWrite} alt="" className="comment-write" />
                                    <input
                                        type="text"
                                        placeholder="别吝啬，快来说几句啦…"
                                        className="comment-input"
                                        onChange={(e) => {
                                            setKeyword(e.target.value)
                                        }}
                                    />
                                    <button
                                        className="search-btn left-button"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                            // console.log(keyword)
                                            if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                                Toast.fail("未注册/登录/认证！")
                                            } else {
                                                if (keyword !== null && keyword !== "") {
                                                    commentAdd({
                                                        objType: "video",
                                                        objId: video.id,
                                                        comment: keyword
                                                    }).then(({ success, message }) => {
                                                        ;(success ? Toast.success : Toast.fail)(message)
                                                        commentList(video.id, "video").then((res) => {
                                                            setComments(res.result.records)
                                                        })
                                                    })
                                                } else {
                                                    Toast.fail("评论内容为空")
                                                }
                                            }
                                        }}
                                    >
                                        发送
                                    </button>
                                </div>
                            </div>
                            {(comments || []).map((comment) => {
                                return (
                                    <div className="comment-box-item" key={comment.id}>
                                        <div className="comment-item-top">
                                            <div className="comment-item-top-left">
                                                <img src={comment.avatar} alt="" className="comment-person" />
                                            </div>
                                            <div className="comment-item-top-right">
                                                <div className="commnet-item-top-right-name">{comment.realname}</div>
                                                <div className="comment-item-top-right-time">{comment.createTime}</div>
                                            </div>
                                        </div>
                                        {(comment.images || []).map((item) => {
                                            return (
                                                <span className="input-item-right" style={{ textAlign: "right" }}>
                                                    <img src={resource(item)} alt="" className="upload-com" />
                                                </span>
                                            )
                                        })}
                                        <div className="comment-item-content">{comment.comment}</div>
                                        <div className="comment-item-btm">
                                            <div className="video-right-item">
                                                <span
                                                    className={
                                                        comment.create_by === userInfo.username
                                                            ? "delete-titile"
                                                            : "hide"
                                                    }
                                                    onClick={() => {
                                                        deleteComment(comment.id).then((res) => {
                                                            if (res.success) {
                                                                Toast.success(res.message)
                                                                commentList(video.id, "video").then((res) => {
                                                                    setComments(res.result.records)
                                                                })
                                                            }
                                                        })
                                                    }}
                                                >
                                                    删除
                                                </span>
                                            </div>
                                            <div className="video-right-item">
                                                <img src={commentMessage} alt="" className="comment-pic" />
                                                <span className="comment-title">{comment.reback.length}</span>
                                            </div>
                                            <div
                                                className="video-right-item"
                                                onClick={() => {
                                                    if (
                                                        userInfo.identityStatus !== 1 &&
                                                        userInfo.identityStatus !== 2
                                                    ) {
                                                        Toast.fail("未注册/登录/认证！")
                                                    } else {
                                                        addLike({
                                                            likeId: comment.id,
                                                            likeType: 2
                                                        }).then((res) => {
                                                            const d = []
                                                            comments.forEach((message1) => {
                                                                if (message1.id === comment.id) {
                                                                    message1.likeCount = res.result
                                                                }
                                                                d.push(message1)
                                                            })
                                                            setComments(d)
                                                        })
                                                    }
                                                }}
                                            >
                                                <img src={zanIcon} alt="" className="comment-pic" />
                                                <span className="zan-title">{comment.likeCount}</span>
                                            </div>
                                        </div>
                                        <button
                                            className="search-btn1 left-button"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                                if (userInfo.identityStatus !== 1 && userInfo.identityStatus !== 2) {
                                                    Toast.fail("未注册/登录/认证！")
                                                } else {
                                                    if (reKeywords !== null && reKeywords !== "") {
                                                        reComments({
                                                            reCommentId: comment.id,
                                                            comment: reKeywords
                                                        }).then(({ success, message }) => {
                                                            ;(success ? Toast.success : Toast.fail)(message)
                                                            commentList(video.id, "video").then((res) => {
                                                                setComments(res.result.records)
                                                            })
                                                        })
                                                    } else {
                                                        Toast.fail("评论内容为空")
                                                    }
                                                }
                                            }}
                                        >
                                            发送
                                        </button>

                                        <input
                                            type="text"
                                            onChange={(e) => {
                                                console.log(e.target.value)
                                                setReKeywords(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.which === 13) {
                                                    reComments({
                                                        reCommentId: comment.id,
                                                        comment: e.target.value
                                                    }).then(({ success, message }) => {
                                                        ;(success ? Toast.success : Toast.fail)(message)
                                                        commentList(video.id, "video").then((res) => {
                                                            setComments(res.result.records)
                                                        })
                                                    })
                                                }
                                            }}
                                            placeholder="别吝啬，快来说几句啦…"
                                            className="comment-input"
                                        />

                                        {(comment.reback || []).map((item) => {
                                            return (
                                                <div key={item.id} className="comment-word">
                                                    {item.realname}：{item.comment}
                                                    <div className="video-right-item">
                                                        <span
                                                            className={
                                                                item.create_by === userInfo.username
                                                                    ? "delete-titile"
                                                                    : "hide"
                                                            }
                                                            onClick={() => {
                                                                deleteReComment(item.id).then((res) => {
                                                                    if (res.success) {
                                                                        Toast.success(res.message)
                                                                        commentList(video.id, "video").then((res) => {
                                                                            setComments(res.result.records)
                                                                        })
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            删除
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}

                            <div className="comment-more">
                                <div className="comment-more-word">展开</div>
                                <img src={down} alt="" className="down" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
