import axios from "axios"
import { Toast } from "antd-mobile-v2"
const request = axios.create({
    // baseURL: "http://bujinge.com:8080/jeecg-boot/"
    // baseURL: "https://bujinge.com/api/jeecg-boot"
    baseURL: "https://jiedaitoutiao.com/api/jeecg-boot"
    // baseURL: 'http://127.0.0.1:8080/jeecg-boot/'
})

request.interceptors.request.use((request) => {
    request.headers = {
        "X-Access-Token": localStorage.getItem("token")
    }
    return request
})

request.interceptors.response.use(
    (response) => {
        return response.data
    },
    (err) => {
        console.log(err)
        Toast.fail("请登录" + err)
        window.location.href = '/login'
    }
)

export default request
