import React, { useState } from 'react';
import './../../css/uploadMessage.css';
import { Picker, List } from 'antd-mobile-v2';
import { ImagePicker } from 'antd-mobile-v2';
import bianIcon from './../../assets/bianji.png';
import addressIcon from './../../assets/add.png';
import rightIcon from './../../assets/right.png';
import {
    commentAdd
} from '../../api/comment'
import {
    Toast
} from 'antd-mobile-v2'
import backImg from "../../assets/left.png";

import provinceCity from './../../assets/provinceCity.json';

const CustomChildren = props => (
    <div
        onClick={props.onClick}
        style={{ backgroundColor: '#fff', paddingLeft: 15 }}
    >
        <div className="test" style={{ display: 'flex', height: '45px', lineHeight: '45px' }}>
            <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{props.children}</div>
            <div style={{ textAlign: 'right', color: '#888', marginRight: 15 }}>{props.extra}</div>
        </div>
    </div>
);

export default function Publish({ history }) {
    const [pickerValue, setPickerValue] = useState(['北京市']);
    const [files, setFiles] = useState([]);
    const [comment, setComment] = useState()
    // const [pickerValue, setPickerValue] = useState([]);
    // const [files, setFiles] = useState([]);
    const onChange = (files, type, index) => {
        console.log(files, type, index);
        setFiles(files)
    }
    const back = () => {
        window.history.back(-1)
    }
    return (
        <div className='wrapper'>
            <div className='container'>
                <div className='page-title'>
                    <img src={backImg} alt='' className='title-left1' onClick={back} />本地消息
                </div>
                <div className='manager-con'>
                    <div className='input-box'>
                        <div className='publish-text'>
                            <img src={bianIcon} alt='' className='bian-icon' />
                            <textarea onChange={({ target }) => {
                                setComment(target.value)
                            }} rows='5' cols='30' placeholder='说点什么吧...' className='upload-textarea bian-textarea'>
                            </textarea>
                        </div>
                        <div className='upload-address'>
                            <div className='upload-address-left'>
                                <img src={addressIcon} alt='' className='upload-address-icon' />
                            </div>
                            <div className='upload-address-right'>
                                <List style={{ width: '100%', border: "none" }} >
                                    <Picker
                                        title="选择地区"
                                        extra=""
                                        cols='1'
                                        data={provinceCity}
                                        value={pickerValue}
                                        onChange={v => setPickerValue(v)}
                                        onOk={v => setPickerValue(v)}
                                    >
                                        <CustomChildren>所在位置</CustomChildren>
                                    </Picker>
                                </List>
                                <img src={rightIcon} alt='' className='upload-right-icon' />
                            </div>
                        </div>
                        <div className='input-btn'>
                            <button className='input-submit' onClick={() => {
                                commentAdd({
                                    objType: "message",
                                    objId: 1,
                                    comment
                                }).then(({ success, message }) => {
                                    (success ? Toast.success : Toast.fail)(message)
                                    history.push("/message")
                                })
                            }}>发布</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
