import request from "../utils/request";
// "&location=" + location+
export function hotVideoList(location = '',pageSize = 300, pageNo = 1) {
    return request({
        url: "videos/tbVideos/list?pageSize=" + pageSize + "&pageNo=" + pageNo +  "&column=score,createTime&order=desc",
        method: "get"
    });
}

export function getVideo(url) {
    return request({
        url
    });
}

export function uploadVideo(data) {
    let action = data.id ? "edit" : "add";
    return request({
        url: "videos/tbVideos/" + action,
        method: data.id ? "PUT" : "POST",
        data
    });
}

export function myVideo() {
    return request({
        url: "videos/tbVideos/mylist",
        method: "get"
    });
}

export function deleteVideo(id) {
    return request({
        url: "videos/tbVideos/delete?id=" + id,
        method: "delete"
    });
}

export function getRealName(userName) {
    return request({
        url: "sys/user/getRealname?username=" + userName,
        method: "get"
    });
}
